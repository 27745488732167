@import '../../../../resources/styles/variables.scss';

.ant-layout .ant-layout-content.content-optimizer-content {
    min-height: 0;
}

.optimizer-date-warning-notification {
    margin: 12.5px 24px 12.5px 24px;
    width: unset;
    
    .notification-card {
        margin-bottom: 0;
    }

    article.title {
        color: #1f1f1f;
    }

    article.title.dark-mode {
        color: #ffffff;
    }
}

.optimizer-below-warning-content-container {
    margin: 0 24px !important;
}

.optimizer-info-notification {
    margin: 12.5px 24px;
    width: unset;

    .notification-card {
        margin-bottom: 0;
        border: 1px solid #20C752;
        background: #dff6e3;
    }

    .notification-card-dark {
        background: $dark-success-bg-color;
    }

    article.title {
        color: #1f1f1f;
    }

    article.title.dark-mode {
        color: #ffffff;
    }
}

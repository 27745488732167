.account-title-wrapper {
    padding: 24px 24px 0 24px;
    height: 62px;
    padding-top: 29px;
    margin-bottom: 7.3px;
}

.account-title {
    font-weight: 600;
    line-height: 44px;
    margin: 24px 0 0 24px;
}

h2.account-title.ant-typography {
    font-size: 24px;
}

.account-container {
    .ant-tabs-nav-wrap {
        margin: 0 24px;
    }
}

.account-content-wrapper {
    .form-fields-wrapper {
        padding: 16px 24px 0;
        overflow-y: scroll;
        position: absolute;
        height: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .section-title {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        margin: 0 0 16px;
    }

    section {
        border-bottom: 1px solid #e5e7eb;
        padding-bottom: 25px;
        padding-top: 16px;

        &:last-child {
            border-bottom: none;
            padding-bottom: 25px;
            margin-bottom: 0;
        }

        &:first-child {
            padding-top: 0;
        }
    }

    .subscription-wrapper {
        padding-top: 0;
    }

    .ant-form-item {
        margin-bottom: 0;
    }

    .form-buttons-wrapper {
        height: 64px;
        border-top: 1px solid #e5e7eb;
        box-shadow: 0px -2px 16px #0000000a;
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .ant-btn.ant-btn-lg {
        font-size: 16px;
        height: 38px;
        margin-bottom: 0;
        font-weight: 500;
        font-size: 14px;
        width: 80px;

        &:first-child {
            margin-right: 14px;
        }
    }
}

.container-dark {
    .account-content-wrapper {
        section {
            border-bottom: 1px solid #2a2d2f;

            &:last-child {
                border-bottom: none;
            }
        }

        .form-buttons-wrapper {
            border-top: 1px solid #2a2d2f;
        }
    }
}

div.settings-tab-bar-extra-content-wrapper {
    display: flex;
    align-items: center;
    margin-right: 24px;
}

@import '../../../../../resources/styles/variables.scss';

.links-dropdown {
    height: 32px;
    border-radius: 6px;

    &:hover {
        background: #f3f7fd;
    }
}

.links-dropdown.dark-mode {
    &:hover {
        background: #ffffff33;
    }
}

.links-popover-overlay.popover-overlay {
    padding: 0;

    .ant-popover-inner {
        padding: 0;
        overflow: hidden;
        border: none;
        box-shadow: #0f0f0f0d 0px 0px 0px 1px,
            #0f0f0f1a 0px 3px 6px,
            #0f0f0f33 0px 9px 24px;
        border-radius: 6px;

        .save-link-button {
            margin-bottom: 0px;
            width: 60px;
            margin-left: 6px;
            padding: 0px;
        }
    }

    .ant-popover-arrow {
        overflow: visible;
        right: 40px;
    }

    &.popover-overlay-dark {
        .ant-popover-inner {
            box-shadow: #5c5c5c0d 0px 0px 0px 1px,
                #5c5c5c1a 0px 3px 6px,
                #5c5c5c33 0px 9px 24px;
        }
    }
}

.add-link-wrapper {
    width: 388px;
    margin: 8px 12px;
}

.edit-link-wrapper {
    width: 100%;
    margin: 0px;
}

.element-link {
    display: inline;
    position: relative;
}

.element-link .popup {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 46px;
    width: 400px;
    padding: 6px 10px;

    z-index: 1000;
    position: absolute;
    left: 0;

    gap: 10px;
    border-radius: 6px;

    background-color: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1215686275);
    border: 1px solid #e5e7eb;
}

.element-link .popup.dark-mode {
    background-color: #0b0a0f;
    border-color: #2a2d2f;
}

.element-link .popup a {
    max-width: 300px;
    word-break: break-all;
    display: flex;
    align-items: center;
    gap: 5px;
    padding-right: 10px;
}

.edit-link-icon-wrapper {
    display: flex;
    justify-content: space-between;
    min-width: 36px;
    margin-left: 10px;

    font-size: 14px;
}

.toolbar-tooltip-overlay.ant-tooltip {
    .ant-tooltip-inner {
        box-shadow: 0px 4px 25px #3333330d;
        padding: 6px 8px;
    }
}

.resize-image-button {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 2px;
    background: #327eef;
}

.size-content-root {
    text-align: right;
}

.size-input-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: right;
    max-width: 100px;
}

.size-input {
    width: fit-content;
    margin-left: 2px;
    height: 18px;
}

.size-popover {
    .ant-popover-inner {
        padding: 6px 8px;
    }

    .size-content-root {
        color: #1f1f1f;
    }
}

.size-popover.dark-mode {
    .ant-popover-inner {
        background: #0c0b10;
    }

    .size-content-root {
        color: #ffffff;
    }
}

.ai-option-tooltip-overlay.ant-tooltip {
    .ant-tooltip-inner {
        width: 186px;
    }
}

.ai-select-tooltip-overlay {
    .ant-tooltip-inner {
        width: 224px;
    }

    &.hidden {
        display: none;
    }
}

.word-count-tooltip-overlay .ant-tooltip-content {
    top: 10px;
}

.word-counter {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    text-wrap: nowrap;
    margin: 0 8px 0 10px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;

    &.active {
        color: $primary-color;
    }

    span {
        display: inline-flex;
        margin-left: 3px;
        margin-right: 3px;
        text-wrap: nowrap;
        font-size: 0.8rem;
        line-height: 15px;
        height: 15px;
        align-self: flex-end;
    }
}

.toolbar-dropdown-overlay {
    .ant-dropdown-menu .ant-dropdown-menu-item {
        margin-bottom: 4px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &::after {
        display: none;
    }
}

.align-dropdown-overlay {
    max-width: 40px;
    min-width: 40px !important;

    .ant-dropdown-menu-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        padding: 8px;
    }

    .ant-dropdown-menu-title-content {
        height: 16px;
    }
}

.editor-toolbar {
    &:has(.word-counter.active) {
        @media screen and (max-width: 1524px) {
            .word-counter {
                flex-wrap: wrap;
            }

            button.editor-more-btn.ant-btn {
                width: auto;
                min-width: auto;
            }

            .version-history {
                .version-dropdown {
                    padding-left: 1px;
                    padding-right: 1px;
                }

                .version-status {
                    width: auto;
                    padding-left: 1px;
                    padding-right: 3px;
                    align-content: center;
                    justify-content: center;
                    flex-wrap: wrap;

                    .status-text {
                        line-height: 0.9;
                    }
                }
            }
        }

        @media screen and (max-width: 1480px) {
            .version-history {
                .version-status svg {
                    display: none;
                }
            }
        }
    }
}

.content-optimizer-top-content {
    border-bottom: 1px solid #e5e7eb;

    .content-optimizer-switcher-wrapper {
        display: flex;
        align-items: center;

        .ant-segmented {
            margin-right: 24px;
            height: 38px;
            width: 100%;
        }
    }
}

.container-dark {
    .content-optimizer-top-content {
        border-color: #2a2d2f;
    }
}

.content-optimizer-basic-data {
    display: flex;
    margin: 10px 24px;

    div.basic-data-block {
        min-width: 170px;
        margin-right: 24px;
        display: flex;
        flex-direction: column;
        gap: 6px;

        .check-outlined-icon {
            height: 23px;

            svg {
                width: 23px;
                height: 23px;
                margin-left: 4px;
            }
        }

        article.block-title {
            font-weight: 500;
            color: #1f1f1f;
            width: fit-content;
            word-break: keep-all;
        }

        article.block-title.dark-mode {
            color: #ffffff;
        }

        article.block-body {
            font-weight: 500;
            font-size: 16px;
            color: #327eef;
            display: flex;
            height: 23px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .content-optimizer-basic-data {
        div.basic-data-block {
            min-width: 134px;
            margin-right: 16px;
        }
    }
}

#fireworks-container {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 999999;
    position: fixed;
    background: transparent;
    pointer-events: none;

    canvas {
        pointer-events: none;
    }
}

.delete-popconfirm {
    z-index: 1051;

    .ant-popover-arrow {
        right: 25px;
        overflow: visible;

        &::before {
            display: none;
        }

        &::after {
            box-shadow: none;
            background: #fff;
            border-right: 1px solid #e5e7eb;
            border-bottom: 1px solid #e5e7eb;
        }
    }

    .ant-popover-inner {
        background-color: #ffffff;
        border-radius: 6px;
        box-shadow: 0px 4px 12px #0000001f;
        padding: 16px;
        border: 1px solid #e5e7eb;
    }

    .ant-popconfirm-buttons {
        text-align: end;
        display: flex;
        justify-content: flex-end;

        .ant-btn.ant-btn-sm {
            width: fit-content;
            margin-bottom: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
        }

        .ant-btn-default {
            background-color: transparent;
            border-color: #327eef;
            color: #327eef;

            &:hover {
                background-color: #327eef1a;
            }
        }
    }
}

.delete-popconfirm-dark {
    .ant-popconfirm-message-title {
        color: #fff;
    }

    .ant-popover-arrow {
        &::after {
            background: #0b0a0f;
            border-right: 1px solid #2a2d2f;
            border-bottom: 1px solid #2a2d2f;
        }
    }

    .ant-popover-inner {
        background-color: #0b0a0f;
        border: 1px solid #2a2d2f;
    }
}

.basic-data-value {
    .complete-badge {
        font-size: 16px;
        height: 23px;
        line-height: 24px;
        margin-right: 0;
    }
}

.optimizer-right-block-wrapper {
    width: 100%;
    border-left: 1px solid #e5e7eb;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .ant-tabs-ink-bar {
        min-width: 50%;
    }

    .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
        margin: 0;
    }

    .ant-tabs-nav-wrap {
        margin: 0;
        padding: 0 16px;
    }

    .ant-tabs-nav-list {
        width: 99%;
    }

    .ant-tabs-tab {
        width: 50%;
        justify-content: center;
        padding: 9px 0;
    }
}

.optimizer-right-block-wrapper.dark-mode {
    border-left-color: #2a2d2f;
}

.brief-status-bar-wrapper {
    width: 100%;

    h5.ant-typography {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        margin-top: 10px;
        margin-bottom: 8px;
    }
}

div.optimizer-content-tabs.dark-mode {
    background: #ffffff0f;

    .ant-segmented-item-selected {
        background: #16161c;
    }
}

@media (hover: hover) {
	.content-optimizer-content {
        min-width: 1024px;
    }
}
.content-briefs-keywords-header-wrapper {
    padding: 12.5px 24px 10px;

    h5.ant-typography {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 2px;
    }

    .ant-typography {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        margin-bottom: 0;
    }

    .progress-wrapper {
        padding-right: 24px;
    }
}

.content-briefs-keywords-container {
    display: flex;
    border: none;
    box-shadow: none;
    height: calc(100% - 40px);

    & > .ant-row > .ant-col:first-child {
        border-right: 1px solid #e5e7eb;
    }

    .section-wrapper {
        width: 100%;

        .form-wrapper {
            width: 100%;
            height: 100%;
            padding: 16px 24px 20px;
            overflow-y: scroll;

            .ant-form-item {
                margin-bottom: 16px;

                .ant-form-item-label > label {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 5px;
                    color: #1f1f1f;
                }

                .ant-typography {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    margin-left: 4px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .brief-label {
                    flex-wrap: wrap;

                    .ant-typography {
                        margin-left: 0;
                    }

                    .brief-title {
                        line-height: 16px;
                        margin-right: 4px;
                    }

                    .check-mark {
                        height: inherit;
                        height: 18px;

                        svg {
                            width: 22px;
                            height: 22px;
                            margin-top: -4px;
                        }
                    }

                    span.success {
                        color: #20c752;
                        font-weight: 600;
                    }

                    span.empty {
                        color: #ff6f67;
                        font-weight: 600;
                    }
                }
            }

            .ant-input,
            .ant-input-number .ant-input-number-input {
                line-height: 28px;
                min-height: 38px;

                &::placeholder {
                    font-style: italic;
                    color: #ff6f67;
                }
            }

            .ant-input-number,
            .ant-input-number-input {
                transition: all 0s;
            }

            .ant-input:focus,
            .ant-input-focused,
            .ant-input-number:focus,
            .ant-input-number-focused {
                box-shadow: none;
            }

            textarea.ant-input {
                min-height: 68px;
                transition: all 0s;
            }
        }

        .brief-section {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .section-title {
            margin-bottom: 6px;

            h5.ant-typography {
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                margin-bottom: 0;
            }
        }

        .questions-wrapper {
            .ant-typography {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                margin-bottom: 6px;
            }
        }

        .citations-wrapper {
            h5.ant-typography {
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                margin-bottom: 0;
            }

            a.ant-typography {
                display: inline-block;
                width: 100%;
                font-weight: 400;
                font-size: 12px;
                line-height: 22px;
                margin-bottom: 6px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .right-menu-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        padding: 20px 12px;
        overflow: overlay;
    }
}

.questions-tooltip-overlay.ant-tooltip,
.citation-tooltip-overlay.ant-tooltip {
    .ant-tooltip-inner {
        min-width: 295px;
        text-align: center;
    }
}

.content-briefs-keywords-container-dark {
    & > .ant-row > .ant-col:first-child {
        border-right: 1px solid #2a2d2f;
    }

    .section-wrapper {
        .form-wrapper {
            .ant-form-item .ant-form-item-label > label {
                color: #ffffff;
            }
        }

        .section-title {
            .copy-icon {
                svg {
                    path {
                        stroke: #ffffff;
                    }
                }

                &:hover {
                    background: #327eef1a;
                }
            }
        }
    }
}

.switcher-wrapper {
    .ant-segmented {
        transition: background 0s;
        position: absolute;
        width: 100%;
        height: 38px;
        margin-top: 14.9px;
    }
}

@media screen and (max-width: 1200px) {
    .content-briefs-keywords-header-wrapper,
    .content-briefs-keywords-container {
        .ant-col-17 {
            max-width: 65%;
            flex: 0 0 65%;
        }

        .ant-col-7 {
            max-width: 35%;
            flex: 0 0 35%;
        }
    }
}

.rankability-loader-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .list-more-button {
        cursor: not-allowed;

        &:hover,
        &:focus {
            background: transparent;
        }
    }
}

button.ant-btn-primary.ant-btn-lg.ant-btn-background-ghost.cancel-rankability-button {
    border: none;
    width: 100px;
    height: 32px;
    padding: 4px 8px 6px;
    font-size: 14px;
}

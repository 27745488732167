@import '../../../../../resources/styles/variables.scss';

.node-aiSpellChecker {
    position: absolute;
    padding: 4px 0 8px;
    z-index: 1;
    width: 100%;
}

.positioned-container {
    border-radius: 6px;
    color: $light-typography-primary-color;
    background-color: $light-section-bg-color;
    box-shadow: #0f0f0f0d 0px 0px 0px 1px,
        #0f0f0f1a 0px 3px 6px,
        #0f0f0f33 0px 9px 24px;
    z-index: 1049;

    &.dark-mode {
        color: $dark-typography-primary-color;
        background-color: $dark-layout-bg-color;
        box-shadow: #0f0f0f0d 0px 0px 0px 1px,
            #0f0f0f1a 0px 3px 6px,
            $primary-color 0px 9px 24px;
    }
}

.node-aiSpellChecker {
    > div {
        min-height: 365px;
        padding-bottom: 4px;
    }

    .ai-response-wrapper {
        margin-top: 3px;
        overflow: hidden;

        .ai-response-content {
            padding: 8px;
            margin: 4px;
            max-height: calc(100vh - 450px);
            overflow-y: overlay;
            margin-bottom: 0;

            font-size: 14px;
            line-height: 20px;
            font-weight: 400;

            ol,
            ul {
                padding-left: 1.5rem;
            }

            h1 {
                font-size: 30px;
                line-height: 34px;
            }
            h2 {
                font-size: 26px;
                line-height: 30px;
            }
            h3 {
                font-size: 22px;
                line-height: 26px;
            }
            h4 {
                font-size: 20px;
                line-height: 24px;
            }
            h5 {
                font-size: 18px;
                line-height: 22px;
            }
            h6 {
                font-size: 16px;
                line-height: 20px;
            }
            h1, h2, h3, h4, h5, h6 {
                font-weight: 600;
            }

            blockquote {
                font-size: 16px;
                line-height: 30px;
            }

            code {
                font-size: 12px;
                line-height: 16px;
            }

            > * {
                margin-bottom: 14px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &:focus-visible {
                outline: none;
                box-shadow: none;
            }
        }

        .ai-response-footer {
            padding: 6px 12px;
            margin-bottom: 0;
            background-color: $light-layout-bg-color;
            border-top: 1px solid $light-border-color;
            display: flex;
            justify-content: space-between;
            align-items: center;
            caret-color: transparent;

            .ant-typography {
                font-size: 12px;
            }

            .anticon {
                display: block;
                margin-right: 8px;
                margin-top: 3px;
            }

            .ant-typography:has(.anticon) {
                display: flex;
            }

            .word-count-label {
                font-weight: 500;
                align-self: center;
                white-space: nowrap;
                margin-left: 24px;
            }

            .navigate-button {
                margin: 0 2px 0 0;
                width: 24px;
                height: 24px;
                box-shadow: none;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;

                &:first-of-type {
                    margin-left: 16px;

                    svg {
                        transform: rotate(270deg);
                    }
                }

                &:last-of-type {
                    svg {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        ins {
            font-weight: 800;
            text-decoration: none;
        }

        del {
            text-decoration: line-through;

            & + ins {
                margin-left: 2px;
            }
        }

        .spelling-diff {
            padding: 2px;
            color: $primary-color;
            border-radius: 4px;
            display: inline-block;

            &.active-correction {
                background-color: $light-primary-bg-color;
            }
        }

        &.dark-mode {
            .ai-response-footer {
                background-color: $dark-input-bg-color;
                border-color: $dark-border-color;
            }

            .spelling-diff.active-correction {
                background-color: $dark-primary-bg-color;
            }
        }
    }

    .ai-actions-wrapper {
        padding: 8px 12px;
        width: 200px;
        margin-top: 3px;
        position: relative;

        .ant-menu.ant-menu-root.ant-menu-vertical {
            border-inline-end: none;

            .ant-menu-item {
                display: flex;
                align-items: center;
                margin: 0;
                height: 30px;
                border-radius: 6px;
                margin-bottom: 4px;
                padding: 4px 4px 4px 8px;
                width: 100%;
                caret-color: transparent;

                svg {
                    margin-right: 8px;
                }

                span {
                    color: $light-typography-primary-color;
                    line-height: normal;
                }

                &:not(:has(.ai-language-info)):hover {
                    .ai-option-item span,
                    span {
                        color: $primary-color;
                    }

                    .fill-icon path {
                        fill: $primary-color;
                    }

                    .stroke-icon path {
                        stroke: $primary-color;
                    }
                }

                &:has(.ai-language-info):hover,
                &.ant-menu-item-selected:has(.ai-language-info) {
                    cursor: default;
                    background-color: transparent;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .ant-menu-submenu {
                margin: 0;

                .ant-menu-sub {
                    padding: 6px;
                }

                .ai-option-item {
                    caret-color: transparent;
                }

                span {
                    color: $light-typography-primary-color;
                    line-height: normal;
                }

                .ant-menu-item:last-child {
                    margin-bottom: 0;
                }
            }

            .ant-menu-submenu-title {
                display: flex;
                align-items: center;
                padding: 4px 4px 4px 8px;
                width: 100%;
                margin: 0;
                height: 30px;
                line-height: 30px;

                .ant-menu-submenu-expand-icon {
                    margin-right: 0;
                    width: 14px;
                    height: 14px;
                    transform: rotate(90deg) translateX(-50%);
                    inset-inline-end: 6px;
                }

                &:hover {
                    span {
                        color: $primary-color;
                    }

                    .ant-menu-submenu-expand-icon path {
                        stroke: $primary-color;
                    }

                    .fill-icon path {
                        fill: $primary-color;
                    }

                    .stroke-icon path {
                        stroke: $primary-color;
                    }
                }
            }

            .ai-language-info {
                display: flex;
                align-items: center;
                cursor: default;
            }
        }

        &.dark-mode {
            .ant-menu.ant-menu-root.ant-menu-vertical,
            .ant-menu-submenu > .ant-menu {
                background-color: $dark-layout-bg-color;

                .ant-menu-item {
                    span {
                        color: $dark-typography-primary-color;
                    }

                    &:not(:has(.ai-language-info)):hover {
                        span {
                            color: $primary-color;
                        }
                    }
                }

                .ant-menu-submenu {
                    span {
                        color: $dark-typography-primary-color;
                    }
                }

                .ant-menu-submenu-title {
                    span {
                        color: $dark-typography-primary-color;
                    }

                    &:hover {
                        span {
                            color: $primary-color;
                        }
                    }
                }
            }

            .ant-menu-submenu > .ant-menu {
                box-shadow: #0f0f0f0d 0px 0px 0px 1px,
                    #0f0f0f1a 0px 3px 6px,
                    $primary-highlight-color 6px 4px 24px;
            }
        }
    }

    .ai-prompt-wrapper {
        padding: 6px 4px 6px 14px;
        margin-top: 4px;
        position: relative;

        & > div {
            padding-right: 4px;
            padding-top: 2px;
            height: auto;
            min-height: 28px;
            max-height: 226px;
            overflow-y: scroll;
        }

        .chat-gpt-icon-wrapper {
            display: flex;
            height: 28px;

            svg {
                width: 28px;
            }
        }

        .spell-checker-enter-button {
            margin: 0 0 0 16px;
            border: none;
            background-color: transparent;
            box-shadow: none;
            position: absolute;
            right: 12px;
            bottom: 8px;
            padding: 0;

            &,
            svg {
                width: 24px;
                height: 24px;
            }

            &:disabled {
                background-color: transparent;

                svg path {
                    fill: $primary-bg-disabled-color;
                }
            }

            &:hover:not(:disabled) {
                border: none;
            }
        }

        .ant-input {
            padding: 0 4px;
            font-size: 16px;
            resize: none;
            overflow: hidden;
            margin-right: 20px;
            min-height: 28px;
        }
    }
}

.ai-options-menu {
    width: 232px;
    margin-top: 4px;
    padding: 6px;

    .ai-response-option {
        width: 100%;
        margin-bottom: 4px;

        &:last-child {
            margin-bottom: 0;
        }

        .ai-credits {
            font-weight: normal;
            font-size: 12px;
            margin-left: 6px;
        }

        .ai-response-option-button {
            display: flex;
            align-items: center;
            box-shadow: none;
            border: none;
            font-size: 14px;
            margin-bottom: 0;
            padding: 4px 6px;
            color: $light-typography-secondary-color;

            span {
                pointer-events: none;
            }

            svg {
                height: 18px;
                width: 18px;
                margin-right: 8px;
            }

            &:hover {
                border: none;

                &:not(:disabled) {
                    color: $primary-color;

                    .fill-icon path {
                        fill: $primary-color;
                    }

                    .stroke-icon path {
                        stroke: $primary-color;
                    }
                }
            }

            &:disabled {
                color: $light-typography-disabled-color;

                .fill-icon path {
                    fill: $light-typography-disabled-color;
                }

                .stroke-icon path {
                    stroke: $light-typography-disabled-color;
                }
            }
        }
    }

    &.dark-mode {
        .ai-response-option-button {
            color: $dark-typography-secondary-color;

            &:disabled {
                color: $dark-typography-disabled-color;

                .fill-icon path {
                    fill: $dark-typography-disabled-color;
                }

                .stroke-icon path {
                    stroke: $dark-typography-disabled-color;
                }
            }
        }
    }
}

@import '../../../../../../resources/styles/variables.scss';

.tiptap-editor-wrapper {
    .tippy-svg-arrow {
        fill: $light-section-bg-color;
    }

    &.dark-mode {
        .tippy-svg-arrow {
            fill: $dark-layout-bg-color;
        }
    }
}

.edit-link-popup {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 46px;
    width: 400px;
    padding: 6px 10px;

    gap: 10px;
    border-radius: 6px;

    background-color: $light-section-bg-color;
    box-shadow: #0f0f0f0d 0px 0px 0px 1px,
        #0f0f0f1a 0px 3px 6px,
        #0f0f0f33 0px 9px 24px;
    
    &.dark-mode {
        background-color: $dark-layout-bg-color;
        border-color: #2a2d2f;
        box-shadow: #5c5c5c0d 0px 0px 0px 1px,
            #5c5c5c1a 0px 3px 6px,
            #5c5c5c33 0px 9px 24px;
    }

    a.edit-popup-link {
        font-size: 14px;
        max-width: 324px;
        text-wrap: nowrap;
        display: inline-block;
        align-items: center;
    }

    .edit-link-wrapper {
        margin: 0px;
    }

    .edit-link-icon-wrapper {
        display: flex;
        justify-content: space-between;
        width: 36px;
        font-size: 14px;

        svg {
            cursor: pointer;

            &:hover path {
                stroke: $primary-color;
            }
        }
    }

    .save-link-button {
        margin-bottom: 0px;
        width: 60px;
        margin-left: 6px;
        padding: 0px;
    }
}

.mastermind-call-modal {
    button.ant-modal-close {
        top: 2px;
        inset-inline-end: 1px;
    }

    div.ant-modal-content {
        height: 450px;

        div.ant-modal-body {
            height: 100%;
        }
    }
}

div.mastermind-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 100%;

    &.dark-mode {
        color: #ffffff;
    }

    .mastermind-title {
        font-size: 18px;
        font-weight: 700;

        margin: 16px 0;
    }

    .add-calendar-label {
        font-size: 15px;
        font-weight: 700;

        margin-top: 8px;

        a {
            font-size: 15px;
        }
    }

    .mastermind-subtitle {
        text-align: center;
        width: 440px;

        margin-bottom: 20px;
    }
}

.tooltip-overlay.ant-tooltip.mastermind-nathan-label-tooltip {
    max-width: 700px;
}

button.ant-btn.ant-btn-lg.join-live-call-btn {
    width: 170px;
    margin: 0 auto 15px;
}

div.mastermind-timer-wrapper {
    .timer-title {
        font-size: 18px;
        font-weight: 700;
        text-align: center;
    }

    .time-items-wrapper article {
        flex: none;
    }
    
    div.time-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80px;
    }

    article.main-time-font:not(.mastermind-sidebar-countdown) {
        color: #000000e0;
        font-size: 30px;
        font-weight: 600;
    }

    &.dark-mode {
        article.main-time-font:not(.mastermind-sidebar-countdown) {
            color: #ffffff;
        }
    }
}

.click-here-link {
    font-size: 12px;
}

.timer-loader-wrapper:not(.mastermind-sidebar-countdown) {
    margin: 0 auto;
    width: 50%;
    padding-bottom: 20px;
    padding-top: 10px;
}

.timer-loader-wrapper.mastermind-sidebar-countdown {
    margin: 16px 0;
}

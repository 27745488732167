@import '../../../resources/styles/variables.scss';

.basic-more-dropdown-overlay.ant-dropdown {
    .ant-dropdown-menu {
        padding: 8px 12px;
        
        .ant-dropdown-menu-item, 
        .ant-dropdown-menu-submenu-title  {
            color: $light-typography-primary-color;
        }

        .ant-dropdown-menu-item:not(.ant-dropdown-menu-item-disabled):hover,
        .ant-dropdown-menu-submenu-title:not(.ant-dropdown-menu-item-disabled):hover {
            .fill-icon path {
                stroke: none;
                fill: $primary-color;
            }
            
            .stroke-icon path {
                fill: none;
                stroke: $primary-color;
            }
        }
    }
}

.basic-more-dropdown-overlay-dark.ant-dropdown {
    .ant-dropdown-menu {
        .ant-dropdown-menu-item,
        .ant-dropdown-menu-submenu-title {
            color: $dark-typography-primary-color;
        }
    }

    .ant-dropdown-menu-submenu-title {
        svg {
            fill: $dark-typography-secondary-color;
        }
    }
}

button.basic-more-btn.ant-btn {
    width: 64px;
    min-width: 64px;
    height: 32px;
    margin-bottom: 0;
    padding: 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: none;

    .ant-typography {
        font-weight: 400;
        font-size: 12px;
    }

    svg {
        margin-left: 4px;
    }

    &:hover {
        background: #f3f7fd;
    }
}

button.basic-more-btn.ant-btn.dark-mode {
    &:hover {
        background: #ffffff33;
    }
}

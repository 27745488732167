.sider {
    width: 200px;
    flex: 0 0 200px !important;
    border-right: 1px solid #e5e7eb;

    .ml-8px {
        margin-left: 8px;
    }

    .ant-btn.ant-btn-lg {
        font-size: 14px;
        height: 38px;
        padding: 0;
        border-radius: 6px;
    }

    .ant-btn-primary {
        box-shadow: none;
    }

    .ant-typography {
        word-break: keep-all;
    }

    .ant-menu-item-selected a {
        font-weight: 500;
    }
}

.sider-collapsed {
    width: 70px !important;
    flex: 0 0 70px !important;
    max-width: 70px !important;
    min-width: 70px !important;
}

.sider-dark {
    border-right: 1px solid #2a2d2f;
}

.sider-nav-settings-wrapper {
    height: calc(100% - 82px);
}

article.maint-title {
    font-weight: 500;
    font-size: 16px;

    color: #1f1f1f;
    margin-right: 10px;
}

article.maint-title.dark-mode {
    color: #ffffff;
}

article.title {
    font-weight: 500;
    font-size: 16px;

    color: #728192;
    margin: 0px 10px;
}

article.title.dark-mode {
    color: #9d9d9f;
}

article.language-code {
    margin-left: 4px;
}

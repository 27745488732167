@import '../../../../resources/styles/variables.scss';

.keyword-research-container {
    .ant-tabs .ant-tabs-content-holder {
        padding: 24px;
    }

    .keyword-research-loader-wrapper {
        height: calc(100vh - 400px);
    }

    .keyword-results-loader-wrapper {
        height: calc(100vh - 240px);
    }

    .ant-tabs-extra-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 200px);
    }
}

.tabs-credits {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    margin-right: 24px;
    margin-left: auto;
    white-space: nowrap;
}

.keyword-view-all-container {
    padding: 24px;
}

.keyword-header-wrapper {
    margin-bottom: 20px;

    .ant-btn.ant-btn-lg {
        width: 130px;
    }

    .ant-input-affix-wrapper {
        margin-right: 16px;
        box-shadow: 0px 2px 4px #0000000a;
        height: 38px;
    }

    .input-error {
        border-color: #ef3e32;

        &:hover {
            border-color: #ef3e32;
        }
    }

    .ant-input-affix-wrapper > input.ant-input {
        font-weight: 500;
    }

    .ant-input-affix-wrapper > input.ant-input:placeholder-shown {
        font-weight: 400;
    }

    .ant-select {
        margin-right: 16px;
        box-shadow: 0px 2px 4px #0000000a;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 38px;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item {
        line-height: 36px;
        font-weight: 500;
    }

    .ant-select-single.ant-select-open .ant-select-selection-item {
        font-weight: 400;
    }

    .keyword-location-select {
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            width: 336px;
        }
    }

    .keyword-language-select {
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            width: 110px;
        }
        .ant-select-selector {
            .ant-select-selection-search {
                input {
                    min-height: 100%;
                }
            }
        }
    }

    .ant-input {
        color: #728192;
    }

    .ant-form-item {
        width: 100%;
        margin-right: 16px;
        margin-bottom: 0;
    }

    .ant-input-affix-wrapper-status-error:not(
            .ant-input-affix-wrapper-disabled
        ):not(
            .ant-input-affix-wrapper-borderless
        ).ant-input-affix-wrapper:focus {
        box-shadow: none;
    }

    .ant-input-affix-wrapper-focused {
        box-shadow: none !important;
    }

    .ant-input-affix-wrapper-status-error:not(
            .ant-input-affix-wrapper-disabled
        ):not(
            .ant-input-affix-wrapper-borderless
        ).ant-input-affix-wrapper:hover {
        border-color: #ef3e32;
    }
}

.list-header-wrapper {
    margin-bottom: 20px;
    height: fit-content;

    .list-header-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        margin-bottom: 0;

        .checked-items-actions-panel {
            margin-top: 0px;
        }
    }

    h3.ant-typography {
        margin-bottom: 0;
    }

    .ant-checkbox {
        margin: 0 16px 0 11px;

        .ant-checkbox-inner::after {
            inset-inline-start: 26.5%;
            top: 47%;
        }
    }

    .ant-checkbox-disabled .ant-checkbox-inner {
        background: transparent;
    }

    .ant-checkbox-wparrep:hover {
        .ant-checkbox-disabled .ant-checkbox-inner {
            border-color: #d9d9d9;
        }
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner:after {
        display: none;
    }
}

.ant-checkbox-group,
.ant-list {
    width: 100%;
}

.ant-list-items .ant-list-item {
    padding: 0 !important;
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }
}

.ant-list-split .ant-list-item {
    border-block-end: none;
}

.list-wrapper {
    $rightBlockWidth: calc(180px + 100px - 6px);
    $createInfoWidth: 260px;
    $marginWidth: 16px;

    .ant-card .ant-card-body {
        padding: 10px 20px 10px 10px;
        border-radius: 0 0 6px 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .ant-card .ant-card-body::before,
    .ant-card .ant-card-body::after {
        display: none;
    }

    .list-card-header {
        max-width: calc(100% - $createInfoWidth - $marginWidth - $marginWidth);
    }

    .list-left-block {
        justify-content: space-between;
        min-width: calc(100% - $createInfoWidth - $marginWidth - $marginWidth);
        max-width: calc(100% - $createInfoWidth - $marginWidth);
    }

    .list-right-block {
        justify-content: space-between;
        align-items: center;
        margin-left: $marginWidth;
        width: 100%;
        max-width: fit-content;
        min-width: $rightBlockWidth;

        .ant-typography {
            white-space: nowrap;
        }
    }

    .list-link-wrapper {
        display: flex;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 24px;
        border-radius: 6px;
        color: #327eef;
    }

    .list-link {
        width: 100%;
        max-width: fit-content;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 500;
        font-size: 16px;
        display: inline-block;
        padding: 0 8px;
        line-height: 32px;
        max-height: 32px;
        height: 32px;

        &:hover {
            color: #327eef;
            background: #f3f7fd;
            border-radius: 6px;
        }
    }

    .list-link.item-not-completed {
        cursor: not-allowed;

        &:hover {
            background: transparent;
        }
    }

    .list-volume {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
    }

    h5.ant-typography {
        margin-bottom: 0;
    }

    .list-spacer {
        width: 4px;
        height: 4px;
        background: #728192;
        border-radius: 50%;
        margin: 0 8px;

        &:last-child {
            display: none;
        }
    }

    .volume-text {
        font-size: 14px;
        font-weight: 500;
        line-height: 28px;
        color: #728192;
    }

    .volume-text.dark-mode {
        color: #9d9d9f;
    }

    .list-create-info-wrapper {
        flex-wrap: wrap;
        margin-left: $marginWidth;
        width: $createInfoWidth;

        .ant-typography {
            font-size: 13px;
        }

        img {
            width: 16px !important;
            height: 16px !important;
            margin-top: 2px;
            margin-right: 5px;
        }
    }

    .ant-btn-default {
        box-shadow: none;
        border: none;
    }

    .ant-checkbox-wrapper {
        align-items: center;
    }

    .ant-checkbox {
        margin-right: 8px;
        margin-top: -4px;

        .ant-checkbox-inner {
            border: 1px solid #d9d9d9;
        }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        border-color: #327eef;
    }

    .ant-checkbox:hover {
        .ant-checkbox-inner {
            transition: border-color 0.3s;
            border-color: #327eef;
        }
    }

    .kanban-view {
        .list-card-header {
            max-width: 100%;
        }

        .list-left-block {
            justify-content: space-between;
            min-width: 100%;
            max-width: 100%;
        }

        .list-create-info-wrapper {
            margin-left: 0;
        }
    }
}

.list-wrapper.ant-list-something-after-last-item
    .ant-spin-container
    > .ant-list-items
    > .ant-list-item:last-child {
    border-block-end: none;
}

.keyword-research-kanban-list-wrapper {
    .ant-card-bordered {
        height: 100%;
    }

    &.ant-list-grid .ant-col > .ant-list-item {
        padding: 0;
        box-shadow: 0px 4px 25px rgba(51, 51, 51, 0.0509803922);
    }

    .ant-col {
        height: 100%;
        padding-bottom: 20px;

        & > .ant-list-item {
            margin-block-end: 0;
            height: 100%;
        }
    }

    .ant-card .ant-card-body {
        height: 100%;
        flex-direction: column;
        align-items: flex-start;

        & > .ant-row {
            flex-flow: column;
            height: 100%;
        }

        .list-left-block {
            width: 100%;
            flex-direction: column;
        }

        .list-right-block {
            width: 100%;
            min-width: auto;
            margin-left: 0;
            justify-content: flex-start;

            .volume-text {
                margin-right: 14px;
                font-size: 14px;
                font-weight: 500;
            }
        }

        .list-create-info-wrapper {
            white-space: pre-wrap;
            margin-bottom: 12px;
            margin-right: 0;

            & > div {
                width: 100%;
            }
        }

        .list-card-header {
            width: 100%;
            max-width: 100%;
            flex: 0 1.5 auto;
            padding-bottom: 12px;
            border-bottom: 1px solid #e5e7eb;
            margin-bottom: 12px;
            align-items: center;
            height: fit-content;
            min-height: auto;

            .list-link-wrapper {
                margin-right: 0;
                flex-wrap: wrap;
            }
        }

        .list-card-main {
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            max-width: 100%;
            flex: 1 1 auto;
            height: 100%;
            padding-bottom: 0;

            .list-create-info-wrapper {
                white-space: pre-wrap;
                margin-bottom: 12px;
                margin-right: 0;

                & > div {
                    width: 100%;
                }
            }
        }
    }

    .list-spacer {
        display: none;
    }
}

.container-dark {
    .keyword-header-wrapper {
        .ant-input {
            color: #9d9d9f;
        }
    }

    .list-spacer {
        background: #9d9d9f;
    }

    .list-wrapper {
        .ant-checkbox .ant-checkbox-inner {
            border-color: #2a2d2f;
        }
    }

    .list-wrapper {
        .ant-checkbox:hover {
            .ant-checkbox-inner {
                border-color: #327eef;
            }
        }

        .ant-checkbox-wparrep:hover {
            .ant-checkbox-disabled .ant-checkbox-inner {
                border-color: #fff;
            }
        }

        .list-link {
            &:hover {
                background-color: #327eef1a;
            }

            &.dark-mode:hover {
                background-color: $dark-primary-bg-color;
            }
        }
    }

    .no-score-difficulty-badge {
        color: #fff;
    }

    .keyword-research-kanban-list-wrapper {
        .ant-card .ant-card-body {
            .list-card-header {
                border-bottom: 1px solid #2a2d2f;
            }
        }
    }
}

.difficulty-badge {
    width: 38px;
    height: 28px;
    background: $error-color;
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
}

.difficulty-badge-small {
    padding: 0px 6px;
    gap: 10px;
    width: 38px;
    height: 22px;
    line-height: 22px;
}

.difficulty-badge-small:first-of-type {
    margin-bottom: 3px;
}

.keyword-breadcrumb {
    img {
        width: 16px;
        height: 16px;
        margin: 3px 4px 0 8px;
    }
}

.no-score-difficulty-badge {
    color: #1f1f1f;
}

@media screen and (max-width: 1200px) {
    .tabs-credits {
        white-space: initial;
        min-width: 80px;
    }

    .keyword-research-container {
        .ant-tabs-extra-content {
            width: calc(100% - 170px);
        }
    }

    .keyword-research-kanban-list-wrapper.list-wrapper
        .list-create-info-wrapper {
        width: 100%;
    }

    .keyword-research-kanban-list-wrapper.list-wrapper .ant-row > div {
        min-width: 33.33%;
    }
}

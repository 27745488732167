.overview-card-content {
    article.overview {
        color: #728192;

        font-size: 14px;
        font-weight: 400;

        margin-bottom: 16px;
    }

    article.overview.dark-mode {
        color: #9d9d9f;
    }

    .page-title {
        color: #1f1f1f;
        font-weight: 500;
        font-size: 14px;
    }

    .page-title.dark-mode {
        color: #ffffff;
        font-weight: 500;
        font-size: 14px;
    }
}

.property-value {
    font-weight: 500;
    font-size: 14px;

    margin-bottom: 20px;

    color: #1f1f1f;
}

.property-value.dark-mode {
    color: #ffffff;
}

.property-name {
    color: #1f1f1f;

    font-weight: 400;
    font-size: 12px;

    margin-bottom: 2px;
}
.property-name.dark-mode {
    color: #ffffff;
}

a.links-value {
    color: #327eef;
}

.status-list-wrapper {
    list-style-type: none;

    li {
        margin-bottom: 12px;
        display: flex;
        align-items: center;

        svg {
            margin-right: 4px;
            margin-top: 2px;
        }
    }

    h5.ant-typography {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 0;
    }
}

.categories-list .category-wrapper {
    padding: 10px 38px 10px 60px;

    .difficulty-badge {
        width: 60px;

        &:first-child {
            margin-right: 15px;
        }

        &:last-child {
            margin-left: 15px;
        }
    }

    .category-name {
        min-width: 135px;
        width: 100%;
        margin-left: 37.5px;
        justify-content: flex-start;

        h5.ant-typography {
            margin-bottom: 0;
        }
    }

    .category-badge-divider {
        margin-bottom: 0;
    }

    .category-empty-divider {
        width: 1px;
        margin-left: 3em;
    }
}

.invoice-history-page-wrapper {
    padding: 40px;

    .ant-typography {
        margin: 0;
    }

    h2.ant-typography {
        margin-bottom: 32px;
        font-size: 26px;
        font-weight: 600;
        line-height: 34px;
    }

    .invoice-history-card-wrapper {
        width: 100%;
        margin-bottom: 24px;
        overflow-y: auto;
    }
}

.invoice-history-dark {
    .invoice-history-card-wrapper {
        .ant-table-wrapper
            .ant-table.ant-table-bordered
            > .ant-table-container
            > .ant-table-content
            > table
            > thead
            > tr
            > th:first-child,
        .ant-table-wrapper
            .ant-table.ant-table-bordered
            > .ant-table-container
            > .ant-table-content
            > table
            > tbody
            > tr
            > td:first-child {
            border-inline-start: none;
        }
    }
}

.invoice-balance-help-text {
    font-size: 13px;
    margin-top: 10px;
}

.unauthorized-layout {
    .unauthorized-card-wrapper {
        min-width: 400px;
        margin: auto;

        div.ant-card-body {
            padding: 28px 24px 20px;
        }
    }

    .login-logo {
        margin-bottom: 20px;
    }
}

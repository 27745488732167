.keyword-select.ant-select-dropdown {
    padding: 12px;
    z-index: 1000;

    .ant-select-item {
        min-height: 28px;
        padding: 6px 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        border-radius: 6px;
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        color: #327eef;
    }

    .ant-select-item-group {
        min-height: 1px;
        padding: 0;
        margin: 0;
        background: #e5e7eb;
        margin: 2px 8px 6px;

        &:first-child {
            height: 0;
        }
    }
}

.keyword-select-dark.ant-select-dropdown {
    .ant-select-item-group {
        background: #2A2D2F;
    }
}

.ant-select-selector {
    .ant-select-selection-search {
        input {
            min-height: 100%;
        }
    }
}
.filter-popover-btn {
    height: 30px;
    margin-right: 6px;
    border: 1px solid #e5e7eb;
    width: fit-content;
    border-radius: 6px;
    align-items: center;
    white-space: nowrap;
    padding-left: 6px;
    box-shadow: 0px 2px 4px #0000000a;
    margin-bottom: 8px;

    &.active {
        background: #327eef21;

        svg path {
            stroke: #327eef;
        }

        article {
            color: #327eef;
        }
    }

    .ant-btn {
        flex-direction: row-reverse;
        height: 30px;
        padding: 0;
        background-color: transparent;
        border: none;
        margin-bottom: 0;
        box-shadow: none;
    }

    .ant-btn:not(:disabled):focus-visible {
        outline: none;
    }

    svg {
        margin-right: 4px;
    }

    article {
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 4px;
        font-size: 14px;
    }

    .text-active {
        color: #728192;
    }

    .ant-typography.text-active {
        color: #327eef;
    }

    .clear-btn {
        height: 26px;
        padding: 0 2px;
        border-left: 1px solid #e5e7eb;

        svg {
            margin: 0;
        }

        &:hover {
            svg path {
                fill: #327eef;
                stroke: #327eef;
            }
        }
    }

    &:hover {
        border-color: #327eef;
        cursor: pointer;
    }
}

.popover-overlay {
    .ant-popover-inner {
        padding: 7px 6px 6px;
        outline: none;
        box-shadow: 0px 2px 4px #0000000a;
        border: 1px solid #e5e7eb;
    }

    .ant-popover-arrow {
        overflow: visible;
        right: 16px;

        &::before {
            display: none;
        }
    }

    .ant-popover-arrow::after {
        box-shadow: none;
        z-index: 0;
        background: #fff;
        border-right: 1px solid #e5e7eb;
        border-bottom: 1px solid #e5e7eb;
        border-radius: 10px 0 2px 0;
    }

    &::after {
        display: none;
    }
}

.popover-filter-overlay {
    padding-top: 4px;

    .ant-popover-inner {
        padding: 0;
    }

    .popover-filter-inputs-wrapper {
        padding: 6px;
    }

    .popover-filter-inputs-wrapper {
        padding: 6;
    }

    .ant-input-group.ant-input-group-compact>*:last-child {
        border-end-end-radius: 0px;
    }

    .ant-btn-primary {
        border-radius: 0 0 6px 6px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 500;
    }

    .ant-btn-primary.ant-btn-background-ghost {
        height: 32px;
        padding: 0 12px;
        border-color: transparent;
        border-top-color: #e5e7eb;
        box-shadow: none;

        &:hover {
            border-color: #327eef;
        }
    }

    .ant-input-number {
        overflow: hidden;
    }

    .ant-input-number .ant-input-number-handler-wrap {
        border-start-end-radius: 0px;
        border-end-end-radius: 0px;
    }
}

.popover-filter-overlay.dark-mode {
    .ant-btn-primary.ant-btn-background-ghost {
        border-color: transparent;
        border-top-color: #2a2d2f;
    }
}

.popover-overlay.keyword-popover-overlay.dark-mode,
.popover-overlay-dark {
    .ant-popover-arrow::after {
        background: #0b0a0f;
        border-right: 1px solid #2a2d2f;
        border-bottom: 1px solid #2a2d2f;
    }

    .ant-popover-inner {
        background: #0b0a0f;
        border: 1px solid #2a2d2f;
    }

    .ant-btn-primary.ant-btn-background-ghost {
        border-color: transparent;
        border-top-color: #2a2d2f;
    }
}

.from-to-popover-overlay {
    .filter-wrapper {
        .ant-input-number .ant-input-number-input {
            height: 32px;
            font-weight: 400;
            font-size: 14px;
        }

        .ant-input-group.ant-input-group-compact>*:first-child {
            border-end-start-radius: 0px;
            border-right-color: transparent;
            margin-inline-end: 0px;

            &:hover,
            &:focus {
                border-right-color: #327eef;
            }
        }

        .ant-input-number-focused {
            margin-inline-end: 0px;
            border-right-color: #327eef !important;
        }
    }
}

.keyword-popover-overlay {
    .add-rule-btn {
        padding-bottom: 4px;
        font-size: 14px;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        font-weight: 400;
        width: fit-content;
    }

    .filter-wrapper {
        max-height: 320px;
        overflow-y: scroll;
    }

    .ant-radio-button-wrapper {
        color: #728192;

        &:not(:first-child)::before {
            inset-block-start: -0.5px;
        }

        &-checked {
            color: #327eef;

            &>.ant-radio-button {
                background-color: #327eef1a;
            }
        }
    }

    .rule-input-wrapper {
        .ant-input-group.ant-input-group-compact>.ant-select:first-child>.ant-select-selector {
            border-start-start-radius: 0px;
            border-end-start-radius: 0px;
            border-top-color: transparent;

            &:hover,
            &:focus {
                border-top-color: #327eef;
            }
        }

        .ant-input-group.ant-input-group-compact>*:last-child {
            border-end-end-radius: 0px;
            border-start-end-radius: 0px;
            border-top-color: transparent;

            &:hover,
            &:focus {
                border-top-color: #327eef;
            }
        }

        &:first-child {

            .ant-input-group.ant-input-group-compact> :first-child,
            .ant-input-group.ant-input-group-compact>.ant-select:first-child>.ant-select-selector {
                border-start-start-radius: 0px;
                border-end-start-radius: 0px;
                border-top-color: #e5e7eb;

                &:hover,
                &:focus {
                    border-top-color: #327eef;
                }
            }

            .ant-input-group.ant-input-group-compact>*:last-child {
                border-start-end-radius: 0px;
                border-end-end-radius: 0px;
                border-top-color: #e5e7eb;

                &:hover,
                &:focus {
                    border-top-color: #327eef;
                }
            }
        }

        .ant-input {
            font-size: 14px;
        }

        .ant-input-affix-wrapper {
            padding: 4px 4px 4px 6px;

            svg:hover {
                cursor: pointer;

                path {
                    fill: #327eef;
                    stroke: #327eef;
                }
            }
        }
    }

    .ant-input-group.ant-input-group-compact {
        display: flex;
        height: 32px;
    }

    .ant-input-group.ant-input-group-compact>.ant-select:first-child>.ant-select-selector {
        width: 138px;
        height: 32px;
        border-end-start-radius: 0px;
        border-right-color: transparent;
        margin-inline-end: 0px;
        padding: 0 0 0 6px;

        &:hover,
        &:focus {
            margin-inline-end: 0px;
            border-right-color: #327eef;
        }
    }

    .ant-select .ant-select-arrow {
        inset-inline-end: 4px;
    }

    .ant-select-focused:first-child>.ant-select-selector {
        border-right-color: #327eef !important;
        border-top-color: #327eef !important;
    }

    .ant-input-affix-wrapper-focused {
        border-top-color: #327eef !important;
    }

    .ant-select-selector {
        font-size: 14px;
        display: flex;
        align-items: center;
    }
}

.keyword-popover-overlay.dark-mode {
    .rule-input-wrapper {
        &:first-child {

            .ant-input-group.ant-input-group-compact> :first-child,
            .ant-input-group.ant-input-group-compact>.ant-select:first-child>.ant-select-selector {
                border-top-color: #2a2d2f;
            }

            .ant-input-group.ant-input-group-compact>*:last-child {
                border-start-end-radius: 0px;
                border-end-end-radius: 0px;
                border-top-color: #2a2d2f;
            }
        }
    }
}

.keyword-filter-select.ant-select-dropdown {
    .ant-select-item {
        min-height: 24px;
        padding: 2px 6px;
        font-size: 14px;
    }
}

.cpc-popover-overlay {
    .ant-input-number {
        border-radius: 0px;
        width: fit-content;
        font-size: 14px;
    }

    .ant-input-number-group .ant-input-number-group-addon {
        font-size: 14px;
        padding: 0;
        width: 42px;
    }

    .ant-input-number .ant-input-number-input {
        width: 90px;
        height: 32px;
        padding: 0 6px;
    }

    .cpc-input-wrapper {
        .ant-input-group.ant-input-group-compact :first-child {
            border-start-start-radius: 0px;
            border-end-start-radius: 0px;
            border-top-color: transparent;

            &:hover,
            &:focus {
                border-top-color: #327eef;
            }
        }

        .ant-input-group.ant-input-group-compact>*:last-child {
            border-end-end-radius: 0px;
            border-start-end-radius: 0px;
            border-top-color: transparent;

            &:hover,
            &:focus {
                border-top-color: #327eef;
            }
        }

        [class^='ant-input-number'] [class^='ant-input-number'] {
            border-top-color: transparent;
            border-right-color: transparent;

            &:hover,
            &:focus {
                border-top-color: #327eef;
                border-right-color: #327eef;
            }
        }

        &:first-child {
            .ant-input-group.ant-input-group-compact :first-child {
                border-start-start-radius: 6px;
                border-end-start-radius: 0px;
                border-top-color: #e5e7eb;

                &:hover,
                &:focus {
                    border-top-color: #327eef;
                }
            }

            .ant-input-group.ant-input-group-compact>*:last-child {
                border-start-end-radius: 6px;
                border-end-end-radius: 0px;
                border-top-color: #e5e7eb;

                &:hover,
                &:focus {
                    border-top-color: #327eef;
                }
            }

            .ant-input-number-group .ant-input-number-group-addon {
                border-start-start-radius: 0px;

                &:hover,
                &:focus {
                    border-top-color: #e5e7eb !important;
                }
            }

            [class^='ant-input-number'] [class^='ant-input-number'] {
                border-top-color: #e5e7eb;

                &:hover,
                &:focus {
                    border-top-color: #327eef;
                }
            }
        }

        .ant-input-number-group .ant-input-number-group-addon {

            &:hover,
            &:focus {
                border-top-color: transparent !important;
            }
        }

        .ant-input-number .ant-input-number-handler-wrap {
            border-start-start-radius: 0px !important;
            border-end-start-radius: 0px !important;
        }

        .ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler {
            border-start-start-radius: 0px !important;
            border-top-color: #e5e7eb !important;
        }

        .ant-input-number-focused {
            border: 1px solid #327eef !important;
        }

        .ant-input-group.ant-input-group-compact>*:first-child {
            border-end-start-radius: 0px;
            border-right-color: transparent;
            margin-inline-end: 0px;

            &:hover,
            &:focus {
                border-right-color: #327eef;
            }
        }
    }
}

.cpc-popover-overlay.dark-mode {
    .cpc-input-wrapper {
        &:first-child {
            .ant-input-group.ant-input-group-compact :first-child {
                border-top-color: #2a2d2f;
            }

            .ant-input-group.ant-input-group-compact>*:last-child {
                border-top-color: #2a2d2f;
            }

            .ant-input-number-group .ant-input-number-group-addon {
                border-start-start-radius: 0px;

                &:hover,
                &:focus {
                    border-top-color: #2a2d2f !important;
                }
            }

            [class^='ant-input-number'] [class^='ant-input-number'] {
                border-top-color: #2a2d2f;
            }
        }

        .ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler {
            border-top-color: #2a2d2f !important;
        }
    }
}

.trend-popover-overlay {
    .ant-select {
        width: 130px;
        height: 32px;
    }

    .ant-select-selector,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 32px;
        padding: 0 6px;
        font-size: 14px;
        display: flex;
        align-items: center;
    }

    .ant-input-group.ant-input-group-compact>*:first-child,
    .ant-input-group.ant-input-group-compact>.ant-select:first-child>.ant-select-selector {
        border-end-start-radius: 0px;
    }

    .ant-input-group.ant-input-group-compact>.ant-select:last-child>.ant-select-selector {
        border-end-end-radius: 0px;
    }

    .ant-select-multiple .ant-select-selection-item {
        max-width: 100%;
        height: 21px;
        margin-top: 1px;
        margin-bottom: 2px;
        line-height: 20px;
        background: #327eef1a;
        border: none;
        color: #327eef;
        padding-inline-start: 8px;
        padding-inline-end: 4px;
        margin: 0;
    }

    .ant-select-selection-overflow-item {
        margin-right: 4px;

        &:last-child {
            margin-right: 0px;
        }
    }

    .ant-select-multiple .ant-select-selection-item-remove {
        display: none;
    }

    .trend-direction-select.ant-select {
        width: auto;
        min-width: 145px;

        .ant-select-selector {
            padding-right: 0;
        }

        .ant-select-selection-search {
            width: 0;
        }

        .ant-select-multiple .ant-select-selection-search-input {
            width: 0;
            min-width: 0;
        }
    }
}

.trend-filter-select {
    width: fit-content;
}

.container-dark {
    .filter-popover-btn {
        border: 1px solid #2a2d2f;
        background-color: #ffffff0f;

        .clear-btn {
            border-left: 1px solid #2a2d2f;
        }

        .text-active {
            color: #9d9d9f;
        }

        &:hover {
            border-color: #327eef;
        }
    }
}

.share-popover-overlay {
    border-radius: 6px;

    .ant-switch.ant-switch-small {
        width: 28px;
        min-width: 28px;
        margin-right: 8px;
    }

    .ant-popover-inner {
        padding: 12px;
        outline: none;
    }

    h5.ant-typography {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .ant-typography .ant-typography-copy {
        color: #327eef;
        height: 16px;
        margin-left: 8px;
    }

    .ant-typography .ant-typography-copy-success {
        height: 22px;
        color: #327eef;
    }

    .share-link-wrapper {
        border: 1px solid #e5e7eb;
        box-shadow: 0px 2px 4px #0000000a;
        border-radius: 6px;
        padding: 6px 8px 6px 16px;
        margin-top: 4px;

        .ant-typography {
            color: #728192;
            font-weight: 400;
        }
    }
}

.list-kanban-more-button {
    .ant-popover-open {
        width: auto !important;
    }
}

.share-popover-overlay .ant-popover-arrow {
    overflow: visible;
    right: 8px;
}

.share-popover-overlay.ant-popover-placement-bottomLeft .ant-popover-arrow {
    left: 8px;
}

.ant-dropdown.dropdown-with-arrow-menu-overlay {
    .ant-dropdown-arrow {
        overflow: visible;
        top: 12.5px;

        &::before {
            display: none;
        }
    }

    .ant-dropdown-arrow::after {
        box-shadow: none;
        z-index: 0;
        background: #fff;
        border-right: 1px solid #e5e7eb;
        border-bottom: 1px solid #e5e7eb;
    }
}

.ant-dropdown.dropdown-with-arrow-menu-overlay.dark-mode {
    .ant-dropdown-menu {
        border: 1px solid #2a2d2f;
    }

    .ant-dropdown-arrow::after {
        background: #0b0a0f;
        border-right: 1px solid #2a2d2f;
        border-bottom: 1px solid #2a2d2f;
    }

    .share-link-wrapper {
        background: #ffffff0f;
        border: 1px solid #2a2d2f;
        box-shadow: 0px 2px 4px #0000000a;

        .ant-typography {
            color: #2a2d2f;
        }
    }
}

@media (max-width: 1200px) {
    .filter-popover-btn {
        article {
            max-width: 170px;
        }
    }
}
.signup-wrapper {
    width: 400px;

    article {
        margin-bottom: 4px;
    }

    input {
        height: 38px;
        box-shadow: 0px 2px 4px #0000000a;
    }

    span.ant-input-password {
        height: 38px;
        box-shadow: 0px 2px 4px #0000000a;

        input.ant-input {
            height: 28px;
            box-shadow: none;
        }
    }

    .ant-form-item {
        margin-bottom: 20px;

        .ant-form-item-control-input {
            min-height: unset;
        }
    }

    .ant-form-item.checkbox-item {
        margin-bottom: 24px;
    }

    .back-to-signin {
        article {
            margin-bottom: 0;
        }
    }

    article.main-typography {
        font-weight: 600;
        font-size: 18px;
        color: #1f1f1f;
        margin-bottom: 4px;
    }

    article.description-typography {
        margin-bottom: 20px;
    }

    button {
        width: 100%;
        height: 38px;
        margin-bottom: 0px;
    }

    article.dark-theme-typography {
        color: #ffffff;
    }

    .repeat-trial-error-box {
        margin: 20px 0;
        font-weight: 500;
        text-align: center;
    }
}

.select-payment-plan {
    width: 400px;

    div {
        width: 100%;
    }

    .credit-card-wrapper {
        margin-bottom: 20px;
    }

    article {
        margin-bottom: 4px;
    }

    .ant-form-item {
        margin-bottom: 0;
    }

    .ant-form-item.custom-inputs {
        margin-bottom: 20px;

        input {
            height: 38px;
            box-shadow: 0px 2px 4px #0000000a;
        }
    }

    button {
        width: 100%;
        height: 38px;
        margin-bottom: 12px;
    }

    article.description-typography {
        margin-bottom: 10px;
    }

    .bottom-link {
        article {
            margin-bottom: 0;
        }
    }

    article.plan-description {
        color: #1f1f1f;
        margin-bottom: 2px;
    }

    article.dark-plan-description {
        color: #ffffff;
    }

    .check-outlined-icon {
        color: #00b32d;
        margin-right: 4px;
    }

    .billing-plans {
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .payment-method-wrapper {
        margin-bottom: 12px;
    }

    .select-payment-dropdown {
        margin-bottom: 12px;

        .ant-select-selection-item {
            color: #1f1f1f;
        }
    }

    .select-payment-dropdown.dark-theme-typography .ant-select-selection-item {
        color: #ffffff;
    }
}

.select-payment-back-btn {
    position: absolute;
    top: 24px;
    width: 40px;
    height: 40px;
    padding: 0;

    &:hover {
        border-color: #327eef !important;
    }

    svg {
        transform: rotate(-90deg);
        margin-top: 5px;
    }
}

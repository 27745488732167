.header {
    border-bottom: 1px solid #e5e7eb;

    .header-content {
        max-width: calc(100vw - 914px);
        padding-right: 16px;

        article.maint-title {
            font-weight: 500;
            font-size: 16px;

            color: #1f1f1f;
            margin-right: 10px;
        }

        article.maint-title.dark-mode {
            color: #ffffff;
        }

        article.title {
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 500;
            font-size: 16px;

            color: #728192;
            margin: 0px 10px 0px 0px;
        }

        article.title.dark-mode {
            color: #9d9d9f;
        }

        .create-section-wrapper {
            article.ant-typography {
                font-weight: 500;
                font-size: 16px;
                margin-left: 4px;
            }

            .d-flex {
                align-items: center;
            }

            .flag-wrapper {
                article.ant-typography {
                    margin-left: 0;
                    line-height: 16px;
                }
            }
        }
    }

    .ant-breadcrumb-link {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: #728192;
        white-space: nowrap;
    }

    .ant-breadcrumb ol {
        flex-wrap: nowrap;
    }

    .ant-breadcrumb ol li {
        display: flex;
        align-items: center;

        &:first-child {
            a {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 30px;
                color: #1f1f1f;

                &:hover {
                    color: #327eef;
                }
            }
        }
    }

    .ant-breadcrumb-separator {
        display: flex;
        align-items: center;
        margin-inline: 8px;
    }

    .ant-breadcrumb a {
        &:hover {
            background: transparent;
        }
    }

    .billing-breadcrumb-link {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: #728192;
        white-space: nowrap;

        &:hover {
            color: #327eef;
        }
    }

    .view-mode-switcher-wrapper {
        height: 32px;
        width: 60px;
        padding: 2px;
        background-color: #dfe3ec;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;

        .view-mode-switcher-item {
            height: 28px;
            width: 28px;
            border-radius: 4px;
            box-shadow: 0px 4px 4px #00000005;
            margin: 0px;
            padding: 0px;
            background-color: transparent;
        }

        .view-mode-switcher-item-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px;
            margin-top: 2px;
        }

        .view-mode-switcher-item-active {
            background-color: #ffffff;

            svg {
                filter: invert(38%) sepia(71%) saturate(1246%) hue-rotate(194deg) brightness(97%) contrast(94%);
            }
        }
    }

    .ant-segmented {
        height: 32px;
        width: 60px;
        padding: 2px;
        background-color: #dfe3ec;
        display: flex;
        border-radius: 4px;

        .ant-segmented-item {
            align-items: center;
            justify-content: center;
            height: 28px;
            width: 28px;
            border-radius: 4px;
            box-shadow: 0px 4px 4px #00000005;
        }

        .ant-segmented-item-label {
            padding: 0px;
            margin-top: 2px;
        }

        .ant-segmented-item-selected {
            svg {
                filter: invert(38%) sepia(71%) saturate(1246%) hue-rotate(194deg) brightness(97%) contrast(94%);
            }
        }
    }

    .ant-btn-default {
        border: none;
        box-shadow: none;
    }

    .quick-nav-btn {
        margin-bottom: 0;

        article {
            margin-left: 4px;
        }

        svg path#stroke-star {
            fill: #728192;
            stroke: none;
        }

        &:hover {
            svg path#stroke-star {
                fill: #327eef;
                stroke: none;
            }
        }
    }

    .use-setting-btn {
        width: 120px;
        height: 32px;
        margin-bottom: 0;

        article {
            margin-left: 4px;
        }
    }

    .share-setting-btn {
        width: 80px;
        height: 32px;
        margin-bottom: 0;

        article {
            margin-left: 4px;
        }
    }

    .project-setting-btn {
        min-width: 32px;
        width: 32px;
        height: 32px;
        margin-bottom: 0;
        padding: 0;
    }

    .ml-16px {
        margin-left: 16px;
    }

    .sort-wrapper {
        min-width: fit-content;
        height: 32px;
        background: #ffffff;
        border: 1px solid #e2e4ec;
        box-shadow: 0px 2px 4px #0000000a;
        border-radius: 6px;
        padding: 0 12px;

        .sort-text {
            span {
                margin-left: 4px;
            }

            svg {
                margin: 3px 0 0 4px;
            }
        }

        &:hover,
        &.ant-dropdown-open {
            border-color: #327eef;
        }
    }

    .search-input {
        width: 280px;
        box-shadow: 0px 2px 4px #0000000a;
    }

    .ant-input-affix-wrapper>input.ant-input {
        font-weight: 500;
        color: #728192;
    }

    .ant-input-affix-wrapper>input.ant-input:placeholder-shown {
        font-weight: 400;
    }
}

.header-dark {
    border-bottom: 1px solid #2a2d2f;

    .ant-breadcrumb-link {
        color: #9d9d9f;
    }

    .ant-breadcrumb ol li {
        &:first-child {
            a {
                color: #ffffff;
            }
        }
    }

    .billing-breadcrumb-link {
        color: #9d9d9f;
    }

    .view-mode-switcher-wrapper {
        background-color: #ffffff1a;

        .view-mode-switcher-item {
            box-shadow: 0px 4px 4px #00000005;
        }

        .view-mode-switcher-item-active {
            background: #327eef1a;

            svg {
                filter: invert(38%) sepia(71%) saturate(1246%) hue-rotate(194deg) brightness(97%) contrast(94%);
            }
        }
    }

    .ant-segmented {
        background-color: #ffffff1a;

        .ant-segmented-item {
            box-shadow: 0px 4px 4px #00000005;
        }

        .ant-segmented-item-selected {
            background: #327eef1a;

            svg {
                filter: invert(38%) sepia(71%) saturate(1246%) hue-rotate(194deg) brightness(97%) contrast(94%);
            }
        }
    }

    .ant-segmented-thumb {
        background-color: #327eef1a;
    }

    .sort-wrapper {
        background: #ffffff0f;
        border: 1px solid #2a2d2f;
        box-shadow: 0px 2px 4px #0000000a;
    }

    .ant-input-affix-wrapper>input.ant-input {
        color: #9d9d9f;
    }

    .quick-nav-btn {
        svg path {
            stroke: #9d9d9f;
        }

        &:hover {
            svg path {
                stroke: #327eef;
            }
        }
    }
}

.ant-modal .ant-modal-content {
    .ant-modal-close {
        &:hover {
            background: transparent;

            svg {
                filter: invert(80%) sepia(78%) saturate(810%) hue-rotate(186deg) brightness(93%) contrast(101%);
            }
        }
    }
}

.how-to-use-modal {
    .ant-modal {
        width: 800px !important;
        height: 450px;
    }

    .ant-modal-content {
        width: 800px;
        height: 450px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.sort-dropdown-overlay {
    top: 52px !important;

    &::after {
        top: -10px;
        margin-left: calc(50% - 6px);
        transform: translateY(50%) rotate(45deg);
    }
}

.ant-input-affix-wrapper {
    .ant-input {
        background: transparent;
    }
}

.ant-wave {
    display: none;
}

.share-dropdown-overlay.ant-dropdown {
    border-radius: 6px;
    min-width: 500px;

    .toggle-container {
        display: flex;
        align-items: center;
    }

    .dropdown-with-extra-content-wrapper {
        padding: 12px;
    }

    .ant-dropdown-arrow {
        overflow: visible;

        &::before {
            display: none;
        }
    }

    .ant-dropdown-arrow::after {
        box-shadow: none;
        z-index: 0;
        background: #fff;
        border-right: 1px solid #e5e7eb;
        border-bottom: 1px solid #e5e7eb;
    }

    .ant-switch.ant-switch-small {
        width: 28px;
        min-width: 28px;
        margin-right: 8px;
    }

    .ant-dropdown-menu {
        padding: 20px;
        outline: none;
        box-shadow: 0px 4px 12px #0000001f;
        border: 1px solid #e5e7eb;

        .ant-dropdown-menu-item:hover {
            background-color: transparent;
            cursor: auto;
        }
    }

    h5.ant-typography {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        white-space: nowrap;
    }

    .ant-typography .ant-typography-copy {
        color: #327eef;
        height: 16px;
        margin-left: 8px;
    }

    .ant-typography .ant-typography-copy-success {
        height: 22px;
        color: #327eef;
    }

    .share-link-wrapper {
        border: 1px solid #e5e7eb;
        box-shadow: 0px 2px 4px #0000000a;
        border-radius: 6px;
        padding: 6px 8px 6px 16px;
        margin-top: 4px;

        .ant-typography {
            color: #728192;
            font-weight: 400;
        }
    }
}

.share-dropdown-overlay-dark.ant-dropdown {
    .ant-dropdown-menu {
        border: 1px solid #2a2d2f;
    }

    .ant-dropdown-arrow::after {
        background: #0b0a0f;
        border-right: 1px solid #2a2d2f;
        border-bottom: 1px solid #2a2d2f;
    }

    .share-link-wrapper {
        background: #ffffff0f;
        border: 1px solid #2a2d2f;
        box-shadow: 0px 2px 4px #0000000a;

        .ant-typography {
            color: #9d9d9f;
        }
    }
}

.project-settings.dropdown-with-arrow-overlay.dropdown-menu {
    width: 310px;
}

.project-settings {
    .ant-dropdown-menu.ant-dropdown-menu-root {
        padding: 12px 8px 8px;
    }

    .project-settings-user-info-wrapper {
        margin: 4px 16px 12px;
        padding-top: 12px;
        border-top: 1px solid #e5e7eb;

        article {
            margin-bottom: 4px;
        }
    }

    .data-option {
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        width: 100%;
        margin: 0;
        color: #728192;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.project-settings.dark-mode {
    .data-option {
        color: #9d9d9f;
    }

    &::after {
        background: #0b0a0f;
        border-left: 1px solid #2a2d2f;
        border-top: 1px solid #2a2d2f;
    }

    .project-settings-user-info-wrapper {
        border-top: 1px solid #2a2d2f;
    }
}

.project-settings {
    &::after {
        left: -1px;
        bottom: 0px;
        margin-left: 15px;
        transform: translateY(50%) rotate(-135deg);
    }
}

@media screen and (max-width: 1200px) {
    .header {
        .search-input {
            margin-left: 10px;
        }

        .header-content {
            max-width: calc(100vw - 820px);
        }
    }
}

.date-range-filter-wrapper {
    margin-left: 16px;

    .filter-popover-btn {
        height: 32px;
        border: 1px solid #e2e4ec;
        box-shadow: 0px 2px 4px #0000000a;
        margin: 0;
    }

    &.dark-mode .filter-popover-btn {
        background: #ffffff0f;
        border: 1px solid #2a2d2f;
        box-shadow: 0px 2px 4px #0000000a;
    }
}

.date-filter-range-picker {
    &:hover {
        border-color: #327eef;
    }

    div.ant-picker-input input {
        color: #728192;

        &::placeholder {
            color: #728192;
        }
    }

    &.dark-mode {
        &:hover {
            border-color: #327eef;
        }

        div.ant-picker-input input {
            color: #9d9d9f;

            &::placeholder {
                color: #9d9d9f;
            }
        }

        border-color: #2a2d2f;
    }
}

.date-filter-range-picker-dropdown-content {
    button {
        width: auto;
    }

    div.ant-picker-header {
        border: none;
    }

    div.ant-picker-range-wrapper.ant-picker-date-range-wrapper div.ant-picker-range-arrow {
        display: none;
    }

    &.dark-mode {
        .ant-picker-cell.ant-picker-cell-in-view {
            color: #9d9d9f;
        }

        div.ant-picker-date-panel {

            div.ant-picker-header,
            button span {
                color: #9d9d9f;
            }
        }

        table.ant-picker-content th,
        table.ant-picker-content tr td.ant-picker-cel.ant-picker-cell-in-view {
            color: #9d9d9f;
        }

        .ant-picker-panel-container {
            color: #9d9d9f;
            background: #16161c;
        }
    }
}
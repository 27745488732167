.error-message-wrapper {
    padding: 20px;
    margin: 8px 0 16px;
    background: #fff8f8;
    border: 1px solid #ef3e324d;
    box-shadow: 0px 2px 4px #0000000a;
    border-radius: 6px;

    h5.ant-typography {
        margin-bottom: 0;
    }

    .error-message-text-wrapper {
        margin-left: 12px;

        .error-message-text {
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 0;
        }
    }
}

.error-message-wrapper-dark {
    background: #16161C;
}

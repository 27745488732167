.versions-popover-overlay.popover-overlay {
    .ant-popover-inner {
        padding: 0;
        overflow: hidden;
    }

    .ant-popover-arrow {
        overflow: visible;
        right: 26px;
    }
}

article.article-title {
    font-weight: 400;
    font-size: 12px;

    margin-bottom: 4px;

    color: #1f1f1f;
}

article.article-title.dark-mode {
    color: #ffffff;
}

.version-history-button {
    width: 80px;
    min-width: 80px;
    height: 38px;
    padding: 0;
    text-align: center;
    border-radius: 6px;

    border: none;

    font-weight: 500;
    font-size: 14px;

    margin-bottom: 0;

    .ant-btn-primary:disabled {
        height: inherit;
    }
}

.create-version-history-button {
    width: 100%;
    margin-bottom: 20px;
}

.dropdown-message {
    padding: 16px 22px;
    border-top: 1px solid #e5e7eb;
    background: #f6f8fa;

    article {
        font-weight: 400;
        font-size: 14px;
        text-align: center;

        color: #1f1f1f;
    }
}

.dropdown-message.dark-mode {
    border-color: #2a2d2f;
    background: #2a2d2f;

    article {
        color: #ffffff;
    }
}

.version-dropdown-menu-item-content {
    width: 420px;
}

.items-section {
    padding: 24px 20px 0px;
    max-height: 406px;
    overflow-y: auto;
}

.version-item {
    margin-bottom: 18px;

    article {
        font-weight: 500;
        font-size: 14px;
    }

    article.main-article {
        color: #1f1f1f;
        margin-bottom: 4px;
    }

    .second-article-section {
        display: flex;

        article {
            margin-right: 12px;
        }
    }

    article.second-article {
        color: #728192;
    }

    .version-name {
        margin-right: 6px;

        .copy-icon {
            display: none;
            min-width: 22px;

            &:first-of-type {
                margin-left: 4px;
            }
        }
    }

    .ant-input {
        margin: 4px 0 6px;
    }

    &:hover {
        .version-name .copy-icon {
            display: flex;
        }
    }
}

.version-item.dark-mode {
    article.main-article {
        color: #ffffff;
    }
    article.second-article {
        color: #9d9d9f;
    }

    .copy-icon:hover {
        background-color: #327eef1a;
    }
}

.version-preview-popconfirm.delete-popconfirm {
    width: 310px;
}

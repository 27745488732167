@import '../../../../resources/styles/variables.scss';

.sider-nav-acc-wrapper {
    height: 32px;
}

.sider-nav-settings-wrapper {
    height: calc(100% - 82px);
}

.sider-settings {
    padding: 0 24px;

    .sider-settings-item {
        padding-bottom: 20px;

        .d-flex {
            flex-wrap: nowrap;
        }

        article {
            flex: auto;
            min-width: 0;
            overflow: hidden;
            white-space: nowrap;
        }

        &:last-child {
            border-bottom: 1px solid #e5e7eb;
        }
    }

    .sider-acc-settings-item {
        .d-flex {
            flex-wrap: nowrap;
        }

        article {
            flex: auto;
            min-width: 0;
            overflow: hidden;
            white-space: nowrap;
        }

        height: 64px;
    }

    .ant-switch.ant-switch-small {
        width: 30px;
    }

    .credits-setting-wrapper {
        width: fit-content;
    }

    .credits-remaining-row {
        display: flex;

        margin-bottom: 4px;
    }
}

.sider-dark {
    .sider-settings {
        .sider-settings-item {
            &:last-child {
                border-bottom: 1px solid #2a2d2f;
            }

            .theme-switcher.collapsed svg path {
                stroke: $primary-color;
            }
        }

        .sider-acc-settings-item {
            height: 64px;
        }
    }
}

.nav-acc-overlay {
    .ant-tooltip {
        display: none;
    }

    &::after {
        margin: 0;
        margin-left: 15px;
        transform: translateY(50%) rotate(-135deg);
        border-left: 1px solid #e5e7eb;
        border-top: 1px solid #e5e7eb;
    }

    .ant-dropdown-menu .ant-dropdown-menu-item {
        &:not(.ant-dropdown-menu-item-disabled):hover {
            svg:not(#affiliate-icon) path {
                stroke: #327eef;
            }

            svg#affiliate-icon path {
                fill: #327eef;
            }
        }

        &:last-child {
            margin-top: -4px;
            height: 46px;
            padding: 0;

            &:hover {
                background: transparent;

                .logout-btn {
                    background: #f3f7fd;
                    border-radius: 6px;

                    svg {
                        filter: invert(38%) sepia(71%) saturate(1246%)
                            hue-rotate(194deg) brightness(97%) contrast(94%);
                    }

                    article {
                        color: #327eef;
                    }
                }
            }
        }
    }

    .nav-acc-user-info-wrapper {
        margin: 12px 16px 0;
        padding-bottom: 8px;
        border-bottom: 1px solid #e5e7eb;
    }

    .nav-acc-user-name {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        width: 145px;
        margin: 0;
        letter-spacing: 0.4px;
        color: #1f1f1f;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .nav-acc-company {
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        width: 145px;
        margin: 0;
        color: #728192;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .line {
        height: 1px;
        background: #e5e7eb;
    }

    .bottom-line {
        margin: 8px 0 0;
    }

    .top-line {
        margin: 0 8px 8px;
    }

    .logout-btn {
        padding: 4px 8px;
    }
}

.nav-acc-dark-overlay {
    .nav-acc-user-info-wrapper {
        border-bottom: 1px solid #2a2d2f;
    }

    .ant-dropdown-menu .ant-dropdown-menu-item {
        &:last-child {
            &:hover {
                .logout-btn {
                    background: #327eef17;
                }
            }
        }
    }

    .nav-acc-user-name {
        color: #ffffff;
    }

    .nav-acc-company {
        color: #9d9d9f;
    }

    .line {
        background: #2a2d2f;
    }

    &::after {
        background: #0b0a0f;
        border-left: 1px solid #2a2d2f;
        border-top: 1px solid #2a2d2f;
    }
}

.nav-acc-overlay {
    &::after {
        left: -1px;
        bottom: 0px;
        margin-left: 15px;
        transform: translateY(50%) rotate(-135deg);
    }
}

.nav-acc-overlay-collapsed {
    left: 54px !important;

    &::after {
        left: -1px;
        bottom: 20px;
        margin-left: -4px;
        transform: translateY(50%) rotate(-45deg);
    }
}

.help-setting-dropdown-overlay {
    left: 90px !important;

    &::after {
        bottom: 62px;
        margin-left: 0;
        left: -4.5px;
        transform: translateY(50%) rotate(-45deg);
    }

    .ant-dropdown-menu .ant-dropdown-menu-item {
        &-active:not(:hover) {
            background: transparent;
        }

        &:hover {
            div.text-decoration-dotted {
                text-decoration-color: #327eef;
            }
        }

        &:first-child:hover {
            svg path {
                fill: #327eef;
                stroke: none;
            }
        }
    }

    &.dropdown-with-arrow-overlay.ant-dropdown .ant-dropdown-arrow {
        left: -32px;
        top: calc(50% - 16px);
        transform: rotate(270deg);
    }
}

.help-setting-dropdown-overlay-collapsed {
    left: 54px !important;
}

.credits-setting-tooltip-overlay-collapsed {
    left: 40px !important;
}

.fast-start-guide-modal {
    button.ant-modal-close {
        top: 2px;
        inset-inline-end: 1px;
    }

    div.ant-modal {
        height: 450px;
    }
}

.help-dropdown-menu {
    .ant-dropdown-menu .ant-dropdown-menu-item {
        &:not(.ant-dropdown-menu-item-disabled):hover {
            svg path {
                fill: #327eef;
            }
        }
    }
}

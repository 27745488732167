.complete-progress-bar {
    display: flex;
    flex-direction: column;

    color: #ffffff;
    background: #ffffff;
    box-shadow: 0px 4px 25px #33333308;

    border: 1px solid #e5e7eb;
    border-radius: 4px;

    position: relative;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    min-width: 300px;

    height: 23px;

    .complete-progress-bar-progress {
        min-width: 125px;
        padding: 0 8px;
        border-radius: 4px;
        transition: width 0.3s;
        white-space: nowrap;

        position: absolute;
        top: -1px;
        left: -1px;
        height: 23px;
    }
}

.null-value-progress-bar {
    color: #1f1f1f;

    .complete-progress-bar-progress {
        min-width: 30px;
    }

    span {
        margin-top: -2px;
    }
}

.complete-progress-bar-dark {
    background: #16161c;
    border: 1px solid #2a2d2f;
    box-shadow: 0px 4px 25px #33333308;
}

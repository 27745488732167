div.monitor-results-filter-container {
    .filter-popover-btn {
        margin-bottom: 0;
    }
}

.monitor-users-filter-checkbox-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .monitor-users-filter-checkbox {
        margin-right: 10px;
    }
}

.monitor-filter-range-picker {
    &:hover {
        border-color: #327eef;
    }
    div.ant-picker-input input {
        color: #728192;

        &::placeholder {
            color: #728192;
        }
    }

    &.dark-mode {
        &:hover {
            border-color: #327eef;
        }

        div.ant-picker-input input {
            color: #9d9d9f;

            &::placeholder {
                color: #9d9d9f;
            }
        }

        border-color: #2a2d2f;
    }
}

.monitor-filter-range-picker-dropdown-content {
    button {
        width: auto;
    }

    div.ant-picker-header {
        border: none;
    }

    div.ant-picker-range-wrapper.ant-picker-date-range-wrapper
        div.ant-picker-range-arrow {
        display: none;
    }

    &.dark-mode {
        .ant-picker-cell.ant-picker-cell-in-view {
            color: #9d9d9f;
        }

        div.ant-picker-date-panel {
            div.ant-picker-header,
            button span {
                color: #9d9d9f;
            }
        }

        table.ant-picker-content th,
        table.ant-picker-content tr td.ant-picker-cel.ant-picker-cell-in-view {
            color: #9d9d9f;
        }

        .ant-picker-panel-container {
            color: #9d9d9f;
            background: #16161c;
        }
    }
}

.monitor-projects-dropdown-overlay {
    max-height: 400px;
    overflow-y: auto;
}

.anticon-info-circle {
    margin-left: 3px;

    &:hover {
        color: #327eef;
    }

    svg {
        min-width: 16px;
        overflow: visible;
    }
}

.legend-modal.modal-wrapper {
    .ant-modal {
        .ant-modal-title {
            font-size: 22px;
            text-align: center;
        }
    }

    .form-fields-wrapper {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .ant-modal {
        width: 500px !important;
    }
}

@import '../../../resources/styles/variables.scss';

.keyword-header-wrapper {
    .ant-form-item {
        width: fit-content;
        margin-right: 0;
        margin-bottom: 0;
    }

    .ant-btn.ant-btn-lg {
        padding: 0 26px;
    }

    .ant-tooltip-disabled-compatible-wrapper {
        cursor: default !important;
    }
}

.create-area-wrapper {
    .ant-form-item .ant-form-item-label > label {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $light-typography-primary-color;
    }
}

.create-multiple-section {
    .options-wrapper.keyword-header-wrapper .ant-form-item {
        width: 100%;
        margin-right: 16px;

        &:last-child {
            margin-right: 0;
            width: fit-content;
        }
    }
}

.area-multiple-wrapper {
    border: 1px solid $light-border-color;
    box-shadow: 0px 2px 4px $light-box-shadow-color;
    border-radius: 6px;
    overflow: hidden;
    height: 108px;
    width: 530px;

    .text-area-wrapper {
        position: relative;
        width: 100%;
        height: 108px;

        .area-multiple-textarea,
        textarea {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 0 16px;
        }

        textarea {
            background-color: transparent;
        }

        .highlighter-wrapper {
            color: transparent;
        }
    }

    .highlighted-text {
        padding: 2px 0;
        background: $light-success-bg-color;

        &.dark-mode {
            background: $dark-success-bg-color;
        }
    }

    .ant-input {
        border: none;
        padding: 0 16px 0;
        border-radius: 0;
    }

    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
        border-color: transparent;
    }

    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
        box-shadow: none;
    }

    .area-multiple-list-number-wrapper {
        width: 40px;
        background: $light-layout-bg-color;
        border-right: 1px solid $light-border-color;
        color: $light-typography-primary-color;
        padding-top: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
    }

    .area-multiple-list-number {
        overflow-y: hidden;
        text-align: center;
    }

    .area-multiple-textarea {
        white-space: pre;
        overflow-y: scroll;
        height: 107px;
        min-height: 107px !important;
        border-top: 8px solid transparent;
    }

    .ant-input {
        transition: all 0.3s, height 0s, background-color 0s, color 0s,
            border-color 0s;
    }

    .ant-input:focus,
    .ant-input-focused {
        box-shadow: none;
    }

    .ant-input:placeholder-shown {
        overflow-wrap: break-word;
        white-space: pre-wrap !important;
    }
}

.input-error {
    border-color: $error-color;

    &:hover {
        border-color: $error-color;
    }
}

.no-credits-modal-wrapper {
    .ant-modal-content .ant-modal-body {
        margin-top: 22px;

        div {
            margin-bottom: 6px;
        }
    }
}

.no-credits-modal-wrapper.dark-mode, .duplicate-optimizer-modal-wrapper.dark-mode {
    .ant-modal-content {
        color: $dark-typography-primary-color;
    }
}

.duplicate-optimizer-modal-wrapper {
    .ant-modal-content {
        width: 530px;
        padding: 16px;

        .ant-row {
            gap: 8px;
        }

        .ant-modal-body {
            .ant-row {
                .ant-col {
                    div {
                        margin-bottom: 6px;
                    }
                }

                margin: 10px 0 8px 0;
            }
        }

        .ant-modal-title {
            &.duplicate-optimizer-header {
                margin-bottom: 5px;
            }
    
            .keyword-wrapper {
                display: flex;
                flex-direction: row;
                gap: 10px;
                align-items: center;
                color: $basic-gray;
                font-weight: 500;
            }
        }
    
        .actions-wrapper {
            height: fit-content;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            gap: 8px;
    
            .ant-btn {
                width: auto;
                margin-bottom: 2px;
            }
        }

        .icon-wrapper {
            svg {
                display: block;
            }

            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 3px;
        }
    }
}
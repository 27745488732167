div.monitor-tab-extra-content {
    display: flex;
    align-items: center;
    div {
        margin-left: 14px;
    }
}

article.add-urls-text {
    min-width: 40px;
}

div.monitor-results-filter-wrapper {
    margin-left: 40px;
}

.content-monitor-container {
    .ant-tabs .ant-tabs-content-holder {
        padding: 24px;
    }

    div.ant-tabs-nav {
        div.ant-tabs-nav-wrap {
            flex: none;
        }

        div.ant-tabs-extra-content {
            flex: 1 1;

            button.import-url-button {
                width: 30px;

                margin-left: 12px;
            }
        }
    }
}

.monitor-date-warning-notification {
    margin-right: 24px;
    margin-bottom: -12px;
}

.outline-tab-wrapper {
    .ranks-container-wrapper {
        position: relative;
    }

    .section-title {
        margin-bottom: 6px;

        h5.ant-typography {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 0;
        }
    }

    .questions-wrapper {
        .question-item {
            display: flex;
            align-items: center;
            border-radius: 6px;
            padding: 4px 4px 4px 8px;
            cursor: pointer;

            .ant-typography {
                margin-bottom: 0;
            }

            .add-button {
                svg {
                    path {
                        stroke: transparent;
                    }
                }
            }
            
            &:hover {
                background: #f3f7fd;
        
                .add-button {
                    border: 1px solid #327eef;
                    color: #327eef;
                    background-color: transparent;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    padding: 0 4px;
        
                    svg {
                        path {
                            stroke: #327eef;
                        }
                    }
                }
        
                .ant-btn.ant-btn-icon-only {
                    background-color: #ffffff;
                }
            }

            .add-button-wrapper {
                width: 20px;
                height: 20px;
                margin-left: auto;
                align-self: flex-start;
            
                .ant-btn.ant-btn-icon-only {
                    margin-top: 2px;
                    width: 18px;
                    height: 18px;
                    padding: 0;
                }
            }
        }
        
        .ant-typography {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 6px;
        }
    }

    .questions-wrapper.dark-mode {
        .question-item:hover {
            background: #327eef1a;
            
            .add-button-wrapper {
                .ant-btn.ant-btn-icon-only {
                    background-color: #2a2d2f;
                }
            }
        }
    }
    .questions-section {
        padding: 20px 12px;
    }
}
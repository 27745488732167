.error-page-root {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    article.main-article {
        font-weight: 700;
        font-size: 24px;
        text-align: center;

        color: #1f1f1f;

        margin-top: 30px;
        margin-bottom: 8px;
    }

    article.main-article.dark-mode {
        color: #ffffff;
    }

    article.second-article {
        width: 450px;

        font-weight: 400;
        font-size: 14px;
        text-align: center;

        color: #728192;

        margin-bottom: 20px;
    }

    article.second-article.dark-mode {
        color: #9d9d9f;
    }
}

.go-back-error-button {
    min-width: 140px;
    width: fit-content;
    height: 38px;
    background: #327eef;
    box-shadow: 0px 4px 12px #00000014;
    border-radius: 6px;
    padding: 0 20px;
    
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
}

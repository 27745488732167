@import '../../../../resources/styles/variables.scss';

.editor-toolbar {
    height: 40px;
    position: relative;
    z-index: 1;

    display: flex;
    padding: 4px 4px 3px;
    background-color: #ffffff;
    border-bottom: 1px solid #e5e7eb;

    button {
        padding: 8px;
        width: 32px;
        height: 32px;
        margin: 0px;
        border: none;
        box-shadow: none;

        &:hover {
            background: #f3f7fd;
        }
    }

    button.active {
        background: #f3f7fd;
    }

    button.ant-btn-default:disabled {
        background-color: transparent;
    }

    .arrow-icon {
        height: 16px;
    }

    .heading-section {
        border-right: 1px solid #e5e7eb;
        width: 112px;

        .heading-button {
            width: 110px;
            flex-direction: row-reverse;
            align-items: center;
            padding-right: 6px;

            &:hover {
                background: #f3f7fd;
                border-radius: 6px;
            }

            span {
                font-weight: 400;
                color: #1f1f1f;
            }
        }
    }

    .heading-section.dark-mode {
        border-color: #2a2d2f;

        .heading-button {
            &:hover {
                background: #ffffff33;
            }

            span {
                color: #9d9d9f;
            }
        }
    }

    .mark-buttons-section {
        display: flex;
        border-right: 1px solid #e5e7eb;
        margin-left: 1px;
        padding-right: 1px;
    }

    .list-buttons-section {
        display: flex;

        border-right: 1px solid #e5e7eb;
        margin-left: 1px;
        padding-right: 1px;

        .align-button {
            width: 50px;
            flex-direction: row-reverse;
            align-items: center;
            padding-right: 6px;

            &:hover {
                background: #f3f7fd;
                border-radius: 6px;
            }

            span {
                color: #1f1f1f;
            }
        }

        .text-align-dropdown.dark-mode {
            border-color: #2a2d2f;

            .ant-select {
                &:hover {
                    background: #ffffff33;
                }

                span.ant-select-selection-item {
                    color: #9d9d9f;
                }
            }
        }
    }

    .gallery-buttons-section {
        display: flex;
        border-right: 1px solid #e5e7eb;
        margin-left: 1px;
        padding-right: 1px;
    }

    .undo-redo-buttons-section {
        display: flex;
        margin-left: 1px;
        padding-right: 1px;
    }

    .ai-button-section {
        display: flex;
        margin-right: 6px;

        button {
            display: flex;
            width: fit-content;
            align-items: center;
            padding: 8px 8px 8px 4px;
            margin-left: 4px;
            border-radius: 4px;

            article {
                color: #1f1f1f;

                &.dark-mode {
                    color: #9d9d9f;
                }
            }

            &:hover {
                border-color: #327eef;
                background-color: #327eef1a;

                article {
                    color: #327eef;
                }

                svg > path {
                    fill: #327eef;
                }
            }

            &.ant-btn:disabled {
                background-color: #327eef1a;
            }
        }
    }

    .mark-buttons-section.dark-mode,
    .list-buttons-section.dark-mode,
    .gallery-buttons-section.dark-mode,
    .undo-redo-buttons-section.dark-mode,
    .ai-button-section.dark-mode,
    .ai-section.dark-mode {
        border-color: #2a2d2f;

        button {
            &:hover {
                background: #ffffff33;
            }
        }

        button.active {
            background: #ffffff33;
        }
    }

    .ant-select-single .ant-select-selector {
        padding: 0 6px 0 8px;
    }

    .editor-more-btn.ant-btn {
        margin-left: 4px;
    }

    .version-history .version-status {
        margin-right: 4px;
    }
}

.ai-section {
    border-left: 1px solid #e5e7eb;

    button.ai-button,
    span.ai-button button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        box-shadow: none;

        width: auto;
        height: 32px;

        margin-left: 2px;
        margin-bottom: 0;
        padding: 0 12px 0 4px;

        border-radius: 4px;

        svg {
            margin-left: 2px;
        }

        &:hover {
            background: #f3f7fd;
        }
    }

    button.ai-active-button {
        background-color: #327eef1a;

        article {
            color: #327eef;
        }

        svg path {
            stroke: #327eef;
        }

        .load-icon-wrapper {
            border-color: #327eef;

            svg > path {
                fill: #327eef;
            }
        }
    }

    .ai-loading {
        svg {
            animation: rotate 1s linear infinite;
        }
    }
}

.ai-option-item {
    min-width: 160px;

    svg {
        margin-right: 4px;
        max-width: 18px;
        min-width: 18px;
        width: 18px;
        height: 18px;
    }
}

.ai-section.dark-mode {
    button.ai-button.ant-btn {
        &:hover {
            background: #ffffff33;
        }
    }
}

.editor-toolbar.dark-mode {
    background-color: #16161c;
    border-color: #2a2d2f;
}

.custom-buttons-tooltip {
    font-size: 14px;

    article {
        font-weight: 500;
        color: #1f1f1f;
    }

    article.dark-mode {
        color: #ffffff;
    }
}

.editor-text-align-dropdown {
    min-width: 40px !important;
    width: 40px !important;

    .ant-select-item.ant-select-item-option {
        width: 32px;
        height: 32px;
        padding: 8px;
    }
}

.credits-wrapper {
    padding: 40px;

    .ant-typography {
        margin: 0;
    }

    h2.ant-typography {
        margin-bottom: 32px;
        font-size: 24px;
        line-height: 34px;
    }

    .label.ant-typography {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }

    .credits-card-wrapper {
        width: 750px;
        margin-bottom: 24px;

        .credits-section-wrapper {
            border-bottom: 1px solid #e5e7eb;
        }

        .credits-select-wrapper {
            padding-bottom: 24px;

            .ant-select-single {
                width: 100%;
                margin-top: 4px;
            }

            .ant-select-selector {
                height: 38px;
                color: #1f1f1f;

                .ant-select-selection-item {
                    font-weight: 500;
                    line-height: 36px;
                }
            }
        }

        .credits-info-wrapper {
            padding: 21px 0 16px;

            .credits-info-block {
                margin-bottom: 6px;
            }

            h5.ant-typography {
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                margin: 0;
            }

            h5.credits-price.ant-typography,
            h4.ant-typography {
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
                margin: 0;
            }

            h4.credits-price.ant-typography {
                font-weight: 700;
                font-size: 22px;
                line-height: 34px;
            }
        }

        .payment-info-section-wrapper {
            padding: 16px 0 20px;

            .ant-typography {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                margin-bottom: 8px;

                & .text-accent {
                    font-weight: 500;
                }
            }
        }

        .credits-button-wrapper {
            .ant-btn {
                width: 180px;
                height: 38px;
                margin-bottom: 0;
            }
        }
    }
}

.credits-wrapper-dark {
    .credits-card-wrapper {
        .credits-section-wrapper {
            border-bottom: 1px solid #2a2d2f;
        }

        .credits-select-wrapper {
            .ant-select-selector {
                height: 38px;
                color: #fff;
            }
        }

        .credits-payment-info-wrapper {
            .ant-typography {
                & .fw-500 {
                    color: #fff;
                }
            }
        }
    }
}

@import '../../../../../../../../../resources/styles/variables.scss';

.version-history-container {
    padding: 4px 18px;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #e5e7eb;
    position: relative;
    z-index: 2;
    height: 40px;

    .brief-tab-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        margin-left: 16px;
        width: 100%;

        h5 {
            display: flex;
            align-items: center;
            margin-bottom: 0;
            font-size: 14px;
        }

        .ai-button {
            margin-left: auto;

            button {
                display: flex;
                width: fit-content;
                align-items: center;
                border: none;
                border-radius: 4px;
                padding: 8px 8px 8px 4px;
                margin-bottom: 0;
                box-shadow: none;

                article {
                    color: #1f1f1f;

                    &.dark-mode {
                        color: #9d9d9f;
                    }
                }

                &:hover {
                    border-color: #327eef;
                    background-color: #327eef1a;

                    article {
                        color: #327eef;
                    }

                    svg > path {
                        fill: #327eef;
                    }
                }

                &.ant-btn:disabled {
                    background-color: #327eef1a;
                }
            }
        }
    }
}

.version-history-container.dark-mode {
    border-color: #2a2d2f;
}

.delete-popconfirm {
    z-index: 1051;

    .ant-popover-arrow {
        right: 25px;
        overflow: visible;

        &::before {
            display: none;
        }

        &::after {
            box-shadow: none;
            background: #fff;
            border-right: 1px solid #e5e7eb;
            border-bottom: 1px solid #e5e7eb;
        }
    }

    .ant-popover-inner {
        background-color: #ffffff;
        border-radius: 6px;
        box-shadow: 0px 4px 12px #0000001f;
        padding: 16px;
        border: 1px solid #e5e7eb;
    }

    .ant-popconfirm-buttons {
        text-align: end;
        display: flex;
        justify-content: flex-end;

        .ant-btn.ant-btn-sm {
            width: fit-content;
            margin-bottom: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
        }

        .ant-btn-default {
            background-color: transparent;
            border-color: #327eef;
            color: #327eef;

            &:hover {
                background-color: #327eef1a;
            }
        }
    }
}

.delete-popconfirm-dark {
    .ant-popconfirm-message-title {
        color: #fff;
    }

    .ant-popover-arrow {
        &::after {
            background: #0b0a0f;
            border-right: 1px solid #2a2d2f;
            border-bottom: 1px solid #2a2d2f;
        }
    }

    .ant-popover-inner {
        background-color: #0b0a0f;
        border: 1px solid #2a2d2f;
    }
}

.version-history-dropdown-title {
    span {
        color: $light-typography-secondary-color;
    }

    span:hover {
        color: $primary-color;
    }
}

.version-history-dropdown-title-dark {
    span {
        color: $dark-typography-secondary-color;
    }

    span:hover {
        color: $primary-color;
    }
}

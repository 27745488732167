.view-only-card {
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    overflow: hidden;

    .ant-card-head {
        padding: 6px 24px;

        border-bottom: 1px solid #e5e7eb;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;

        background: #f6f8fa;

        font-weight: 600;
        font-size: 18px;
        text-transform: capitalize;
        color: #1f1f1f;
    }

    .ant-card-body {
        padding: 14px 22px;
    }

    .basic-outline-tag-wrapper:hover {
        background: transparent;
        cursor: auto;
    }
}

.view-only-card-dark-mode {
    border: 1px solid #2a2d2f;

    color: #ffffff;

    .ant-card-head {
        border-bottom: 1px solid #2a2d2f;

        color: #ffffff;
        background: #2a2d2f;
    }
}

.active-view-only-card {
    border: 1px solid #327eef;

    .ant-card-head {
        border-bottom: 1px solid #327eef;

        color: #ffffff;
        background: #327eef;
    }
}

.view-only-wrapper {
    width: 100%;

    .left-column {
        padding-right: 12px;
    }

    .right-column {
        padding-left: 12px;
    }

    .empty-message {
        padding: 24px;
    }
}

.view-only-mode {
    margin-bottom: 24px;
}

.sider-settings-item.view-only-item {
    padding-bottom: 16px;
}

.view-only-content-wrapper.ant-layout-content {
    margin: 0px;
    height: calc(100vh - 56px);
    overflow-y: auto;
    padding: 0 24px 24px;

    border: none;

    .basic-data-block {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.card-wrapper {
    padding-top: 24px;
    scroll-margin-top: 54px;
    scroll-margin-left: 20px;
    scroll-margin-right: 20px;
}

.optimizer-view-only-nav-menu {
    .ant-menu-item {
        svg {
            margin-right: 8px;
        }
    }

    .ant-menu-item-selected {
        svg path {
            stroke: #327eef;
        }

        #stroke-star {
            stroke: none;
            fill: #327eef;
        }
    }
}

.sider-collapsed {
    .optimizer-view-only-nav-menu {
        .ant-menu-title-content {
            display: none;
        }
    }
}

@import '../../../../resources/styles/variables.scss';

.invite-user-button {
    width: 126px;
    height: 32px;

    &.ant-btn.ant-btn-lg.ant-btn-primary.ant-btn-background-ghost  {
        height: 32px;
        font-size: 14px;
        padding: 0 12px 0 10px;
        width: fit-content;

        &.disabled {
            color: #c3cfe4;
            border-color: #c3cfe4;
    
            path {
                stroke: #c3cfe4;
            }
        }

        &.disabled:hover {
            background-color: #ffffff;
            cursor: default;
        }
    }

    &.dark-mode {
        &.disabled {
            background-color: #2e2e33 !important;
        }

        .ant-btn-lg:hover {
            background-color: $dark-primary-bg-color;
        }

        .ant-btn-lg.disabled {
            background-color: #2e2e33 !important;
        }
    }    

    span {
        margin-left: 5px;
    }
}

.users-content-wrapper {
    .users-subtitle {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        margin: 0;
    }

    .users-table-title-wrapper {
        margin-bottom: 16px;

        article {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }
    }

    .users-table-wrapper {
        padding: 16px 24px 0;
        overflow-y: scroll;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;

        .ant-badge {
            margin-left: 8px;
        }

        .btn-icon {
            width: 32px;
            height: 32px;
            border-radius: 6px;

            svg {
                width: 14px;
            }
        }

        .users-table-header-title {
            width: fit-content;

            .ant-typography {
                font-size: 10px;
            }

            .active {
                color: #327eef;
            }

            svg {
                margin-left: 4px;
            }

            svg > path {
                stroke: transparent;
            }

            &:hover {
                svg > path {
                    stroke: #9199ad;
                }
            }

            svg.active > path {
                stroke: #327eef;
            }
        }

        .link-hover-highlight {
            margin-left: -8px;
        }

        .link-wrapper .ant-typography.ant-typography-disabled {
            color: #327eef;
            background-color: transparent;
            cursor: auto;
        }

        .actions-wrapper .link-wrapper {
            &:last-child {
                width: 50px;
                margin-left: 12px;
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .ant-form-item {
        margin-bottom: 0;
    }

    .ant-input-status-error {
        margin-top: 21px;
        transition: 500ms;
    }

    .ant-input-status-success {
        margin-top: 0;
        transition: 500ms;
    }

    .ant-input:focus,
    .ant-input-focused {
        box-shadow: none;
    }

    .ant-input-status-error:not(.ant-input-disabled):not(
            .ant-input-borderless
        ).ant-input:focus {
        box-shadow: none;
    }

    .ant-input-status-error:not(.ant-input-disabled):not(
            .ant-input-borderless
        ).ant-input {
        border-color: #ef3e32;
    }

    .ant-input,
    .ant-select {
        box-shadow: 0px 2px 4px #0000000a;
    }

    .users-buttons-wrapper {
        height: 64px;
        border-top: 1px solid #e5e7eb;
        box-shadow: 0px -2px 16px #0000000a;

        .ant-btn.ant-btn-lg {
            width: 80px;

            &:first-child {
                margin-right: 14px;
            }
        }
    }

    .account-loader-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
    }

    .ant-select-single .ant-select-selector {
        color: #1f1f1f;
    }
}

.container-dark {
    .users-content-wrapper {
        .users-buttons-wrapper {
            border-top: 1px solid #2a2d2f;
        }

        .ant-select-single .ant-select-selector {
            color: #fff;
        }
    }
}

.invite-user-modal,
.edit-user-modal {
    .ant-modal {
        width: 600px !important;
        top: calc(50% - 295px);
        padding-bottom: 0;

        .ant-modal-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
        }

        .ant-modal-content {
            padding: 18px 24px 24px;
        }
    }

    .form-fields-wrapper {
        border-top: 1px solid #e5e7eb;
        border-bottom: 1px solid #e5e7eb;
        margin: 16px 0 24px;
        padding: 20px 0;
    }

    .modal-subtitle {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 16px;
        letter-spacing: 0.3px;
    }

    .ant-form-item {
        margin-bottom: 22px;
    }

    .ant-form-item .ant-form-item-label > label {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }

    .ant-input {
        height: 38px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        padding-bottom: 6px;
        box-shadow: 0px 2px 4px #0000000a;
    }

    .ant-input:placeholder-shown {
        font-weight: 400;
    }

    .ant-input:focus,
    .ant-input-focused {
        box-shadow: none;
    }

    .ant-input-status-error:not(.ant-input-disabled):not(
            .ant-input-borderless
        ).ant-input:focus {
        box-shadow: none;
    }

    .ant-input-status-error:not(.ant-input-disabled):not(
            .ant-input-borderless
        ).ant-input {
        border-color: #ef3e32;
    }

    .ant-input-disabled,
    .ant-input[disabled] {
        color: #1f1f1f;
        background-color: #fff;
        border-color: #e5e7eb;
    }

    .form-radio-wrapper {
        .modal-subtitle {
            margin-bottom: 8px;
        }
    }

    .radio-description {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .ant-radio-wrapper .ant-radio {
        position: absolute;
        top: 4px;
    }

    .ant-radio-wrapper span.ant-radio + * {
        padding-inline-start: 28px;
        padding-inline-end: 8px;
    }

    .form-buttons-wrapper {
        height: 40px;
    }

    .ant-btn.ant-btn-lg {
        width: 80px;

        &:first-child {
            margin-right: 14px;
        }
    }
}

.invite-user-modal-dark,
.edit-user-modal-dark {
    .form-fields-wrapper {
        border-top: 1px solid #2a2d2f;
        border-bottom: 1px solid #2a2d2f;
    }

    .ant-radio-wrapper .ant-radio-inner::after {
        background-color: #1f1f1f;
    }
}

.users-status-label {
    width: fit-content;
    padding: 4px 8px;
    border-radius: 6px;

    &-active {
        background-color: #dff6e3;
        color: #20c752;

        &.dark-mode {
            background-color: $dark-success-bg-color;
        }
    }

    &-inactive {
        background-color: #ef3e321a;
        color: #ef3e32;

        &.dark-mode {
            background-color: #510c04;
        }
    }
}

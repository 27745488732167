.sider-logo {
    height: 78px;
    align-items: flex-start;
    padding-top: 15px;
}

.sider-collapse-btn {
    position: absolute;
    width: 20px;
    height: 20px;
    right: -10px;
    top: 16px;
    background: #ffffff;
    border: 1px solid #e5e7eb;
    border-radius: 50%;
}

.create-btn-dropdown-overlay {
    top: 130px !important;

    &::after {
        top: -9.5px;
        margin: 0px auto;
        transform: translateY(50%) rotate(45deg);
    }

    .ant-dropdown-menu-item {
        &:hover {
            svg path#stroke-star {
                fill: #327eef;
                stroke: none;
            }
        }
    }
}

.create-btn-dropdown-overlay-collapsed {
    left: 12px !important;

    &::after {
        margin: 0;
        margin-left: 18px;
    }
}

.sider-collapsed {
    .sider-logo {
        padding-top: 8px;
    }
}

.sider-dark {
    .sider-collapse-btn {
        background: #16161c;
        border: 1px solid #327eef;
        border-radius: 50%;
    }
}

@import '../../../../../../../resources/styles/variables.scss';

.competitors-actions-wrapper {
    width: 100%;
    display: flex;
    align-items: center;

    div.search-input-wrapper {
        width: 64%;

        .ant-form-item {
            width: 100%;
            margin-bottom: 0;
        }
    }
}

.competitors-actions-wrapper > div {
    margin-bottom: 24px;
}

.competitors-search-input {
    display: flex;

    .search-input {
        height: 38px;

        border: 1px solid $light-border-color;
        box-shadow: 0 2px 4px $light-box-shadow-color;
        border-radius: 6px;

        span.ant-input-clear-icon {
            height: 20px;
        }
    }

    .search-input.dark-mode {
        border-color: $dark-border-color;
    }

    .input-error,
    .input-error.dark-mode {
        border-color: #ef3e32;

        &:hover {
            border-color: #ef3e32;
        }
    }

    .scan-button,
    .ant-tooltip-disabled-compatible-wrapper {
        height: 38px;
        width: 95px;

        margin-left: 16px;
        margin-bottom: 0px;

        button {
            height: 100%;
        }
    }
}

@media (max-width: 1200px) and (min-width: 720px) {
    .competitors-actions-wrapper {
        div.search-input-wrapper {
            width: 50%;
        }
    }
}

.sort-wrapper {
    min-width: 216px;
    height: 38px;

    background: #ffffff;
    border: 1px solid #e2e4ec;

    box-shadow: 0px 2px 4px #0000000a;
    border-radius: 6px;
    padding: 0 12px;

    .sort-text {
        span {
            margin-left: 4px;
        }

        svg {
            margin: 3px 0 0 4px;
        }
    }
}

.sort-wrapper.dark-mode {
    background: $dark-input-bg-color;
    border-color: $dark-border-color;
}

.sort-dropdown-overlay {
    top: 52px !important;

    &::after {
        top: -10px;
        margin-left: calc(50% - 6px);
        transform: translateY(50%) rotate(45deg);
    }
}

.competitors-export-button {
    height: 38px;
    margin-bottom: 0;

    border: 1px solid $primary-color;
    border-radius: 6px;
    color: $primary-color;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 4px 11px;

    svg {
        margin-right: 4px;
    }
}

img.site-icon {
    margin-right: 4px;
}

a.site-link {
    font-size: 12px;
}

table tbody.ant-table-tbody tr.ant-table-row.highlighted-row {
    td {
        background: $light-success-bg-color;
    }

    &:hover td,
    &:focus td {
        background: $light-success-bg-color;
    }
}

table tbody.ant-table-tbody tr.ant-table-row.dark-highlighted-row {
    td {
        background: $dark-success-bg-color;
    }

    &:hover td,
    &:focus td {
        background: $dark-success-bg-color;
    }
}

.table-search-row {
    tr:first-child {
        background: $light-success-bg-color;
    }
}

.table-search-row.dark-mode {
    tr:first-child {
        background: $dark-success-bg-color;
    }
}

.competitors-table {
    height: 100%;
    
    .ant-table-header {
        //NOTE: Override inline antd style
        overflow: unset !important;
        margin-left: 0px;
    }
    
    .ant-table-thead {
        height: 36px;
    }

    .ant-spin-nested-loading,
    .ant-spin-container,
    .ant-table,
    .ant-table-container,
    .ant-table-body {
        height: 100%;
    }

    .ant-table-container {
        display: flex;
        flex-direction: column;
    }
    
    &.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container {
        border-inline-start: 0px;
    }

    .ant-table-body {
        max-height: 100% !important;
        border-inline-end: 1px solid $light-border-color;
    }

    &.ant-table-wrapper .ant-table-thead > tr > th {
        padding: 2px 18px;
    }

    &.ant-table-wrapper .ant-table-tbody > tr > td {
        padding-left: 18px;
    }

    .ant-table-cell-scrollbar {
        box-shadow: unset !important;
    }

    .competitor-list-actions::before {
        display: none;
    }
}

.competitors-table.dark-mode {
    .ant-table-body {
        border-inline-end: 1px solid $dark-border-color;
    }
}

.competitors-table-header-title {
    width: fit-content;

    svg {
        min-width: 16px;
        margin-left: 0;
    }

    svg > path {
        stroke: transparent;
    }

    &:hover {
        svg > path {
            stroke: $sort-arrows-light-icon-color;
        }
    }

    svg.active > path {
        stroke: $primary-color;
    }
}

.competitors-table-header-title.dark-mode {
    &:hover {
        svg > path {
            stroke: $sort-arrows-dark-icon-color;
        }
    }

    svg.active > path {
        stroke: $primary-color;
    }
}

.competitors-table-header-title.active {
    color: $primary-color;
}

.scan-url-loader-wrapper {
    margin: 0;
    padding: 0;
    height: 90%;
}

.competitor-name-wrapper {
    display: grid;
    place-items: stretch;

    .competitor-name-ellipsis {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &.missing-info span {
            color: $disabled-dotted-text-color;
        }
    }

    .competitor-link-ellipsis {
        color: $primary-color;
    }
}

button.list-more-button.btn-hover-light-primary {
    width: 20px;
    margin: auto;
    border: none;
    margin-bottom: 0px;
    margin-right: 10px;
    box-shadow: none;

    &:hover,
    &:focus {
        border: none;
        box-shadow: none;
    }

    &.dark-mode {
        border: none;
        box-shadow: none;

        &:hover,
        &:focus {
            border: none;
            box-shadow: none;
            background-color: $dark-primary-bg-color;
        }
    }
}

.content-actions-dropdown {
    li {
        &:hover {
            .competitor-actions-dropdown-close {
                path {
                    fill: $primary-color;
                    stroke-width: 0;
                }
            }
        }
    }
}

// Autofill background color and color text (light theme). Search autofill needs it
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    // !important should be for rewrite password element background
    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
    -webkit-text-fill-color: #1f1f1f;
    -webkit-background-clip: text;
}

.dark-input-autofill {
    // Autofill background color and color text  (dark theme)
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        // !important should be for rewrite password element background
        -webkit-box-shadow: 0 0 0 30px #242429 inset !important;
        -webkit-text-fill-color: #fff;
        -webkit-background-clip: text;
    }

    span.anticon.ant-input-password-icon {
        svg {
            fill: #9d9d9f;
        }
    }
}

.three-dots-dropdown-overlay.ant-dropdown {
    border-radius: 6px;
    
    .dropdown-with-extra-content-wrapper {
        padding: 8px 12px 12px 12px;
    }

    .ant-dropdown-arrow {
        overflow: visible;

        &::before {
            display: none;
        }
    }

    .ant-dropdown-arrow::after {
        box-shadow: none;
        z-index: 0;
        background: $dark-typography-primary-color;
        border-right: 1px solid $light-border-color;
        border-bottom: 1px solid $light-border-color;
    }

    .ant-switch.ant-switch-small {
        width: 28px;
        min-width: 28px;
        margin-right: 8px;
    }

    .ant-dropdown-menu {
        outline: none;
        box-shadow: 0px 4px 12px #0000001f;
        border: 1px solid #e5e7eb;

        .ant-dropdown-menu-item:hover {
            background-color: transparent;
            cursor: auto;
        }
    }

    h5.ant-typography {
        margin: auto;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $light-typography-secondary-color;
    }

    h5.reset-to-default:hover {
        color: $primary-color;
    }

    .reset-to-default-container {
        border-radius: 6px;
        
        &:hover {
            svg path {
                stroke: $primary-color;
            }

            .reset-to-default {
                color: $primary-color;
            }

            background: $color-primary-bg-light;
        }
    }

    .ant-typography .ant-typography-copy {
        color: $primary-color;
        height: 16px;
        margin-left: 8px;
    }

    .ant-typography .ant-typography-copy-success {
        height: 22px;
        color: $primary-color;
    }
}

.three-dots-dropdown-overlay.dark-mode.ant-dropdown {
    .ant-dropdown-menu {
        border: 1px solid $dark-border-color;
    }

    .ant-dropdown-arrow::after {
        background: $dark-layout-bg-color;
        border-right: 1px solid $dark-border-color;
        border-bottom: 1px solid $dark-border-color;
    }

    h5.ant-typography {
        color: $dark-typography-secondary-color;
    }

    h5.reset-to-default:hover {
        color: $primary-color;
    }

    .reset-to-default-container:hover {
        background: $color-dark-theme-bg-hover-dark;
    }
}

.competitors-3-dots {
    cursor: pointer;

    > div {
        padding: 4px 12px;
    }
    
    .d-flex:first-of-type {
        margin-bottom: 4px;
    }

    .reset-to-default-icon {
        margin: 0 10px 0px 3px;
        display: flex;
    }

    h5.ant-typography.reset-to-default {
        height: 24px;
        line-height: 1;
    }
}
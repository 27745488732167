@import '../../../resources/styles/variables.scss';

.ant-layout .ant-layout-sider {
    position: absolute;
    height: auto;
}

.sider {
    width: 200px;
    flex: 0 0 200px !important;
    border-right: 1px solid #e5e7eb;

    .ml-8px {
        margin-left: 8px;
    }

    .ant-btn.ant-btn-lg {
        font-size: 14px;
        height: 38px;
        min-height: 38px;
        max-height: 38px;
        padding: 0;
        border-radius: 6px;
    }

    .ant-btn-primary {
        box-shadow: none;
    }

    .ant-typography {
        word-break: keep-all;
    }
}

.sider-collapsed {
    width: 70px !important;
    flex: 0 0 70px !important;
    max-width: 70px !important;
    min-width: 70px !important;
}

.sider-dark {
    border-right: 1px solid #2a2d2f;
}

.dropdown-menu {
    position: fixed;

    .ant-dropdown-menu {
        border: 1px solid #e5e7eb;
        box-shadow: none;
        padding: 6px;

        .ant-dropdown-menu-item-selected:hover {
            background-color: #f3f7fd;
        }
    }

    .ant-dropdown-menu .ant-dropdown-menu-item {
        margin-bottom: 8px;
        height: 30px;
        border-radius: 6px;
        padding: 4px 4px 4px 8px;

        svg {
            margin-right: 8px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &:not(.ant-dropdown-menu-item-disabled):hover {
            svg:not(#affiliate-icon, #fast-start-guide-icon) path {
                stroke: #327eef;
            }

            span {
                color: #327eef;
            }
        }

        &-disabled {
            svg path {
                stroke: #c3cfe4;
            }
        }
    }
}

.dropdown-overlay {
    box-shadow: 0px 4px 25px #3333330d;

    &::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        inset-inline: 0;
        border-radius: 0 0 2px 0;
        box-shadow: none;
        z-index: 0;
        background: white;
        border-left: 1px solid #e5e7eb;
        border-top: 1px solid #e5e7eb;
    }
}

.dropdown-overlay-dark {
    box-shadow: 0px 4px 25px #3333330d;

    .ant-dropdown-menu {
        border: 1px solid #2a2d2f;

        .ant-dropdown-menu-item-selected:hover {
            background-color: $dark-primary-bg-color;
        }

        .ant-dropdown-menu-item-disabled svg path {
            stroke: #5c5c5c;
        }
    }

    &::after {
        background: #0b0a0f;
        border-left: 1px solid #2a2d2f;
        border-top: 1px solid #2a2d2f;
    }
}

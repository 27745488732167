@import '../../../../resources/styles/variables.scss';

.disabled-content-optimizer-item {
    border: 1px solid $error-color;

    .list-link-wrapper {
        pointer-events: none;

        a,
        a:hover {
            color: #728192;
            cursor: default;
        }

        a:hover {
            background-color: transparent;
        }
    }

    .disabled-optimizer-item-menu {
        pointer-events: none;
    }

    .content-optimizer-list-view-disabled-message {
        width: 700px !important;
        white-space: normal !important;
    }
}

.ai-response-confirm-modal {
    .ant-modal {
        max-width: 360px;

        .ant-modal-content {
            padding: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .ai-response-title {
        width: 260px;
        text-align: center;
        margin-bottom: 24px;
        display: flex;

        svg {
            min-width: 30px;
            height: 30px;
            margin-top: -4px;
        }
    }

    .actions-wrapper {
        width: 270px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .ant-tooltip-disabled-compatible-wrapper {
            width: 100%;
        }

        .ant-btn:last-child {
            margin-bottom: 0;
        }
    }
}
.settings-content-wrapper {
    .optimizer-settings-wrapper {
        .ant-form-item {
            width: 100%;
        }

        .ant-checkbox-group {
            width: 100%;

            h5.ant-typography {
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                margin-bottom: 4px;
            }

            .ant-typography {
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
            }

            .ant-checkbox + span {
                padding-inline-start: 12px;
                padding-inline-end: 0px;

                .ant-checkbox-inner {
                    width: 18px;
                    height: 18px;
                }
            }
        }

        .ant-checkbox {
            top: 4px;
        }

        .ant-col-8 {
            max-width: calc(33.33333333333333% - 16px);
        }

        .ant-card {
            box-shadow: 0px 2px 4px #0000000a;

            .ant-card-body {
                padding: 12px;
            }
        }

        .ant-card-bordered {
            height: 100%;

            &:hover {
                border-color: #327eef;
            }
        }

        .optimizer-brief-setting-wrapper {
            margin-top: 24px;
        }

        .row-with-checkbox {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

        .optimizer-settings-select-wrapper {
            margin-top: 24px;

            .ant-col-8 {
                max-width: 100%;
            }

            .ant-form-item-label > label {
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
            }

            .ant-select-selector {
                height: 38px;
                box-shadow: 0px 2px 4px #0000000a;
            }

            .ant-select-single .ant-select-selector {
                color: #1f1f1f;
                transition: all 0s;

                .ant-select-selection-item {
                    transition: all 0s;
                }
            }

            .ant-select .ant-select-selection-item {
                font-weight: 500;
            }

            .ant-select-single .ant-select-selector .ant-select-selection-item {
                display: flex;
                align-items: center;
            }

            .ant-typography {
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                margin-top: 8px;
            }
        }
    }

    .monitor-settings-wrapper {
        .form-fields-wrapper {
            .ant-input-affix-wrapper {
                padding: 0;
                overflow: hidden;
                box-shadow: 0px 2px 4px #0000000a;
            }

            .ant-input-affix-wrapper .ant-input-suffix {
                display: none;
            }

            .ant-input-affix-wrapper-disabled .ant-input[disabled] {
                padding: 0 12px;
            }
        }

        .ant-input {
            height: 38px;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            display: flex;
            align-items: center;
            padding-bottom: 6px;
            box-shadow: 0px 2px 4px #0000000a;
        }

        .ant-input:placeholder-shown {
            font-weight: 400;
        }

        .ant-input:focus,
        .ant-input-focused {
            box-shadow: none;
        }

        .ant-input-status-error:not(.ant-input-disabled):not(
                .ant-input-borderless
            ).ant-input:focus {
            box-shadow: none;
        }

        .ant-input-disabled,
        .ant-input[disabled] {
            color: #1f1f1f;
            background-color: #fff;
            border-color: #e5e7eb;

            &:hover {
                border-color: #327eef;
            }
        }

        span.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled.ant-input-password {
            &:hover {
                border-color: #327eef;
            }
        }

        a.ant-typography {
            display: block;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            margin-top: 28px;
        }
    }

    .advanced-settings-wrapper {
        .advanced-settings-table {
            margin-top: 25px;

            th {
                height: 38px;
            }
        }

        article {
            font-size: 14px;
            line-height: 22px;
        }

        .ant-segmented.ant-segmented-block {
            margin: 16px 12px 20px 0;
            height: 40px;
        }

        .settings-list-wrapper {
            list-style-type: none;

            li {
                margin-bottom: 12px;
            }

            .ant-badge.ant-badge-status .ant-badge-status-dot {
                width: 4px;
                height: 4px;
            }

            .ant-badge-status-text {
                color: #1f1f1f;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
            }
        }

        .domain-formatting-title {
            margin-top: 20px;
        }

        .advanced-settings-empty-area-wrapper {
            .ant-card {
                margin-top: 25px;
                background: #f6f8fa;
                border-radius: 16px;

                .ant-card-body {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 50px;
                }

                .area-content-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    h4.ant-typography {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 28px;
                        margin: 20px 0 12px;
                        text-transform: capitalize;
                    }

                    article {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 22px;
                    }
                }
            }
        }
    }
}

.container-dark {
    .settings-content-wrapper {
        .optimizer-settings-wrapper {
            .ant-select-single .ant-select-selector {
                color: #fff;
            }
        }

        .ant-checkbox .ant-checkbox-inner {
            border: 1px solid #2a2d2f;
        }
        .ant-checkbox-wrapper:hover {
            .ant-checkbox .ant-checkbox-inner {
                border: 1px solid #327eef;
            }
        }
    }

    .monitor-settings-wrapper {
        .ant-input-disabled,
        .ant-input[disabled] {
            color: #fff;
            background-color: #ffffff0f;
            border-color: #2a2d2f;

            &:hover {
                border-color: #327eef;
            }
        }

        span.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled.ant-input-password {
            &:hover {
                border-color: #327eef;
            }
        }
    }

    .advanced-settings-wrapper {
        .ant-segmented.ant-segmented-block {
            background-color: #0b0a0f;
        }

        .settings-list-wrapper {
            .ant-badge-status-text {
                color: #fff;
            }
        }

        .advanced-settings-empty-area-wrapper {
            .ant-card {
                background: #ffffff1a;
            }
        }
    }
}

.advanced-settings-modal-wrapper {
    .ant-modal-title {
        text-transform: capitalize;
    }

    .form-fields-wrapper {
        .ant-form-item-label > label {
            font-size: 13px;
            color: #1f1f1f;
        }

        .ant-form-item-control-input-content {
            .ant-input {
                height: 38px;
                box-shadow: 0px 2px 4px #0000000a;
            }
        }
    }

    .form-buttons-wrapper {
        .ant-btn.ant-btn-lg {
            min-width: 112px;
            width: fit-content;
        }
    }
}

.advanced-settings-modal-wrapper-dark {
    .form-fields-wrapper {
        .ant-form-item .ant-form-item-label > label {
            color: #fff;
        }
    }
}

@media screen and (max-width: 1024px) {
    .settings-content-wrapper {
        .ant-checkbox-group {
            flex-wrap: wrap;
            margin-left: -8px;

            .ant-col-8 {
                margin-bottom: 16px;
                padding: 0 8px;
            }
        }

        .optimizer-settings-wrapper .optimizer-settings-select-wrapper {
            margin-top: 8px;
        }
    }
}

@import '../../../../../../../../resources/styles/variables.scss';

.optimizer-right-block-wrapper {
    .optimizer-keywords-panel-wrapper {
        overflow-y: overlay;
        position: absolute;
        top: 0;
        bottom: 23px;
        width: 100%;

        .ant-spin-nested-loading {
            height: 100%;
        }
    }

    .keywords-top-section-wrapper {
        padding: 16px;
        padding-top: 0;
        flex-wrap: wrap;

        .optimizer-keywords-sort-wrapper {
            min-width: 100px;
            width: 156px;
            height: 32px;
            justify-content: space-between;
            padding-right: 8px;
            margin-top: 16px;

            .ant-typography {
                font-size: 13px;
                line-height: 18px;
                white-space: nowrap;

                svg {
                    margin-top: 0;
                }
            }

            &:hover {
                cursor: pointer;
                border-color: #327eef;
            }
        }

        .optimizer-keywords-icon-btn,
        .keyword-delete-button {
            height: 32px;
            width: 32px;
            padding: 0 8px;
            margin-left: 6px;
            margin-top: 16px;

        }

        .optimizer-keywords-icon-btn {
            &-active {
                color: $primary-color;
                background-color: $light-primary-bg-color;
                border-color: $primary-color;

                &.dark-mode {
                    background-color: $dark-primary-bg-color;
                }
            }
        }

        .keyword-delete-button {
            margin-bottom: 0;
            border-color: transparent;
            background-color: $light-primary-bg-color;

            &:hover {
                border-color: $primary-color;
            }

            &.dark-mode {
                background-color: $dark-primary-bg-color;
            }
        }
    }

    .optimizer-keywords-list-wrapper {
        .ant-list-items .ant-list-item {
            margin: 0 0 2px;

            &:last-child {
                margin-bottom: 0;
            }

            .optimizer-keywords-list-item-wrapper {
                padding: 8px 16px;

                .ant-checkbox {
                    margin-right: 16px;
                }

                h4.ant-typography {
                    font-size: 14px;
                    line-height: 18px;
                    margin-right: 8px;
                }

                .ant-typography {
                    font-size: 12px;
                    line-height: 16px;
                }

                .ant-progress-line {
                    margin-bottom: 0;
                }

                .ant-progress .ant-progress-inner {
                    width: 80px;
                    height: 4px;
                    background-color: #bbc5d2;

                    .ant-progress-bg {
                        height: 4px !important;
                    }
                }

                .ant-progress.ant-progress-status-success .ant-progress-bg {
                    background-color: #327eef;
                }

                .ant-progress .ant-progress-text {
                    display: none;
                }

                .uses-wrapper {
                    display: flex;
                    flex-wrap: wrap;

                    .ant-typography {
                        margin-bottom: 6px;
                        margin-right: 4px;
                    }
                }

                .heading-presence-wrapper {
                    width: 10px;

                    .ant-typography {
                        font-size: 14px;
                    }
                }

                .ant-tag {
                    font-size: 10px;
                    line-height: 14px;
                    height: 16px;
                    transition: all 0s;
                }

                .ant-tag-processing {
                    color: #327eef;
                    background: #f3f7fd;
                    border-color: #327eef;
                }
            }

            &.active {
                background: #dff6e3;

                h4.ant-typography {
                    color: $dark-success-bg-color;
                }

                .uses-wrapper {
                    .ant-typography {
                        color: $dark-success-bg-color;
                    }
                }
            }
        }
    }

    .optimizer-keywords-complete-progress-wrapper {
        position: absolute;
        width: 100%;
        bottom: 0;
        background-color: #fff;

        .optimizer-keywords-complete-progress-bar.ant-progress {
            margin: 0;
            margin-inline-end: 0px;

            .ant-progress-inner {
                height: 20px;
                border-radius: 0;
                background-color: #dfe3ec;

                .ant-progress-bg {
                    min-height: 20px;
                    min-width: 125px;

                    border-radius: 0 10px 10px 0;
                }
            }
        }

        &.complete {
            .optimizer-keywords-complete-progress-bar.ant-progress
                .ant-progress-inner
                .ant-progress-bg {
                border-radius: 0;
                background-color: #327eef;
            }
        }

        .optimizer-keywords-complete-progress-text {
            margin-left: 16px;
            position: absolute;
            bottom: 2px;
            transition: right 0.3s ease-in-out, left 0.3s ease-in-out;
            overflow: hidden;

            .ant-typography {
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: #fff;
            }
        }
    }
}

.container-dark {
    .optimizer-right-block-wrapper {
        .optimizer-keywords-list-wrapper {
            .ant-list-items .ant-list-item {
                .optimizer-keywords-list-item-wrapper {
                    .ant-progress .ant-progress-inner {
                        background-color: #ffffff1a;
                    }

                    .ant-progress.ant-progress-status-success .ant-progress-bg {
                        background-color: #327eef;
                    }

                    .ant-tag-processing {
                        background: #192131;
                    }
                }

                &.active {
                    background: $dark-success-bg-color;

                    h4.ant-typography {
                        color: #fff;
                    }

                    .uses-wrapper {
                        .ant-typography {
                            color: #fff;
                        }
                    }
                }
            }
        }

        .optimizer-keywords-complete-progress-wrapper {
            background-color: #16161c;

            .optimizer-keywords-complete-progress-bar.ant-progress {
                .ant-progress-inner {
                    background-color: #1e1e1e;

                    .ant-progress-bg {
                        border-radius: 0 10px 10px 0;
                    }
                }
            }

            &.complete {
                .optimizer-keywords-complete-progress-bar.ant-progress
                    .ant-progress-inner
                    .ant-progress-bg {
                    border-radius: 0;
                    background-color: #327eef;
                }
            }
        }
    }
}

.add-terms-modal {
    .ant-modal {
        min-width: 420px;
    }

    h5.ant-typography {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 16px;
    }

    .ant-form-item {
        margin-bottom: 20px;
    }

    .ant-btn.ant-btn-lg {
        min-width: 100px;
    }

    .suggestions-wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        list-style-type: none;
        max-height: 275px;

        h5.ant-typography {
            font-weight: 400;
            margin-bottom: 6px;
        }
    }

    .form-fields-wrapper {
        padding: 18px 0 12px;
        overflow-y: scroll;
        max-height: 440px;

        &.scroll-animated {
            --scroll-padding: 20px;
        }
    }
}

.modal-wrapper-dark {
    .area-multiple-wrapper {
        border: 1px solid #2a2d2f;
        box-shadow: 0px 2px 4px #0000000a;

        .ant-input-status-error:not(.ant-input-disabled):not(
                .ant-input-borderless
            ).ant-input {
            border-color: transparent;
        }

        .area-multiple-list-number-wrapper {
            background: #ffffff1a;
            border-right: 1px solid #2a2d2f;
            color: #fff;
            text-align: center;
        }
    }

    .input-error {
        border-color: #ef3e32;

        &:hover {
            border-color: #ef3e32;
        }
    }
}

.optimizer-keywords-tooltip-overlay.ant-tooltip {
    .ant-tooltip-inner {
        padding: 6px 8px;
        color: $light-typography-primary-color;
        font-size: 14px;
        font-weight: 500;
    }

    &.tooltip-overlay-dark {
        .ant-tooltip-inner {
            color: $dark-typography-primary-color;
        }
    }
}

.keyword-info-dropdown-overlay {
    min-width: 265px !important;
    box-shadow: none;

    .ant-dropdown-arrow {
        right: -32px;
        top: calc(50% - 16px);
        transform: rotate(90deg);
    }

    .keyword-info-wrapper {
        min-width: 265px;
        padding: 20px 16px 20px 24px;

        article.ant-typography {
            font-size: 14px;
        }

        .text-accent {
            color: #1f1f1f;
            font-size: 16px;
            font-weight: 600;
        }

        .text-small {
            font-size: 12px;
        }

        a.ant-typography {
            margin-top: 4px;
            margin-bottom: -4px;
        }

        a.ant-typography.block-link {
            margin-right: 8px;
        }

        .ant-tag {
            line-height: 16px;
            height: 18px;
            transition: all 0s;
            margin-bottom: 8px;
        }

        .ant-tag-processing {
            color: #327eef;
            background: #f3f7fd;
            border-color: #327eef;
        }
    }
}

.keyword-info-dropdown-overlay.dark-mode {
    .text-accent {
        color: #fff;
    }

    .keyword-info-wrapper {
        .ant-tag-processing {
            background: #192131;
        }
    }
}

.keyword-delete-popconfirm.delete-popconfirm, .block-popconfirm {
    width: 225px;
    z-index: 1051;
    padding-bottom: 6px;

    .ant-popconfirm-message {
        margin-bottom: 12px;
    }
}

.keywords-reset-popconfirm.delete-popconfirm {
    width: 212px;
    z-index: 1051;
}

.block-popconfirm .ant-popconfirm-buttons {
    text-align: end;
    display: flex;
    justify-content: flex-end;

    .ant-btn.ant-btn-sm {
        width: fit-content;
        margin-bottom: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
    }

    .ant-btn-default {
        background-color: transparent;
        border-color: $primary-color;
        color: $primary-color;

        &:hover {
            background-color: $light-primary-bg-color;
        }
    }
}

@media screen and (max-width: 1200px) {
    .optimizer-right-block-wrapper
        .optimizer-keywords-list-wrapper
        .ant-list-items
        .ant-list-item
        .optimizer-keywords-list-item-wrapper
        .ant-progress
        .ant-progress-inner {
        width: 60px;
    }
}

.keyword-examples-button {
    a {
        margin-left: -8px;
    }
}

button.add-keyword-button.ant-btn.ant-btn-lg {
    width: 120px;
}

@import '../../../../resources/styles/variables.scss';

.quotas-container {
    padding: 24px 24px 0;

    h5.ant-typography {
        margin-top: 12px;
        margin-left: 10px;

        &:first-child {
            margin-top: 0;
        }
    }

    .users-table-header-title article {
        white-space: nowrap;
    }

    .ant-table-wrapper tr:not(.ant-table-expanded-row) {
        & th:last-child {
            text-align: end;
            padding-right: 24px;
        }
    }

    .actions-cell {
        display: flex;
        justify-content: flex-end;
    }

    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table {
        thead > tr > .ant-table-cell-fix-right-first::after {
            border-inline-end: none;
        }

        tbody > tr {
            .ant-table-cell-fix-right-first::after {
                border-inline-end: none;
            }

            td > .ant-table-expanded-row-fixed::after {
                display: none;
            }
        }
    }

    .ant-table-wrapper {
        .ant-table-thead > tr > th {
            padding: 9px 16px;
        }

        .ant-table-tbody > tr > td {
            padding: 16px 16px;

            .ant-table-wrapper:only-child,
            .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child {
                .ant-table {
                    margin-block: -0;
                    margin-inline: 0;
                    border-radius: 6px;
                    border-bottom: 1px solid $light-border-color;
                }
            }
        }
    }

    .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
        border-left: 1px solid $light-border-color;
    }

    .ant-table-expanded-row.ant-table-expanded-row-level-1 > .ant-table-cell {
        padding: 12px;
    }

    .ant-table-wrapper .ant-table-tbody > tr > td {
        .ant-table-wrapper:only-child .ant-table .ant-table-tbody > tr:last-child > td {
            &:first-child {
                border-bottom-left-radius: 6px;
            }

            &:last-child {
                border-bottom-right-radius: 6px;
            }
        }
    }

    .ant-table-row-level-0:hover {
        tr:last-child > td:first-child {
            border-inline-start: 1px solid $light-border-color;
            border-bottom-left-radius: 6px;
        }
    }

    .ant-table-wrapper .expanded-row {
        background-color: $light-table-expanded-row-bg-color;

        .ant-table-cell-fix {
            &-left, &-right {
                background-color: $light-table-expanded-row-bg-color;
            }
        }
    }

    .ant-table-wrapper .ant-table-tbody {
        tr.ant-table-row.expanded-row:hover > td, tr.expanded-row > td.ant-table-cell-row-hover {
            background: $light-table-expanded-row-bg-color;
        }
    }

    .ant-table-expanded-row {
        .ant-table-wrapper .ant-table.ant-table-bordered {
            .ant-table-container > .ant-table-content > table {
                background: $light-layout-bg-color;

                & > tbody > tr > td {
                    background-color: $light-section-bg-color;
                    display: table-cell;
                }
            }
        }
    }

    .users-table-header-title {
        width: fit-content;

        .ant-typography {
            font-size: 10px;
        }

        .active {
            color: $primary-color;
        }

        svg {
            margin-left: 4px;
        }

        svg > path {
            stroke: transparent;
        }

        &:hover {
            svg > path {
                stroke: $sort-arrows-light-icon-color;
            }
        }

        svg.active > path {
            stroke: $primary-color;
        }
    }

    .expand-icon {
        width: 22px;
        height: 22px;
        border-radius: 4px;

        &:hover {
            background: $light-primary-bg-color;

            svg path {
                stroke: $primary-color;
            }
        }
    }

    .admin-quota-credits-item {
        display: flex;
        align-items: center;

        .admin-quota-credits-text {
            color: $light-typography-primary-color;
            margin-right: 4px;
        }

        .add-credits-icon-container {
            display: flex;
            justify-content: center;
        }
    }

    .actions-wrapper .link-wrapper {
        &:last-child {
            width: 50px;
            margin-left: 12px;
        }

        &:first-child {
            margin-left: 0;
        }
    }
}

.quotas-container.container-dark {
    .ant-table-wrapper {
        .ant-table-tbody > tr > td {
            .ant-table-wrapper:only-child,
            .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child {
                .ant-table {
                    border-bottom: 1px solid $dark-border-color;
                }
            }
        }
    }

    .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
        border-left: 1px solid $dark-border-color;
    }

    .ant-table-row-level-0:hover {
        tr:last-child > td:first-child {
            border-inline-start: 1px solid $dark-section-bg-color;
        }
    }

    .ant-table-wrapper .expanded-row {
        background-color: $dark-table-expanded-row-bg-color;

        .ant-table-cell-fix {
            &-left, &-right {
                background-color: $dark-table-expanded-row-bg-color;
            }
        }
    }

    .ant-table-wrapper .ant-table-tbody {
        tr.ant-table-row.expanded-row:hover > td, tr.expanded-row > td.ant-table-cell-row-hover {
            background: $dark-table-expanded-row-bg-color;
        }
    }

    .ant-table-expanded-row {
        .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container {
            .ant-table-content > table {
                border-top-color: $dark-section-bg-color;
                background: $dark-table-expanded-row-primary-bg-color;

                & > thead > tr > th:first-child {
                    border-inline-start: 1px solid $dark-section-bg-color;
                }

                & > tbody > tr > td {
                    background-color: $dark-section-bg-color;
                }

                & > tbody > tr > td:first-child {
                    border-inline-start: 1px solid $dark-section-bg-color;
                }

                & > thead > tr:first-child > *:last-child, & > tbody > tr > td:last-child {
                    border-inline-end: 1px solid $dark-section-bg-color;
                }
            }
        }

        .ant-table-wrapper .ant-table.ant-table-bordered .ant-table-tbody > tr:last-child > td {
            border-bottom: 1px solid $dark-section-bg-color;
        }
    }

    .admin-quota-credits-text {
        color: $dark-typography-primary-color;
    }
}

@media screen and (max-width: 1200px) {
    .quotas-container .actions-cell {
        padding-right: 8px;
    }
}

.edit-quota-modal {
    .ant-modal {
        width: 600px !important;
        top: calc(50% - 295px);
        padding-bottom: 0;

        .ant-modal-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
        }

        .ant-modal-content {
            padding: 18px 24px 24px;
        }
    }

    .form-fields-wrapper {
        border-top: 1px solid $light-border-color;
        border-bottom: 1px solid $light-border-color;
        margin: 16px 0 24px;
        padding: 20px 0;
    }

    .modal-subtitle {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 16px;
        letter-spacing: 0.3px;
    }

    .ant-form-item {
        margin-bottom: 22px;

        .ant-form-item-label > label {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
        }
    }

    .ant-input, .ant-input-number {
        height: 38px;
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 2px 4px $light-box-shadow-color;

        &:placeholder-shown {
            font-weight: 400;
        }

        &:focus, &-focused {
            box-shadow: none;
        }
    }

    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    .ant-input-number-status-error:not(.ant-input-number-disabled):not(
        .ant-input-number-borderless).ant-input-number {
        border-color: $error-color;

        &:focus {
            box-shadow: none;
        }
    }

    .ant-input-disabled, .ant-input[disabled],
    .ant-input-number-disabled, .ant-input-number[disabled] {
        color: $light-typography-primary-color;
        background-color: $light-input-bg-color;
        border-color: $light-border-color;
    }

    .ant-input-number-input-wrap {
        flex-grow: 0.9;
    }

    .error-message-text-wrapper {
        .error-message-text {
            max-height: 42px;
            overflow-y: scroll;
        }
    }

    .form-buttons-wrapper {
        height: 40px;
    }

    .ant-btn.ant-btn-lg {
        width: 80px;

        &:first-child {
            margin-right: 14px;
        }
    }
}

.edit-quota-modal-dark {
    .form-fields-wrapper {
        border-top: 1px solid $dark-border-color;
        border-bottom: 1px solid $dark-border-color;
    }
}

@import '../../../resources/styles/variables.scss';

@keyframes scroll-padding {
    from, to { padding-right: 20px; }
}

.checked-items-actions-panel {
    .ant-btn-primary.ant-btn-background-ghost {
        margin-bottom: 0;
        box-shadow: 0px 2px 4px #0000000a;
        border-radius: 6px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        padding-left: 12px;
        padding-right: 12px;
        margin-left: 8px;
    }

    .manage-project-btn.ant-btn-primary.ant-btn-background-ghost {
        box-shadow: none;
        border: 1px solid transparent;
        background: #327eef21;
        margin-left: 0;

        &:hover {
            border: 1px solid #327eef;
        }
    }

    .selected-link {
        white-space: nowrap;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin: 0 18px;
        pointer-events: none;
    }
}

.manage-project-modal {
    .ant-modal {
        max-width: 450px;
    }

    .form-fields-wrapper {
        margin: 16px 0 24px;
        padding: 18px 0 0 0;
        overflow-y: auto;
        max-height: 440px;
    }

    .modal-subtitle {
        margin-bottom: 5px;
        font-size: 12px;
        line-height: 22px;
    }

    .ant-form-item .ant-form-item-label > label {
        height: 18px;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: $light-typography-primary-color;
    }

    .ant-form-item .ant-form-item-control-input {
        min-height: 24px;
    }

    .ant-checkbox-group {
        flex-direction: column;
    }

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-inline-start: 0;
    }

    .ant-checkbox + span {
        padding-inline-start: 0;
        padding-inline-end: 0;
    }

    .ant-checkbox {
        margin-right: 12px;

        .ant-checkbox-inner {
            width: 18px;
            height: 18px;

            &::after {
                width: 5.714286px;
                height: 9.142857px;
                inset-inline-start: 27%;
                top: 8px;
            }
        }
    }

    .ant-checkbox-wrapper {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .input-wrapper {
        position: relative;
        padding-bottom: 3px;

        .manage-project-modal-link {
            display: flex;
            align-items: center;
            padding: 4px 0 4px;

            &.hidden-link {
                opacity: 0;
            }

            svg {
                margin-right: 6px;
            }
        }

        .ant-form-item {
            margin-bottom: 0;
        }
    }

    .ant-input:focus,
    .ant-input-focused {
        box-shadow: none;
    }

    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
        border-color: $error-color;

        &:focus {
            box-shadow: none;
        }
    }

    .no-project-message {
        height: 20px;
    }

    .available-projects-wrapper {
        .ant-checkbox .ant-checkbox-inner::after {
            width: 1.714286px;
            height: 9.142857px;
            inset-inline-start: 20%;
            top: 5px;
            rotate: 45deg;
        }

        input[type='checkbox' i]:focus-visible {
            outline-offset: 0px;
            outline: none;
        }

        .ant-form input[type='checkbox']:focus {
            outline: 0;
            box-shadow: none;
        }

        .ant-checkbox .ant-checkbox-input:focus-visible + .ant-checkbox-inner {
            outline: none;
            outline-offset: 0px;
            transition: all 0s;
        }
    }

    .edit-delete-project-buttons {
        input {
            padding: 0px 5px;
        }
    }
}

.modal-wrapper-dark {
    .ant-form-item .ant-form-item-label > label {
        color: $dark-typography-primary-color;
    }
}

@import '../../../../resources/styles/variables.scss';

.usage-page-content {
    overflow-y: auto;
}

.usage-content-wrapper {
    padding: 24px;
    top: 0;
    bottom: 0;
    width: 100%;

    div.ant-row {
        .chart-wrapper {
            width: 100%;
            padding: 20px 24px;

            border: 1px solid $light-border-color;
            box-shadow: 0 2px 4px $light-box-shadow-color;
            border-radius: 6px;

            margin-bottom: 32px;

            .chart-label {
                margin-bottom: 24px;

                article.second-label {
                    font-size: 14px;
                    color: $light-typography-secondary-color;
                }
            }

            .x-axis-label {
                display: flex;
                justify-content: space-between;

                article {
                    font-size: 12px;
                    color: $light-typography-primary-color;
                }
            }

            .recharts-wrapper {
                margin-left: -26px;
            }
        }

        .chart-wrapper.dark-mode {
            border-color: $dark-section-bg-color;

            article.second-label {
                color: $dark-typography-secondary-color;
            }

            .x-axis-label {
                article {
                    color: $dark-typography-primary-color;
                }
            }
        }
    }

    .organization-stats-label {
        margin-bottom: 16px;
    }

    article.main-label {
        font-weight: 600;
        font-size: 18px;
        color: $light-typography-primary-color;
    }

    article.main-label.dark-mode {
        color: $dark-typography-primary-color;
    }

    .stats-table-header-title {
        width: fit-content;

        .ant-typography {
            font-size: 10px;
        }

        .active {
            color: $primary-color;
        }

        svg {
            margin-left: 4px;

            path {
                stroke: transparent;
            }

            &.active > path {
                stroke: $primary-color;
            }
        }

        &:hover {
            svg > path {
                stroke: $sort-arrows-light-icon-color;
            }
        }

        article {
            white-space: nowrap;
        }
    }
}

.ranks-container {
    height: fit-content;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    overflow-y: scroll;
    overflow: overlay;
    overflow-x: hidden;
    border-top-right-radius: 6px;

    span.ant-collapse-header-text {
        width: 94%;
    }
    .ant-collapse-item {
        border: none;
    }

    .ranks-info-wrapper {
        margin: 10px 20px;
    }
}

.ranks-container.dark-mode {
    .ant-card-head {
        background: #ffffff1a;
    }
}

.competitors-tab {
    padding: 24px;
    height: calc(100% - 100px);

    &:not(:has(.competitors-actions-wrapper)) {
        .competitors-table-wrapper {
            height: 100%;
        }

        .competitors-table {
            height: 100%;
            
            .ant-table-container {
                height: 100%;
            }
            
            .ant-table-body {
                flex: 1;
                height: auto;
            }
        }
    }
}

.competitors-loader-wrapper {
    height: 10px;

    div.rankability-loader {
        position: relative;
        top: -31px;
    }
}

@media screen and (max-width: 1200px) {
    .ant-table-wrapper.competitors-table .ant-table-thead > tr > th {
        padding: 2px 12px;
    }

    .ant-table-wrapper.competitors-table .ant-table-tbody > tr > td {
        padding: 9px 12px;
    }

    .ant-table-wrapper.competitors-table .ant-table-tbody > tr > td:last-of-type {
        padding-right: 12px;
        border-inline-end: none !important;
    }
}

@media screen and (min-width: 1201px) {
    .ant-table-wrapper.competitors-table .ant-table-tbody > tr > td:last-of-type {
        padding-right: 15px;
        padding-left: 15px;
        border-inline-end: none !important;
    }
}

div.competitors-table-wrapper {
    height: calc(100% - 40px);
    padding-right: 24px;
    margin-right: -24px;

    .list-more-button {
        display: inline-flex;
        align-items: center;
        padding: 0 5px;
        margin-right: 5px;
    }
    
    .toggle-container {
        max-width: 28px;
        display: inline-block;

        .ant-switch.ant-switch-loading * {
            cursor: default;
        }
    }
}

.tooltip-overlay.ant-tooltip.three-dots {
    max-width: 200px;
}

.invoice-wrapper {
    transition: width 0.3s ease-in-out;
    padding: 40px;

    h2.ant-typography {
        margin-bottom: 32px;
        font-size: 24px;
        font-weight: 600;
        line-height: 34px;
    }

    .invoice-card-wrapper {
        width: 460px;
        min-height: 450px;

        .spin-skeleton {
            min-height: 400px;
        }

        .invoice-top-section {
            padding: 32px 24px 20px;
            border-bottom: 1px solid #e5e7eb;

            svg {
                margin-bottom: 16px;
            }

            .ant-typography {
                font-family: 'Inter', sans-serif;
            }

            h5.ant-typography {
                font-weight: 700;
                font-size: 34px;
                margin: 12px 0 18px;
                line-height: 22px;
            }

            a.ant-typography u {
                font-family: 'Inter', sans-serif;
            }
        }

        .invoice-bottom-section {
            padding: 20px 0 24px;

            .invoice-info-wrapper {
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }

                .ant-typography {
                    line-height: 22px;
                    margin: 0;
                }

                h5.ant-typography {
                    font-weight: 500;
                    font-size: 14px;
                }
            }
        }

        .invoice-buttons-wrapper {
            .ant-btn {
                margin: 0 8px;
                height: 38px;
                font-weight: 500;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .invoice-footer-text {
        margin-top: 14px;
        width: 400px;

        .ant-typography {
            text-align: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            margin: 0;
        }
    }
}

.invoice-wrapper-dark {
    .invoice-card-wrapper {
        .invoice-top-section {
            border-bottom: 1px solid #2a2d2f;
        }
    }
}

.invoice-details-open-wrapper {
    width: calc(100% - 460px);
}

.invoice-details-drawer-wrapper {
    .ant-drawer-mask {
        display: none;
    }

    .ant-drawer-content-wrapper {
        top: 54px;
        box-shadow: none;

        .ant-drawer-wrapper-body {
            border-left: 1px solid #e5e7eb;
        }

        .close-wrapper {
            margin-bottom: 32px;

            svg {
                margin-right: 6px;
            }
        }

        h4.ant-typography {
            font-weight: 600;
            font-size: 24px;
            line-height: 38px;
            margin-bottom: 32px;
        }

        .invoice-section {
            margin-bottom: 32px;

            .section-subtitle {
                text-transform: uppercase;
                margin-bottom: 12px;
                font-weight: 500;
            }

            .section-label {
                width: 100px;
            }
        }

        .summary-section {
            h5.ant-typography {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                margin: 0;
            }
        }

        .items-section {
            padding: 0;

            .info-block {
                margin: 12px 0;

                h5.ant-typography {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                    margin: 0;
                }

                &:last-child {
                    margin-top: -12px;
                }
            }
        }
    }
}

.invoice-details-drawer-wrapper-dark {
    .ant-drawer-content-wrapper {
        .ant-drawer-wrapper-body {
            border-left: 1px solid #2a2d2f;
        }
    }
}

@media screen and (max-width: 1200px) {
    .ant-drawer-content-wrapper {
        max-width: 280px;
    }

    .invoice-details-open-wrapper {
        width: calc(100% - 280px);
    }
}

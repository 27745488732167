.version-history {
    display: flex;

    .version-status {
        display: flex;
        align-items: center;

        width: 65px;
        height: 32px;
        padding: 6px;

        border-radius: 6px;
        margin-right: 8px;

        svg {
            margin-right: 2px;
            margin-bottom: 2px;
        }
    }

    .version-dropdown {
        height: 32px;
        border-radius: 6px;
        padding: 6px;

        &:hover {
            background: #f3f7fd;
        }
    }

    .version-dropdown.dark-mode {
        &:hover {
            background: #ffffff33;
        }
    }

    .version-dropdown-label {
        display: flex;
        align-items: center;

        svg {
            margin-left: 2px;
        }

        &.disabled {
            cursor: not-allowed;

            &:hover {
                background: transparent;
            }
        }
    }

    article.status-text {
        font-weight: 400;
        font-size: 12px;
        color: #728192;
    }

    article.status-text.dark-mode {
        color: #9d9d9f;
    }
}

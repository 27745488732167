.flag-wrapper {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-left: 5px;

    img {
        margin-right: 5px;
    }

    article {
        font-size: 14px;
        line-height: 14px;
    }
}
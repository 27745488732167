.change-password-modal {
    .ant-modal {
        width: 600px !important;
        top: calc(50% - 183px);
        padding-bottom: 0;

        .ant-modal-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
        }

        .ant-modal-content {
            padding: 18px 24px 24px;
        }
    }

    .form-fields-wrapper {
        border-top: 1px solid #e5e7eb;
        border-bottom: 1px solid #e5e7eb;
        margin: 16px 0 24px;
        padding: 20px 0;
    }

    .ant-form-item
        .ant-form-item-label
        > label.ant-form-item-required:not(
            .ant-form-item-required-mark-optional
        )::before {
        display: none;
    }

    .ant-btn.ant-btn-lg {
        font-size: 16px;
        height: 38px;
        padding: 6.428571428571429px 15px;
        border-radius: 8px;
        margin-bottom: 0;
        font-weight: 500;
        font-size: 14px;
        width: 80px;

        &:first-child {
            margin-right: 14px;
        }
    }

    .ant-input-affix-wrapper {
        height: 38px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        padding-bottom: 6px;
        box-shadow: 0px 2px 4px #0000000a;
    }

    .ant-input-affix-wrapper-status-error:not(
            .ant-input-affix-wrapper-disabled
        ):not(
            .ant-input-affix-wrapper-borderless
        ).ant-input-affix-wrapper:focus {
        box-shadow: none;
    }

    .ant-input-affix-wrapper-focused {
        box-shadow: none !important;
    }

    .ant-input-affix-wrapper-status-error:not(
            .ant-input-affix-wrapper-disabled
        ):not(
            .ant-input-affix-wrapper-borderless
        ).ant-input-affix-wrapper:hover {
        border-color: #EF3E32;
    }

    .ant-form-item .ant-form-item-label > label {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }

    .ant-form-item {
        margin-bottom: 20px;
    }

    // Autofill background color and color text (light theme)
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        // !important should be for rewrite password element background
        -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
        -webkit-text-fill-color: #1f1f1f;
    }
}

.change-password-modal-dark {
    .form-fields-wrapper {
        border-top: 1px solid #2a2d2f;
        border-bottom: 1px solid #2a2d2f;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #19181d inset !important;
        -webkit-text-fill-color: #fff;
    }

    input:-internal-autofill-selected {
        background-color: #19181d !important;
        color: #fff !important;
        border: none !important;
    }

    span.anticon.ant-input-password-icon {
        svg {
            fill: #9d9d9f;
        }
    }
}

@import '../../../../resources/styles/variables.scss';

.billing-content-wrapper {
    .account-content-wrapper section {
        padding-bottom: 32px;
    }

    article {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
    }

    .text-accent {
        font-weight: 600;
        color: #1f1f1f;
    }

    a.ant-typography {
        margin-left: -8px;
    }

    .status-list-wrapper {
        margin-top: 16px;

        h5.ant-typography {
            font-weight: 500;
        }
    }

    .ant-table-wrapper .ant-table-tbody > tr > td {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .ant-table-cell {
        height: 60px;
    }

    .ant-table-thead {
        height: 38px;

        .ant-table-cell {
            height: 38px;
        }
    }

    .payment-wrapper {
        article {
            margin-bottom: 8px;
        }

        a.ant-typography {
            margin-top: 8px;
        }
    }

    .billing-info-wrapper {
        .ant-form-item {
            margin-bottom: 0;
        }

        .ant-form-item
            .ant-form-item-label
            > label.ant-form-item-required:not(
                .ant-form-item-required-mark-optional
            )::before {
            display: none;
        }

        .ant-form-item .ant-form-item-label > label {
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
        }

        .ant-input {
            height: 38px;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            display: flex;
            align-items: center;
            padding-bottom: 6px;
            box-shadow: 0px 2px 4px #0000000a;
        }

        textarea.ant-input {
            min-height: 38px;
            height: 150px;
        }

        .ant-input:placeholder-shown {
            font-weight: 400;
        }

        .ant-input:focus,
        .ant-input-focused {
            box-shadow: none;
        }

        .ant-input-status-error:not(.ant-input-disabled):not(
                .ant-input-borderless
            ).ant-input:focus {
            box-shadow: none;
        }

        .ant-input-disabled,
        .ant-input[disabled] {
            color: #1f1f1f;
            background-color: #fff;
            border-color: #e5e7eb;
        }

        .section-description {
            font-weight: 400;
            font-size: 13px;
            margin-top: 8px;
        }
    }

    .report-credits-wrapper {
        .credit-details-wrapper {
            height: 32px;
            margin-top: 16px;
            margin-bottom: 8px;

            a.ant-typography {
                margin-left: 8px;
            }
        }

        .credit-details-table {
            margin: 20px 0 16px;

            .ant-table-column-sorter {
                display: none;
            }

            .credit-details-table-column-header {
                width: fit-content;
                display: flex;
                align-items: center;

                svg {
                    margin-left: 4px;
                }

                svg > path {
                    stroke: transparent;
                }

                &:hover {
                    svg > path {
                        stroke: #9199ad;
                    }
                }

                svg.active > path {
                    stroke: #327eef;
                }
            }

            .competitors-table-header-title.dark-mode {
                &:hover {
                    svg > path {
                        stroke: #9d9d9f;
                    }
                }

                svg.active > path {
                    stroke: #327eef;
                }
            }
        }
    }

    .invoice-history-wrapper {
        .invoice-history-table {
            margin: 20px 0 16px;
        }

        .ant-btn-link {
            margin-left: -8px;
        }
    }

    .cancel-subscription-wrapper {
        .ant-btn {
            margin-bottom: 24px;
        }
    }
}

.invoice-history-table {
    .status-label {
        width: fit-content;
        padding: 4px 8px;
        border-radius: 6px;

        &-paid {
            background-color: #dff6e3;
            color: #20c752;

            &.dark-mode {
                background-color: $dark-success-bg-color;
            }
        }

        &-unpaid {
            background-color: #ef3e321a;
            color: #ef3e32;

            &.dark-mode {
                background-color: #510c04;
            }
        }
    }
}

.container-dark {
    .billing-content-wrapper {
        .text-accent {
            font-weight: 600;
            color: #fff;
        }

        .cancel-subscription-wrapper {
            .ant-btn.restart-subscription-button {
                background-color: #20c75224;
            }
        }
    }
}

.payment-info-section-wrapper {
    article .text-accent {
        color: #1f1f1f;
        font-weight: 600;
    }
}

.payment-info-section-wrapper-dark {
    article .text-accent {
        color: #fff;
    }
}

.copy-icon {
    cursor: pointer;
    width: 22px;
    height: 22px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;

    &:hover {
        background: #f3f7fd;
        svg {
            path {
                stroke: #327eef;
            }
        }
    }

    &:first-child {
        margin-left: 4px;
    }
}

.container-dark {
    .copy-icon {
        &:hover {
            background: #327eef1a;
        }
    }
}

.version-history-container {
    padding: 4px 18px 4px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    
    border-bottom: 1px solid #e5e7eb;

    .brief-actions-wrapper {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;

        .page-title-input-wrapper {
            padding: 0 0 0 16px;
            min-width: 200px;
            width: calc(100% - 93px);

            input {
                height: 27px;
            }

            h5.ant-typography {
                margin-bottom: 0;
                font-size: 14px;
                margin-right: 8px;
            }
        }

        .ai-button {
            margin-left: auto;

            button {
                display: flex;
                width: fit-content;
                align-items: center;
                border: none;
                border-radius: 4px;
                padding: 8px 8px 8px 4px;
                margin-left: 8px;
                margin-bottom: 0;
                box-shadow: none;

                article {
                    color: #1f1f1f;

                    &.dark-mode {
                        color: #9d9d9f;
                    }
                }

                &:hover {
                    border-color: #327eef;
                    background-color: #327eef1a;

                    article {
                        color: #327eef;
                    }

                    svg > path {
                        fill: #327eef;
                    }
                }

                &.ant-btn:disabled {
                    background-color: #327eef1a;
                }
            }
        }
    }
}

.version-history-container.dark-mode {
    border-color: #2a2d2f;

    .ai-button.dark-mode {
        border-color: #2a2d2f;

        button {
            &:hover {
                background: #ffffff33;
            }
        }

        button.active {
            background: #ffffff33;
        }
    }
}

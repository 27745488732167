.loader-wrapper {
    .ant-spin {
        color: #327eef;

        span {
            font-size: 32px;
        }
    }
}

.bar-loader-wrapper {
    flex-direction: column;
    align-items: center;

    .bar-loader {
        width: 100%;
        height: 6px;
        border-radius: 100px;
        display: inline-block;
        position: relative;
        background: #dfe3ec;
        overflow: hidden;

        &::after {
            content: '';
            width: 192px;
            height: 6px;
            border-radius: 100px;
            background: #327eef;
            position: absolute;
            top: 0;
            left: 0;
            box-sizing: border-box;
            animation: animloader 1s linear infinite;
        }
    }

    .bar-loader-message {
        margin-bottom: 16px;
    }
}

.bar-loader-wrapper-dark {
    .bar-loader {
        background: #1e1e1e;
    }
}

@keyframes animloader {
    0% {
        left: 0;
        transform: translateX(-100%);
    }
    100% {
        left: 100%;
        transform: translateX(0%);
    }
}

// primary colors
$primary-color: #327eef;
$primary-highlight-color: #327eef66;
$gray-text-color: #9d9d9f;

$basic-gray: #728192;

$light-primary-bg-color: #327eef1a;
$dark-primary-bg-color: #1b2131;

$primary-bg-disabled-color: #c3cfe4;

// success colors
$success-color: #1ea11d;

$dark-success-bg-color: #12431b;
$light-success-bg-color: #dff6e3;

// error colors
$error-color: #d52624;
$warning-bg-color: #feecea;
$dark-warning-bg-color: #310702;

// border colors
$light-border-color: #e5e7eb;
$dark-border-color: #2a2d2f;

// box shadow colors
$light-box-shadow-color: #0000000a;

// typography colors
$light-typography-primary-color: #1f1f1f;
$dark-typography-primary-color: #ffffff;

$light-typography-secondary-color: #728192;
$dark-typography-secondary-color: #9d9d9f;

$light-typography-disabled-color: #c3cfe4;
$dark-typography-disabled-color: #5c5c5c;

$disabled-dotted-text-color: #aeb5bc;

// background colors
$light-layout-bg-color: #f6f8fa;
$dark-layout-bg-color: #0b0a0f;

$light-section-bg-color: #ffffff;
$dark-section-bg-color: #16161c;

$color-primary-bg-light: #f3f7fd;
$color-dark-theme-bg-hover-dark: #ffffff33;

$light-input-bg-color: #ffffff;
$dark-input-bg-color: #ffffff0f;

$light-table-expanded-row-bg-color: #eaf2fd;
$dark-table-expanded-row-bg-color: #192131;

$dark-table-expanded-row-primary-bg-color: #2e2e33;

// icons colors
$sort-arrows-light-icon-color: #9199ad;
$sort-arrows-dark-icon-color: #9D9D9F;

// placeholder color
$tiptap-editor-placeholder-color: #adb5bd;

@import '../../../resources/styles/variables.scss';

// Basic Outline
.basic-outline-tag-wrapper {
    display: flex;
    border-radius: 6px;
    cursor: pointer;
    padding-right: 4px;

    .tag-line {
        width: 1px;
    }

    .add-button {
        svg {
            path {
                stroke: transparent;
            }
        }
    }

    &:hover {
        background: $color-primary-bg-light;

        .add-button {
            border: 1px solid $primary-color;
            color: $primary-color;
            background-color: transparent;
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 0 4px;

            svg {
                path {
                    stroke: $primary-color;
                }
            }
        }

        .ant-btn.ant-btn-icon-only {
            background-color: $light-section-bg-color;
        }
    }

    .add-button-wrapper {
        width: 20px;
        height: 20px;
        margin-left: auto;
        align-self: flex-start;

        .ant-btn.ant-btn-icon-only {
            margin-top: 4px;
            width: 18px;
            height: 18px;
            padding: 0;
        }
    }
}

.basic-outline-tag-wrapper.dark-mode {
    &:hover {
        background: $light-primary-bg-color;
        .add-button-wrapper {
            .ant-btn.ant-btn-icon-only {
                background-color: $dark-border-color;
            }
        }
    }
}

.basic-outline-tag {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    color: $light-typography-primary-color;

    .prefix-tag {
        font-weight: 400;
        font-size: 10px;
        color: $light-typography-secondary-color;
        margin-right: 6px;
        margin-left: 10px;
    }

    .prefix-tag.dark-mode {
        color: $dark-typography-secondary-color;
    }

    .bulleted-tag {
        font-size: 16px;
        padding-top: 4px;
        margin-right: 10.4px;
    }

    article {
        color: $light-typography-primary-color;
        font-weight: 400;
        margin-right: 6px;
        padding: 2px 0;
    }

    article.h1,
    article.h2 {
        font-weight: 500;
        font-size: 14px;
    }

    article.h3 {
        font-size: 14px;
    }

    article.h4 {
        font-size: 14px;
    }

    article.h5 {
        font-size: 12px;
    }

    article.h6 {
        font-size: 12px;
    }

    article.p {
        font-size: 12px;
    }

    article.dark-mode {
        color: $dark-typography-primary-color;
    }

    article.active-heading {
        font-weight: 500;
        color: $primary-color;
    }
}

.add-button {
    margin-left: auto;
    margin-bottom: 0;
    margin-right: 6px;
    border-radius: 4px;
    color: transparent;
    border-color: transparent;
    background-color: transparent;

    font-weight: 500;
    font-size: 12px;
    padding: 0px;
    width: 58px;
    height: 26px;
}

// Editable Outline
.editable-outline-buttons {
    align-self: self-start;
    display: flex;
    margin-top: 5px;
    margin-right: 5px;
    justify-content: space-between;
    width: 208px;
    height: 22px;

    button.editable-outline-button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.5px solid $light-border-color;
        border-radius: 4px;

        width: 22px;
        height: 22px;
        padding: 0;
    }

    span {
        margin-right: 4px;
    }

    & span:last-child {
        margin-right: 0;
    }
}

.editable-outline-tag-wrapper {
    display: flex;
    border-radius: 6px;
    cursor: grab;

    &:focus {
        outline: none;
    }

    .editable-outline-buttons {
        button.editable-outline-button {
            background: transparent;
            border-color: transparent;
            box-shadow: none;

            span {
                color: transparent;
                font-weight: 400;
            }

            svg path {
                stroke: transparent;
            }
        }
    }

    &:hover {
        background: $color-primary-bg-light;

        .editable-outline-buttons {
            button.editable-outline-button {
                background: $light-section-bg-color;
                border-color: $light-border-color;

                span {
                    color: $sort-arrows-light-icon-color;
                }

                svg path {
                    stroke: $sort-arrows-light-icon-color;
                }

                &:hover {
                    border-color: $primary-color;

                    span {
                        color: $primary-color;
                    }

                    svg path {
                        stroke: $primary-color;
                    }
                }
            }
        }
    }
}

.editable-outline-tag-wrapper.dark-mode {
    &:hover {
        background: $light-primary-bg-color;

        .editable-outline-buttons {
            button.editable-outline-button {
                background: $dark-border-color;
                border-color: $dark-border-color;

                svg path {
                    stroke: $dark-typography-secondary-color;
                }

                &:hover {
                    border-color: $primary-color;

                    svg path {
                        stroke: $primary-color;
                    }
                }
            }
        }
    }
}

.editable-outline-tag {
    min-height: 32px;
    display: flex;
    align-items: center;
    color: $light-typography-primary-color;
    flex-grow: 1;

    textarea {
        padding: 0;
        background: transparent;
        font-family: 'Inter';
        overflow-y: hidden;
        padding-left: 6px;
    }

    textarea.ant-input {
        min-height: 20px;
    }

    article {
        width: 100%;
    }

    .prefix-tag {
        height: 100%;
        font-weight: 400;
        font-size: 10px;
        color: $light-typography-secondary-color;
        margin-right: 6px;
        margin-left: 16px;
        padding-top: 10px;
    }

    .prefix-tag.dark-mode {
        color: $dark-typography-secondary-color;
    }

    .bulleted-tag {
        font-size: 16px;
        padding-top: 4px;
        margin-right: 10.4px;
    }

    textarea.h1,
    article.h1 {
        font-weight: 600;
        font-size: 22px;
        color: $light-typography-primary-color;
    }

    textarea.h2,
    article.h2 {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: $light-typography-primary-color;
        margin: 0.05em 0;
    }

    textarea.h3,
    article.h3 {
        font-weight: 500;
        font-size: 14px;
        color: $light-typography-primary-color;
        margin: 0.3em 0;
    }

    textarea.h4,
    article.h4 {
        font-weight: 400;
        font-size: 14px;
        color: $light-typography-primary-color;
        margin: 0.35em 0;
    }

    textarea.h5,
    article.h5 {
        font-weight: 400;
        font-size: 12px;
        color: $light-typography-primary-color;
        margin: 0.55em 0;
    }

    textarea.h6,
    article.h6 {
        font-weight: 400;
        font-size: 12px;
        color: $light-typography-primary-color;
        margin: 0.55em 0;
    }

    textarea.p,
    article.p {
        font-weight: 400;
        font-size: 12px;
        color: $light-typography-primary-color;
        margin: 0.55em 0;
    }

    textarea.dark-mode,
    article.dark-mode {
        color: $dark-typography-primary-color;
    }

    textarea.dark-mode {
        background: $dark-section-bg-color;
    }

    div.tag-save-button-wrapper {
        margin: 0px 8px;
    }
}

.tag-line {
    width: 0.75em;
    border-left: 1px solid $light-border-color;
}

.tag-line.dark-mode {
    border-left-color: $dark-border-color;
}

// View only outline
.view-only-outline-tag-wrapper {
    display: flex;

    .editable-outline-buttons {
        button.editable-outline-button {
            background: transparent;
            border-color: transparent;
            box-shadow: none;

            svg path {
                stroke: transparent;
            }
        }
    }
}

.view-only-outline-tag {
    display: flex;
    align-items: center;
    flex-grow: 1;

    .prefix-tag {
        height: 100%;
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        margin-right: 6px;
        margin-left: 16px;
        padding-top: 4px;
    }

    .ant-typography {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 4px;
    }
}

div.selected-outline-tag {
    background: $color-primary-bg-light;

    &.dark-mode {
        background: $light-primary-bg-color;
    }
}
@import '../../../../../../../../../resources/styles/variables.scss';

.examples-modal-content-wrapper {
    max-height: calc(100vh - 200px);
    margin-bottom: 5px;

    .ant-card.rank-item {
        box-shadow: none;
    }
}

.examples-modal-wrapper {
    &.ant-modal > .ant-modal-content {
        padding: 18px 0px 24px 24px;
    }

    .no-data-wrapper {
        height: calc(100vh - 220px);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ant-modal-header {
        margin-right: 24px;
        padding-bottom: 16px;
        margin-bottom: 24px;
        border-bottom: 1px solid #e5e7eb;
        text-transform: capitalize;

        .ant-modal-title {
            font-size: 18px;
        }
    }

    .ant-modal-body {
        padding-right: 18px;
        overflow-y: auto;
    }

    div.ant-spin-nested-loading {
        min-height: calc(100vh - 200px);
    }

    div.ant-spin.ant-spin-spinning.examples-spin-skeleton {
        min-height: calc(100vh - 224px);

        .ant-spin-container.ant-spin-blur {
            position: relative;
        }
    }

    .rank-item {
        width: 100%;
        border-radius: 6px;
        border-top-right-radius: 6px;
        margin-bottom: 20px;

        .show-more-link {
            margin-left: -8px;
        }

        .example-tag {
            min-width: 25px;
            margin-right: 10px;
            text-transform: lowercase;
            color: $light-typography-secondary-color;
        }

        .ant-empty-normal {
            margin-block: 12px;
        }

        .ant-card-head {
            background: #f6f8fa;
            padding: 8px 12px;
            border-radius: 0;

            &.ant-card-head {
                border-radius: 5px 5px 0 0;
                background: #f6f8fa;
            }

            .complete-badge-small {
                width: 30px;
                height: 20px;
                line-height: 20px;
                font-size: 12px;
                margin-right: 0;
            }

            .custom-badge {
                padding: 2px 4px 3px;
                border-radius: 4px;
            }
        }

        .ant-card-body {
            padding: 12px 14px;
        }

        .ant-empty-image {
            display: none;
        }

        .rank-badge {
            margin-right: 4px;
        }

        .rank-favicon {
            width: 16px;
            height: 16px;
            margin-right: 6px;
            vertical-align: middle;
            margin-bottom: 2px;
        }

        .example-sentence {
            margin-bottom: 12px;
            color: #1f1f1f;

            &.dark-mode {
                margin-bottom: 12px;
                color: #fff;
            }

            .examples-highlited-keyword {
                background: #dff6e3;

                &.dark-mode {
                    background: $dark-success-bg-color;
                }
            }
        }
    }
}

.examples-modal-wrapper.modal-wrapper-dark {
    .ant-modal-header {
        border-bottom: 1px solid #2a2d2f;
    }

    .rank-item {
        .ant-card-head {
            &.ant-card-head {
                background: #ffffff1a;
            }

            .examples-highlited-keyword {
                background: #b4d7fe;

                &.dark-mode {
                    background: #567aa3;
                }
            }
        }
    }
}
@import '../../../../../../../../../../resources/styles/variables.scss';

button.editor-more-btn.ant-btn {
    width: 64px;
    min-width: 64px;
    height: 32px;
    margin-left: 8px;
    padding: 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: none;

    .ant-typography {
        font-weight: 400;
        font-size: 12px;
    }

    svg {
        margin-left: 4px;
    }

    &:hover {
        background: #f3f7fd;
    }
}

button.editor-more-btn.ant-btn.dark-mode {
    &:hover {
        background: #ffffff33;
    }
}

.more-dropdown-overlay.ant-dropdown {
    .ant-dropdown-menu {
        padding: 8px 12px;
        .ant-dropdown-menu-item {
            color: #1f1f1f;
            margin-bottom: 6px;

            .import-row {
                margin: 0 4px;

                svg {
                    width: 19px;
                    height: 19px;
                }
            }

            .ant-dropdown-menu-title-content span {
                padding-right: 8px;
                color: $light-typography-secondary-color;
            }

            .ant-dropdown-menu-title-content span:hover {
                color: $primary-color;
            }
        }
    }

    .editor-more-settings-wrapper {
        margin: 0px 20px 16px;
        padding-top: 8px;
        border-top: 1px solid #e5e7eb;

        .editor-more-setting-item {
            width: fit-content;
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            h5.ant-typography {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                margin-left: 8px;
                margin-bottom: 0;
                color: $light-typography-secondary-color;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &.view-only {
            border-top: none;
            padding-top: 16px;
        }
    }
}

.more-dropdown-overlay-dark.ant-dropdown {
    .ant-dropdown-menu {
        .ant-dropdown-menu-item {
            color: #fff;

            .ant-dropdown-menu-title-content span {
                color: $dark-typography-secondary-color;
            }
        }
    }

    .editor-more-settings-wrapper {
        border-top: 1px solid #2a2d2f;

        .editor-more-setting-item h5.ant-typography {
            color: $dark-typography-secondary-color;
        }
    }
}

.import-from-url-modal-wrapper {
    h5.ant-typography {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 16px;
    }

    .extracted-url-wrapper {
        margin-bottom: 16px;

        svg {
            margin-top: 4px;
        }

        h5.ant-typography {
            margin: 0 0 0 12px;
            font-weight: 500;
        }

        a.ant-typography {
            margin-left: 26px;
        }
    }

    .ant-form-item .ant-form-item-control-input {
        min-height: 38px;

        .ant-input {
            height: 38px;
            display: flex;
            align-items: center;
            font-weight: 500;
        }

        .ant-input:placeholder-shown {
            font-weight: 400;
        }
    }

    .ant-input-status-error:not(.ant-input-disabled):not(
            .ant-input-borderless
        ).ant-input:hover {
        border-color: #ef3e32;
        box-shadow: none;
    }

    .bar-loader-wrapper,
    .no-extracted-content-wrapper {
        height: 150px;
        margin-top: 16px;
        border-top: 1px solid #e5e7eb;
    }

    .imported-data-wrapper {
        margin-top: 16px;
        border-top: 1px solid #e5e7eb;
        padding: 18px 0;

        .tiptap.ProseMirror {
            height: 420px;
            padding: 0;
            position: relative !important;
            top: 0;
            width: 100%;
            overflow: overlay;
            overflow-x: hidden;
        }

        &.dark-mode {
            color: #ffffff;
        }
    }

    .imported-data-actions-wrapper {
        justify-content: flex-end;
        padding-top: 16px;
        border-top: 1px solid #e5e7eb;

        .copy-icon {
            margin-right: 6px;

            svg path {
                stroke: #327eef;
            }
        }
    }

    .copy-icon {
        width: 32px;
        height: 32px;
        border-radius: 6px;

        &:hover {
            background-color: #327eef1a;
        }
    }
}

.import-from-url-modal-wrapper-dark {
    .bar-loader-wrapper,
    .no-extracted-content-wrapper,
    .imported-data-wrapper,
    .imported-data-actions-wrapper {
        border-top: 1px solid #2a2d2f;
    }
}

@import '../../../../../../resources/styles/variables.scss';

.table-row {
    .actions-buttons {
        margin-left: 6px;
        align-self: center;

        path {
            stroke: transparent;
            fill: transparent;
            cursor: pointer;
        }
    }

    .magic-pen-icon {
        padding-top: 2px;
    }

    &:hover {
        .actions-buttons {
            path {
                stroke: $primary-color;
                fill: $primary-color;
            }
        }

        .magic-pen-icon {
            path {
                stroke: $primary-color;
                fill: none;
            }

            path#stroke-star {
                stroke: none;
                fill: $primary-color;
            }
        }
    }
}

.aling-value-column {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
}

.monitor-disabled-text {
    color: $disabled-dotted-text-color;
}

.monitored-page-title {
    margin-left: 36px;
}

.content-monitor-table-section {
    .monitor-list-header-wrapper {
        margin-bottom: 20px;
        height: fit-content;

        .list-header-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 32px;
            margin-bottom: 0;

            .checked-items-actions-panel {
                margin-top: 0px;
            }
        }

        h3.ant-typography {
            margin-bottom: 0;
        }

        .ant-checkbox {
            margin: 0 16px 0 24.5px;

            .ant-checkbox-inner::after {
                inset-inline-start: 26.5%;
                top: 47%;
            }
        }

        .ant-checkbox-disabled .ant-checkbox-inner {
            background: transparent;
        }

        .ant-checkbox-wparrep:hover {
            .ant-checkbox-disabled .ant-checkbox-inner {
                border-color: #d9d9d9;
            }
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner:after {
            display: none;
        }
    }

    .ant-checkbox-group {
        display: block;

        .ant-table-wrapper {
            width: 100%;

            .ant-table-tbody > tr > td {
                padding: 15px 23.5px;
            }
        }
    }

    .monitor-table-checkbox.dark-mode {
        span.ant-checkbox-inner {
            border-color: $dark-border-color;
        }

        &:hover span.ant-checkbox-inner {
            border-color: $primary-color;
        }
    }
}

.monitor-projects-wrapper {
    padding: 4px 8px 0 0;
    flex-wrap: wrap;
    width: 100%;

    .ant-tag-processing {
        transition: all 0s;
        color: $primary-color;
        background: $color-primary-bg-light;
        border-color: $primary-color;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 4px;
    }

    &.dark-mode .ant-tag-processing {
        background: $light-primary-bg-color;
    }
}

.monitor-rank-loader-wrapper {
    label {
        padding-right: 32px;
    }

    display: flex;
    align-items: center;
}

a.monitored-page-url {
    font-size: 12px;
}

.monitor-name-wrapper {
    display: grid;
    place-items: stretch;

    .monitor-name-ellipsis {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .monitor-link-ellipsis {
        color: $primary-color;
    }
}

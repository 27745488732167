.go-back-btn {
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0;

    &:hover {
        border-color: #327eef !important; // !important because of antd classes 
    }
    
    svg {
        transform: rotate(-90deg);
        margin-top: 5px;
    }   
}
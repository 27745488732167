@import '../../../../resources/styles/variables.scss';

.content-briefs-subtitle {
    max-width: 560px;
}

.content-briefs-container {
    $marginWidth: 16px;
    $createInfoWidth: 320px;

    .brief-header.keyword-header-wrapper {
        display: flex;
        flex-flow: row wrap;

        .ant-form-item {

            &:nth-of-type(1),
            &.change-url-form-item-wrapper {
                max-width: calc(100% - ($createInfoWidth * 2));
            }

            &.change-url-form-item-wrapper {
                margin-top: $marginWidth;
            }
        }

        .ant-form-item:has(button),
        button {
            width: 146px;
            margin-right: 0;
        }
    }

    .dropdown-title {
        h3.ant-typography {
            margin-right: 8px;
        }
    }

    .content-briefs-view-all-container {
        padding: 24px;
    }

    .content-briefs-loader-wrapper {
        min-height: calc(100vh - 342px);
    }

    .ant-tabs .ant-tabs-content-holder {
        padding: 24px;
    }

    div.ant-tabs-nav {
        div.ant-tabs-nav-wrap {
            flex: none;
        }

        div.ant-tabs-extra-content {
            flex: 1;

            button.import-url-button {
                width: 30px;

                margin-left: 12px;
            }
        }
    }

    .list-more-button {
        width: 32px;
        height: 32px;
        margin-bottom: 0;
        padding: 0 5px;
    }

    .list-link {
        line-height: 20px;
    }

    .list-wrapper {
        height: fit-content;

        .card-header {
            max-width: calc(100% - $createInfoWidth - $marginWidth);
        }

        .card-wrapper {
            padding-top: 0;
        }

        .rankability-loader-wrapper {
            margin: 0;
            width: 100%;

            .rankability-loader {
                margin-right: 30px;
                margin-left: -10.5px;
            }
        }

        .list-link-wrapper {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .list-link-item-title {
                display: flex;
                width: 100%;
            }
        }

        .list-left-block {
            min-width: calc(100% - $createInfoWidth - $marginWidth);
            max-width: calc(100% - $createInfoWidth - $marginWidth);
        }

        .list-right-block {
            min-width: $createInfoWidth;
            align-items: center;
            margin-left: $marginWidth;
            margin-right: 0;
        }

        .ant-card .ant-card-body {
            flex-direction: column;
            align-items: flex-start;
        }

        .projects-wrapper {
            padding: 0 8px;
            margin-top: -4px;
            margin-bottom: 4px;
            flex-wrap: wrap;

            width: 100%;

            .ant-tag-processing {
                transition: all 0s;
                color: #327eef;
                background: #f3f7fd;
                border-color: #327eef;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 4px;
            }
        }

        .create-info-wrapper {
            display: flex;
            height: fit-content;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

.kanban-list-wrapper {
    .ant-list-item {
        padding: 0;
        box-shadow: 0px 4px 25px #3333330d;
    }

    .list-card-wrapper {
        flex-direction: column;
        width: 100%;
    }

    .list-create-info-wrapper {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 12px;
    }

    .card-header {
        padding-bottom: 12px;
        border-bottom: 1px solid #e5e7eb;
        margin-bottom: 12px;
        justify-content: space-between;
        align-items: center;
    }

    &.list-wrapper .rankability-loader-wrapper {
        margin: auto;
        width: 100%;
        min-width: 100%;

        .rankability-loader {
            margin-right: 0;
        }
    }

    .list-kanban-more-button {
        .list-more-button {
            display: flex;
            margin-left: 0px;
        }
    }

    .ant-card-bordered {
        height: 100%;
    }

    .ant-col {
        height: 100%;
        padding-bottom: 20px;
    }

    .ant-col>.ant-list-item {
        margin-block-end: 0;
        height: 100%;
    }
}

.kanban-list-wrapper.list-wrapper {
    .card-wrapper {
        width: 100%;
        padding-top: 0;
        flex-direction: column;
        justify-content: space-between;
    }

    .rankability-loader-wrapper {
        width: 100%;
        min-width: 100%;
        margin: auto;

        .rankability-loader {
            margin-right: 0;
        }
    }

    .list-left-block {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding-right: 0;
    }

    .list-right-block {
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        min-width: 100%;
        margin-left: 0px;
    }

    .card-header {
        width: 100%;
        max-width: 100%;

        div:first-of-type {
            overflow: hidden;
        }
    }

    .list-link-wrapper {
        display: flex;
        align-items: center;
    }

    .list-link {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .list-create-info-wrapper {
        white-space: pre-wrap;
        margin-left: 0;
        width: 100%;
    }

    .ant-card .ant-card-body {
        padding: 15px;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
    }

    .list-spacer {
        display: none;
    }

    .list-link-wrapper {
        margin-right: 8px;
    }

    .projects-wrapper {
        padding: 8px 0 0;
    }

    .empty-space {
        height: 34px;
    }
}

.container-dark {
    .kanban-list-wrapper {
        .card-header {
            border-bottom: 1px solid #2a2d2f;
        }
    }

    .create-area-wrapper {
        .ant-form-item .ant-form-item-label>label {
            color: #fff;
        }
    }

    .area-multiple-wrapper {
        border: 1px solid #2a2d2f;
        box-shadow: 0px 2px 4px #0000000a;

        .area-multiple-list-number-wrapper {
            background: #ffffff1a;
            border-right: 1px solid #2a2d2f;
            color: #fff;
            text-align: center;
        }
    }

    .input-error {
        border-color: #ef3e32;

        &:hover {
            border-color: #ef3e32;
        }
    }

    .list-wrapper {
        .projects-wrapper {
            .ant-tag-processing {
                background: #327eef1a;
            }
        }
    }

    .badge-disabled {
        color: #fff;
    }
}

.share-dropdown-overlay.ant-dropdown {
    border-radius: 6px;

    .ant-dropdown-arrow {
        overflow: visible;

        &::before {
            display: none;
        }
    }

    .ant-dropdown-arrow::after {
        box-shadow: none;
        z-index: 0;
        background: #fff;
        border-right: 1px solid #e5e7eb;
        border-bottom: 1px solid #e5e7eb;
    }

    .ant-switch.ant-switch-small {
        width: 28px;
        min-width: 28px;
        margin-right: 8px;
    }

    .ant-dropdown-menu {
        padding: 12px;
        outline: none;
        box-shadow: 0px 4px 12px #0000001f;
        border: 1px solid #e5e7eb;

        .ant-dropdown-menu-item:hover {
            background-color: transparent;
            cursor: auto;
        }
    }

    h5.ant-typography {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .ant-typography .ant-typography-copy {
        color: #327eef;
        height: 16px;
        margin-left: 8px;
    }

    .ant-typography .ant-typography-copy-success {
        height: 22px;
        color: #327eef;
    }

    .share-link-wrapper {
        border: none;
        box-shadow: none;
        min-width: 500px;
        padding: 0;

        .ant-input {
            &, &:hover, &:focus {
                border: 1px solid $light-border-color;
                box-shadow: 0 2px 4px $light-box-shadow-color;
                color: $light-typography-secondary-color;
                font-weight: 400;
            }
        }

        .copy-icon {
            height: 16px;
            width: 16px;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}

.share-dropdown-overlay.dark-mode.ant-dropdown {
    .ant-dropdown-menu {
        border: 1px solid #2a2d2f;
    }

    .ant-dropdown-arrow::after {
        background: #0b0a0f;
        border-right: 1px solid #2a2d2f;
        border-bottom: 1px solid #2a2d2f;
    }

    .share-link-wrapper {
        background: $dark-input-bg-color;

        .ant-input {
            &, &:hover, &:focus {
                border: 1px solid $dark-border-color;
                color: $dark-typography-secondary-color;
            }
        }

        .copy-icon:hover {
            background-color: $light-box-shadow-color;
        }
    }
}

.ant-dropdown.projects-dropdown-overlay {
    min-width: fit-content;

    .ant-dropdown-arrow {
        overflow: visible;
        top: 12.5px;

        &::before {
            display: none;
        }
    }

    .ant-dropdown-arrow::after {
        box-shadow: none;
        z-index: 0;
        background: #fff;
        border-right: 1px solid #e5e7eb;
        border-bottom: 1px solid #e5e7eb;
    }

    .ant-dropdown-menu .ant-dropdown-menu-item-selected:hover {
        background-color: #327eef1a;
    }

    .ant-dropdown-menu .ant-dropdown-menu-item-disabled:hover {
        background-color: transparent;

        span {
            color: #c3cfe4;
        }
    }
}

.projects-dropdown-overlay.dark-mode.ant-dropdown {
    .ant-dropdown-menu {
        border: 1px solid #2a2d2f;
    }

    .ant-dropdown-arrow::after {
        background: #0b0a0f;
        border-right: 1px solid #2a2d2f;
        border-bottom: 1px solid #2a2d2f;
    }

    .share-link-wrapper {
        background: #ffffff0f;
        border: 1px solid #2a2d2f;
        box-shadow: 0px 2px 4px #0000000a;

        .ant-typography {
            color: #2a2d2f;
        }
    }
}

.delete-popconfirm {
    z-index: 1051;

    .ant-popover-arrow {
        right: 25px;
        overflow: visible;

        &::before {
            display: none;
        }

        &::after {
            box-shadow: none;
            background: #fff;
            border-right: 1px solid #e5e7eb;
            border-bottom: 1px solid #e5e7eb;
        }
    }

    .ant-popover-inner {
        background-color: #ffffff;
        border-radius: 6px;
        box-shadow: 0px 4px 12px #0000001f;
        padding: 16px;
        border: 1px solid #e5e7eb;
    }

    .ant-popconfirm-buttons {
        text-align: end;
        display: flex;
        justify-content: flex-end;

        .ant-btn.ant-btn-sm {
            width: fit-content;
            margin-bottom: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
        }

        .ant-btn-default {
            background-color: transparent;
            border-color: #327eef;
            color: #327eef;

            &:hover {
                background-color: #327eef1a;
            }
        }
    }
}

.delete-popconfirm-dark, .block-popconfirm-dark {
    .ant-popconfirm-message-title {
        color: #fff;
    }

    .ant-popover-arrow {
        &::after {
            background: #0b0a0f;
            border-right: 1px solid #2a2d2f;
            border-bottom: 1px solid #2a2d2f;
        }
    }

    .ant-popover-inner {
        background-color: #0b0a0f;
        border: 1px solid #2a2d2f;
    }

    .ant-popconfirm-buttons>.ant-btn-default:hover {
        background-color: $dark-primary-bg-color;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.ai-button-loading {
    svg {
        animation: rotate 1s linear infinite;
    }
}

.content-optimizer-container {
    .list-wrapper {
        height: fit-content;

        .rankability-loader-wrapper {
            width: 100%;
            min-width: 100%;
            margin: auto;

            .rankability-loader {
                margin-right: 0;
                margin-left: -10.5px;
            }

            .list-more-button {
                margin-left: 30px;
            }
        }
    }

    .list-word-count {
        color: #728192;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
    }

    .list-word-count.dark-mode {
        color: #9d9d9f;
    }

    .kanban-list-wrapper.list-wrapper {
        .list-complete-info {
            justify-content: flex-start;
            flex-wrap: wrap;

            .list-word-count {
                margin-right: 14px;
            }
        }
    }
}

.content-optimizer-container-dark {
    .list-word-count {
        color: #ffffff;
    }
}

div.optimizer-results-filter-wrapper {
    margin-left: 40px;
}

#keywords_help .ant-form-item-explain-error {
    margin-bottom: 10px;
}

@media screen and (max-width: 1200px) {
    .checked-items-actions-panel {
        margin-top: 8px;
    }

    .list-header-wrapper {
        align-items: start;
    }

    .list-header-title-wrapper {
        flex-direction: column;
    }

    .list-wrapper {
        .list-link-wrapper {
            display: flex;
            align-items: center;
        }

        .list-link {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .kanban-list-wrapper.list-wrapper {
        .card-header {
            width: 100%;
        }

        .list-create-info-wrapper {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }

        .ant-row>div {
            min-width: 33.33%;
        }

        .ant-checkbox {
            margin-right: 4px;
        }

        .list-link-wrapper {
            margin-right: 0;
        }

        .list-right-block {
            min-width: 100%;
        }
    }

    .content-optimizer-container {
        .kanban-list-wrapper.list-wrapper {
            .list-more-button {
                margin-left: 0;
            }
        }
    }
}
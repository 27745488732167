.payment-method-wrapper {
    .label.ant-typography {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        margin: 8px 0 4px;
    }

    .card-input {
        height: 38px;
        width: 100%;
        border: 1px solid #e5e7eb;
        border-radius: 6px;
        padding: 8px;
        box-shadow: 0px 2px 4px #0000000a;
    }
}

.payment-method-wrapper-dark {
    .card-input {
        border-color: #2a2d2f;
        background-color: #ffffff0f;
    }
}

.keyword-list-of-results {
    .ant-row {
        justify-content: space-between;
    }

    .notification-card .ant-card-body .ant-typography {
        font-size: 14px;
        line-height: 20px;
        margin: 0;
    }

    article.text-configuration {
        font-size: 18px;
        font-weight: 600;
    }

    article.title {
        color: #1f1f1f;
        margin: 0 32px 14px 0;
    }

    article.title.dark-mode {
        color: #ffffff;
    }

    .export-button {
        border: 1px solid #327eef;
        border-radius: 6px;
        color: #327eef;
        font-weight: 500;
        font-size: 14px;
        width: 75px;
        height: 32px;
        display: flex;
        align-items: center;
        margin: 0 12px;

        svg {
            margin-right: 4px;
        }
    }

    .copy-button {
        display: flex;
        align-items: center;

        svg {
            margin-right: 4px;
        }
    }

    .anticon-info-circle {
        margin-left: 3px;

        &:hover {
            color: #327eef;
        }

        svg {
            overflow: visible;
        }
    }

    .column-width {
        min-width: 114px;
    }

    .column-content-color {
        article {
            color: #181a1d;
        }
    }

    .column-content-color-dark {
        article {
            color: #ffffff;
        }
    }

    .table-row {
        .actions-buttons {
            margin-left: 6px;

            path {
                stroke: transparent;
                fill: transparent;
                cursor: pointer;
            }
        }

        .magic-pen-icon {
            padding-top: 2px;
        }

        &:hover {
            .actions-buttons {
                path {
                    stroke: #327eef;
                    fill: #327eef;
                }
            }

            .magic-pen-icon {
                path {
                    stroke: #327eef;
                    fill: none;
                }

                path#stroke-star {
                    stroke: none;
                    fill: #327eef;
                }
            }
        }
    }

    .ant-table-wrapper .ant-table-column-sorter {
        display: none;
    }

    .ant-table-wrapper .ant-table-thead th {
        height: 38px;
    }

    .url-tab.ant-table-wrapper .ant-table-tbody > tr > td, .url-tab.ant-table-wrapper .ant-table-thead th {
        &:first-of-type {
            padding: 10px 12px 10px 24px;
        }

        &:last-of-type {
            padding: 10px 24px 10px 12px;
        }

        padding: 10px 12px;
    }
    
    .ant-table-wrapper
        .ant-table.ant-table-bordered
        > .ant-table-container
        > .ant-table-content
        > table
        > tbody
        > tr
        > .ant-table-cell-fix-right-first::after,
    .ant-table-wrapper
        .ant-table.ant-table-bordered
        > .ant-table-container
        > .ant-table-content
        > table
        > thead
        > tr
        > .ant-table-cell-fix-right-first::after {
        border-inline-end: none;
    }

    .highlight-keyword {
        font-weight: 700;
        background-color: transparent;
    }

    .sort-icons-wrapper {
        margin-left: 8px;
        position: relative;

        svg {
            position: absolute;

            &:first-child {
                top: -10px;
            }

            &:last-child {
                bottom: -9px;
            }
        }
    }
}

.results-filters-wrapper {
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .filters-popovers-wrapper {
        flex-wrap: wrap;
        padding-top: 8px;
    }

    .filters-action-wrapper {
        margin-right: 16px;

        .ant-btn {
            font-weight: 500;
        }

        .ant-btn-primary {
            height: 30px;
            box-shadow: none;
            border-color: transparent;
            background: #327eef21;
            color: #327eef;
            font-size: 14px;
            margin: 0;
            padding: 0 8px;
            line-height: 18px;
            margin-right: 6px;

            &:hover {
                border-color: #327eef;
            }
        }

        .ant-btn-primary.ant-btn-background-ghost {
            color: #327eef;
            background-color: transparent;
            border: 1px solid #327eef;
            box-shadow: none;

            &:hover {
                background-color: #327eef21;
            }
        }
    }
}

.keyword-research-container.results-container {
    .ant-tabs {
        height: auto;

        .ant-tabs-content-holder {
            padding: 0;
        }
    }

    .results-content-wrapper {
        padding: 24px;
    }
}

.keywords-result-table-header-title {
    width: fit-content;

    .active {
        color: #327eef;
    }

    svg > path {
        stroke: transparent;
    }

    &:hover {
        svg > path {
            stroke: #9199ad;
        }
    }

    svg.active > path {
        stroke: #327eef;
    }
}

.keywords-result-table-header-title.dark-mode {
    &:hover {
        svg > path {
            stroke: #9d9d9f;
        }
    }

    svg.active > path {
        stroke: #327eef;
    }
}

.keywords-rankability-loader-container {
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.keyword-table {
    .ant-table-sticky-scroll-bar {
        display: none;
    }
}

.keyword-table.hide-x-scroll {
    div.ant-table-body {
        overflow: hidden !important;
    }
}

// SignIn
.signin-wrapper {
    width: 400px;

    article {
        margin-bottom: 4px;
    }

    input {
        height: 38px;
        box-shadow: 0px 2px 4px #0000000a;
    }

    span.ant-input-password {
        height: 38px;
        box-shadow: 0px 2px 4px #0000000a;

        input.ant-input {
            height: 28px;
            box-shadow: none;
        }
    }

    .forgot-password {
        margin-top: auto;
        margin-bottom: 6px;
        padding: 4px 8px 4px;
    }

    .ant-form-item {
        margin-bottom: 0;
    }

    .ant-form-item.custom-inputs {
        margin-bottom: 20px;
    }

    .singin-options {
        margin-top: 26px;
        margin-bottom: 0;
    }
}

.signin-contact-us {
    article {
        margin-bottom: 0;
    }
}

// ForgotPassword
.forgot-password-wrapper {
    width: 400px;

    article {
        margin-bottom: 4px;
    }

    input {
        height: 38px;
        box-shadow: 0px 2px 4px #0000000a;
    }
}

// Common
article.main-typography {
    font-weight: 600;
    font-size: 18px;
    color: #1f1f1f;
    margin-bottom: 4px;
}

article.description-typography {
    margin-bottom: 20px;
}

button {
    width: 100%;
    height: 38px;
    margin-bottom: 12px;
}

article.dark-theme-typography {
    color: #ffffff;
}

.bm-cancel {
    position: fixed !important;
    height: 100vh;
    overflow-y: auto;
    justify-content: unset !important;

    .bm-background {
        visibility: hidden;
    }

    .bm-cancel-modal {
        overflow: unset;
        box-shadow: 0px 20px 70px #1f1f1fcc;
        padding: 5px 40px 10px;
    }
}
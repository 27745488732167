.organizations-container {
    padding: 24px 24px 0px;

    h5.ant-typography {
        margin-top: 12px;
        margin-left: 10px;

        &:first-child {
            margin-top: 0;
        }
    }

    .users-table-header-title article {
        white-space: nowrap;
    }

    .ant-table-wrapper tr:not(.ant-table-expanded-row) {
        & th:last-child {
            text-align: end;
            padding-right: 24px;
        }
    }

    .actions-cell {
        display: flex;
        justify-content: flex-end;
    }

    .ant-table-wrapper
        .ant-table.ant-table-bordered
        > .ant-table-container
        > .ant-table-content
        > table
        > tbody
        > tr
        > .ant-table-cell-fix-right-first::after,
    .ant-table-wrapper
        .ant-table.ant-table-bordered
        > .ant-table-container
        > .ant-table-content
        > table
        > thead
        > tr
        > .ant-table-cell-fix-right-first::after {
        border-inline-end: none;
    }

    .ant-table-wrapper
        .ant-table.ant-table-bordered
        > .ant-table-container
        > .ant-table-content
        > table
        > tbody
        > tr
        > td
        > .ant-table-expanded-row-fixed::after {
        display: none;
    }

    .ant-table-wrapper
        .ant-table-tbody
        > tr
        > td
        > .ant-table-wrapper:only-child
        .ant-table,
    :where(.css-dev-only-do-not-override-5d3cb3).ant-table-wrapper
        .ant-table-tbody
        > tr
        > td
        > .ant-table-expanded-row-fixed
        > .ant-table-wrapper:only-child
        .ant-table {
        margin-block: -0;
        margin-inline: 0;
        border-radius: 6px;
        border-bottom: 1px solid #e5e7eb;
    }

    .ant-table-wrapper
        .ant-table-container
        table
        > thead
        > tr:first-child
        > *:first-child {
        border-left: 1px solid #e5e7eb;
    }

    .ant-table-expanded-row.ant-table-expanded-row-level-1 > .ant-table-cell {
        padding: 12px;
    }

    .ant-table-wrapper
        .ant-table-tbody
        > tr
        > td
        > .ant-table-wrapper:only-child
        .ant-table
        .ant-table-tbody
        > tr:last-child
        > td {
        &:first-child {
            border-bottom-left-radius: 6px;
        }

        &:last-child {
            border-bottom-right-radius: 6px;
        }
    }

    .ant-table-row-level-0:hover {
        tr:last-child > td:first-child {
            border-inline-start: 1px solid #e5e7eb;
            border-bottom-left-radius: 6px;
        }
    }

    .ant-table-wrapper .expanded-row {
        background-color: #eaf2fd;

        .ant-table-cell-fix {
            &-left,
            &-right {
                background-color: #eaf2fd;
            }
        }
    }

    .ant-table-wrapper
        .ant-table-tbody
        > tr.ant-table-row.expanded-row:hover
        > td,
    .ant-table-wrapper
        .ant-table-tbody
        > tr.expanded-row
        > td.ant-table-cell-row-hover {
        background: #eaf2fd;
    }

    .ant-table-expanded-row {
        .ant-table-wrapper
            .ant-table.ant-table-bordered
            > .ant-table-container
            > .ant-table-content
            > table {
            background: #f6f8fa;

            & > tbody > tr > td {
                background-color: #fff;
                display: table-cell;
            }
        }
    }

    .users-table-header-title {
        width: fit-content;

        .ant-typography {
            font-size: 10px;
        }

        .active {
            color: #327eef;
        }

        svg {
            margin-left: 4px;
        }

        svg > path {
            stroke: transparent;
        }

        &:hover {
            svg > path {
                stroke: #9199ad;
            }
        }

        svg.active > path {
            stroke: #327eef;
        }
    }

    .expand-icon {
        width: 22px;
        height: 22px;
        border-radius: 4px;

        &:hover {
            background: #327eef1a;

            svg path {
                stroke: #327eef;
            }
        }
    }

    .actions-button {
        width: 32px;
        height: 32px;
        margin-bottom: 0;
        padding: 0 5px;
        box-shadow: none;

        &:hover,
        &:focus {
            border: none;
        }
    }
}

.organizations-container.container-dark {
    .ant-table-wrapper
        .ant-table-tbody
        > tr
        > td
        > .ant-table-wrapper:only-child
        .ant-table,
    :where(.css-dev-only-do-not-override-5d3cb3).ant-table-wrapper
        .ant-table-tbody
        > tr
        > td
        > .ant-table-expanded-row-fixed
        > .ant-table-wrapper:only-child
        .ant-table {
        border-bottom: 1px solid #2a2d2f;
    }

    .ant-table-wrapper
        .ant-table-container
        table
        > thead
        > tr:first-child
        > *:first-child {
        border-left: 1px solid #2a2d2f;
    }

    .ant-table-row-level-0:hover {
        tr:last-child > td:first-child {
            border-inline-start: 1px solid #16161c;
        }
    }

    .ant-table-wrapper .expanded-row,
    .expanded-row:hover td {
        background-color: #192131;

        .ant-table-cell-fix {
            &-left,
            &-right {
                background-color: #192131;
            }
        }
    }

    .ant-table-wrapper
        .ant-table-tbody
        > tr.ant-table-row.expanded-row:hover
        > td,
    .ant-table-wrapper
        .ant-table-tbody
        > tr.expanded-row
        > td.ant-table-cell-row-hover {
        background: #192131;
    }

    .ant-table-expanded-row {
        .ant-table-wrapper
            .ant-table.ant-table-bordered
            > .ant-table-container
            > .ant-table-content
            > table {
            border-top-color: #16161c;
            background: #2e2e33;
            & > thead > tr > th:first-child {
                border-inline-start: 1px solid #16161c;
            }

            & > tbody > tr > td {
                background-color: #16161c;
            }

            & > tbody > tr > td:first-child {
                border-inline-start: 1px solid #16161c;
            }

            & > thead > tr:first-child > *:last-child,
            & > tbody > tr > td:last-child {
                border-inline-end: 1px solid #16161c;
            }
        }

        .ant-table-wrapper
            .ant-table.ant-table-bordered
            .ant-table-tbody
            > tr:last-child
            > td {
            border-bottom: 1px solid #16161c;
        }
    }
}

@media screen and (max-width: 1200px) {
    .organizations-container .actions-cell {
        padding-right: 8px;
    }
}

div.home-title-wrapper {
    margin: 30px;
}

div.home-content-wrapper {
    margin: 0 24px 0;

    .home-card-column {
        margin-bottom: 24px;
    }
}

div.home-card {
    background: linear-gradient(
        90deg,
        rgba(31, 31, 31, 1) 0%,
        rgba(0, 14, 76, 1) 45%,
        rgba(50, 126, 239, 1) 100%
    );
    color: #ffffff;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    height: 100%;
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .home-card-title {
        margin-bottom: 0;
        color: #ffffff;

        .title-icon {
            margin-right: 10px;

            &#s-optimizer-icon-container {
                margin-right: 9px;

                svg {
                    margin-bottom: -1px;
                }
            }

            &#i-optimizer-icon-container {
                margin-right: 8px;

                svg {
                    margin-bottom: -1px;
                }
            }

            &#cm-icon-container {
                margin-right: 9px;

                svg {
                    margin-bottom: -1px;
                }
            }

            &#kr-icon-container {
                margin-right: 5px;
            }

            svg {
                margin-bottom: -2px;
            }
        }
    }

    .home-card-subtitle,
    .home-card-description {
        margin-bottom: 14px;
        margin-top: 4px;
        color: #ffffff;
    }

    .home-card-buttons {
        display: flex;
        flex-wrap: wrap;

        button.start-optimizing-btn {
            width: 144px;

            margin-bottom: 4px;
            margin-right: 12px;
        }

        button.how-to-use-btn {
            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 136px;

            box-shadow: none;
            border: 1px solid transparent;
            background: transparent;
            color: #728192;

            &:hover {
                background: #327eef21;
                color: #327eef;

                svg path {
                    stroke: #327eef;
                }
            }
        }
    }
}

div.home-card.dark-mode {
    background: linear-gradient(
        90deg,
        rgba(31, 31, 31, 1) 0%,
        rgba(0, 14, 76, 1) 45%,
        rgba(50, 126, 239, 1) 100%
    );
    border: 1px solid #2a2d2f;
    box-shadow: 0px 4px 25px #3333330d;

    button.how-to-use-btn {
        color: #9d9d9f;
    }

    .home-card-subtitle.dark-mode,
    .home-card-description.dark-mode {
        color: #ffffff;
    }
}

@media screen and (max-width: 880px) {
    div.home-title-wrapper {
        margin: 24px;
    }
}

#home-page-title-text {
    line-height: 40px;
    padding-left: 10px;
}

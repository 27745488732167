@import './resources/styles/variables.scss';

* {
    font-family: 'Inter';
}

.d-none {
    display: none;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.min-h-100 {
    min-height: 100%;
}

.d-flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-break-row {
    flex-basis: 100%;
    height: 0;
}

.flex-baseline {
    align-items: baseline;
}

.flex-start {
    align-items: flex-start;
}

.flex-center {
    align-items: center;
    justify-content: center;
}

.flex-column {
    flex-direction: column;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.justify-content-start {
    justify-content: flex-start;
}

.justify-content-end {
    justify-content: flex-end;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-auto {
    cursor: auto;
}

.m-1 {
    margin: 1em;
}

.m-2 {
    margin: 2em;
}

.mr-1 {
    margin-right: 1em;
}

.mr-2 {
    margin-right: 2em;
}

.ml-1 {
    margin-left: 1em;
}

.ml-2 {
    margin-left: 2em;
}

.mt-1 {
    margin-top: 1em;
}

.mb-1 {
    margin-bottom: 1em;
}

.mb-2 {
    margin-bottom: 2em;
}

.mr-4px {
    margin-right: 4px;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.text-active {
    color: $primary-color;
}

.text-decoration-dotted,
a.ant-typography.text-decoration-dotted {
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-color: $disabled-dotted-text-color;
    text-decoration-thickness: 1px;
    text-underline-offset: 3.5px;
}

a.ant-typography.text-decoration-dotted {
    text-decoration-color: inherit;
}

.link-hover-highlight.ant-typography {
    display: block;
    width: fit-content;
    padding: 4px 8px 6px;
    border-radius: 6px;
    transition: all 0.3s;

    font-weight: 400;

    &:hover {
        background-color: $light-primary-bg-color;
    }
}

.link-hover-highlight.ant-typography.dark-mode {
    &:hover {
        background-color: $dark-primary-bg-color;
    }
}

.btn-hover-light-primary {
    min-width: fit-content;
    background: transparent;
    border-color: transparent;
    border-radius: 6px;
    padding: 0 8px;
    font-weight: 500;

    &:hover,
    &:focus {
        transition: 300ms;
        cursor: pointer;
        background: $light-primary-bg-color;
        border: 1px solid $primary-color;

        svg {
            path {
                stroke: $primary-color;
            }
        }

        article {
            color: $primary-color;
        }
    }
}

.btn-hover-light-primary.dark-mode {
    &:hover,
    &:focus {
        background: $dark-primary-bg-color;
    }
}

.btn-icon {
    cursor: pointer;
    width: 22px;
    height: 22px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;

    &:hover {
        background: $light-primary-bg-color;

        svg {
            path {
                stroke: $primary-color;
            }
        }
    }
}

.btn-primary {
    color: #fff;
    background-color: $primary-color;
    border: none;
    width: fit-content;
    height: 38px;
    margin-bottom: 0;

    &.ant-btn:hover {
        background-color: #000e4c;
        color: #fff;
    }
}

.btn-success {
    color: #20c752;
    background-color: #dff6e3;
    border: none;
    width: fit-content;
    height: 38px;
    margin-bottom: 0;

    &.ant-btn:hover {
        color: #20c752;
    }

    &.dark-mode {
        background: $dark-success-bg-color;
    }
}

button.ant-btn.btn-danger:not(:disabled) {
    background-color: #ef3e321a;
    color: $error-color;
    border: none;
    width: fit-content;
    height: 38px;

    &.ant-btn:hover {
        color: $error-color;
    }

    &.dark-mode {
        background: #510c04;

        &:hover {
            background-color: $dark-warning-bg-color;
        }
    }
}

.create-new-btn.ant-btn {
    width: fit-content;
    height: 32px;
    max-height: 32px;
    padding: 0 12px 0 10px;

    span {
        margin-left: 5px;
    }
}

.container-dark {
    .btn-icon {
        &:hover {
            background: $light-primary-bg-color;
        }
    }
}

.ant-layout .ant-layout-content {
    min-height: fit-content;
    margin: 24px 24px 0;
    margin-bottom: 0;
    border-radius: 6px 6px 0 0;
    border: 1px solid #e5e7eb;
}

.container {
    border: 1px solid #e5e7eb;
}

.ant-layout .ant-layout-content.container-dark,
.container-dark {
    border: 1px solid #2a2d2f;
}

.text-overflow-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
}

.page-title-wrapper {
    margin: 5.5px 0 -17px;
    text-align: center;

    .page-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
    }

    article {
        margin-bottom: 3px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.3px;
    }
}

.space-no-wrap {
    white-space: nowrap;
}

.text-danger {
    color: #ef3e32;
}

.dropdown-with-arrow-overlay.ant-dropdown {
    box-shadow: 0 4px 25px #3333330d;

    .ant-dropdown-arrow {
        overflow: visible;

        &::before {
            display: none;
        }
    }

    .ant-dropdown-arrow::after {
        box-shadow: none;
        z-index: 0;
        background: #fff;
        border-right: 1px solid #e5e7eb;
        border-bottom: 1px solid #e5e7eb;
    }
}

.dropdown-with-arrow-overlay.dark-mode.ant-dropdown,
.dropdown-with-arrow-overlay-dark.ant-dropdown {
    .ant-dropdown-menu {
        border: 1px solid #2a2d2f;

        .ant-dropdown-menu-item-selected:hover {
            background-color: $light-primary-bg-color;
        }
    }

    .ant-dropdown-arrow::after {
        background: #0b0a0f;
        border-right: 1px solid #2a2d2f;
        border-bottom: 1px solid #2a2d2f;
    }
}

.dropdown-overlay-with-extra-content.ant-dropdown {
    .dropdown-with-extra-content-wrapper {
        border-radius: 6px;
        border: 1px solid #e5e7eb;
        background-color: #fff;
        box-shadow: 0px 4px 25px #3333330d;

        .ant-dropdown-menu {
            border: none;
        }
    }
}

.dropdown-overlay-with-extra-content.dark-mode.ant-dropdown,
.dropdown-overlay-with-extra-content-dark.ant-dropdown {
    .dropdown-with-extra-content-wrapper {
        border: 1px solid #2a2d2f;
        background-color: #0b0a0f;

        .ant-dropdown-menu {
            border: none;
        }
    }
}

.ant-popconfirm:not(.subscription-delete-popconfirm) .ant-popconfirm-message-icon svg {
    color: $primary-color;
}

// EditAndDeleteButtons component
.edit-n-delete-buttons-wrapper {
    .edit-buttons-icon-wrapper {
        white-space: nowrap;
        padding-left: 10px;
        display: none;

        &.focused-button-wrapper {
            display: flex;
        }

        &:has(div > .anticon-check) {
            padding-left: 3px;
        }

        svg {
            cursor: pointer;
            margin: 5px 0 5px 5px;

            &:hover {
                color: $primary-color;
                
                path {
                    stroke: $primary-color;
                }
            }
        }
    }

    &:has(input.ant-input),
    &:hover,
    &:active,
    &:focus {
        .edit-buttons-icon-wrapper {
            display: flex;
        }
    }

    .error-message-text {
        font-size: 14px;
    }
}

// Scroll animation to imitate scroll-padding
@keyframes scroll-padding {
    from,
    to {
        padding-right: var(--scroll-padding, 0);
    }
}

.scroll-animated {
    /* the trick (and @keyframes above), but not working for some browsers */
    animation: 3s linear 0s 1 alternate scroll-padding;
    animation-timeline: scroll(self);
    animation-range: 0 100%;
}

.optimizer-brief-form-wrapper.ant-form {
    padding: 18px 12px 2px;

    .ant-form-item {
        margin-bottom: 12px;

        .ant-input {
            box-shadow: 0px 2px 4px #0000000a;
            line-height: 22px;
        }

        .ant-form-item-label {
            line-height: 16px;
        }

        .ant-form-item-label > label {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #1f1f1f;
        }

        .brief-label .check-mark {
            svg {
                width: 22px;
                height: 22px;
            }
        }

        .ant-typography {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            margin-right: 4px;
        }

        .ant-input,
        .ant-input-number-input {
            padding: 8px 12px;
            min-height: 38px;
        }
    }

    textarea.ant-input {
        min-height: 68px;
        transition: all 0s;
    }

    .ant-input-disabled,
    .ant-input[disabled] {
        color: #728192;
        background-color: transparent;
        border-color: transparent;
        cursor: auto;
        padding: 8px 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        min-height: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        box-shadow: none;

        &.link-area {
            color: #327eef;
        }
    }

    .ant-input-number {
        transition: all 0s;

        .ant-input-number-input {
            transition: all 0s;
        }
    }

    .ant-form-item {
        .brief-text.ant-typography {
            font-weight: 400;
            font-size: 14px;
            margin: 0;
            line-height: 22px;
        }
    }

    .ant-input-number-disabled {
        background-color: transparent;
        border-color: transparent;
        cursor: auto;

        .ant-input-number-input {
            cursor: auto;
            padding: 8px 0;
            color: #728192;
        }
    }

    .ant-input-status-error:not(.ant-input-disabled):not(
            .ant-input-borderless
        ).ant-input:focus {
        box-shadow: none;
    }

    .ant-btn-link {
        width: 36px;
        height: 20px;
        border-radius: 4px;
        color: #1f1f1f;
        margin: 0 0 0 16px;
        padding: 0;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;

        &:hover {
            background: #f3f7fd;
            color: #327eef;
        }
    }

    .brief-label {
        flex-wrap: wrap;

        .brief-title {
            margin-right: 4px;
        }

        span.success {
            color: #20c752;
            font-weight: 600;
        }

        span.empty {
            color: #ff6f67;
            font-weight: 600;
        }
    }

    .brief-text {
        position: relative;
        min-height: 32px;
        padding: 4px 0;
        display: inline;

        .check-mark {
            svg {
                position: absolute;
                bottom: 2px;
                width: 22px;
                height: 22px;
            }
        }

        span.empty {
            color: #ff6f67;
        }

        .ant-typography {
            font-style: italic;
            font-weight: 400;
            font-size: 14px;
            margin: 0;
            line-height: 22px;
        }
    }

    .ant-input,
    .ant-input-number .ant-input-number-input {
        &::placeholder {
            font-style: italic;
            color: #ff6f67;
        }
    }
}

.no-content-form-wrapper.ant-form {
    .ant-form-item {
        margin-bottom: 14px;
        line-height: 14px;

        .ant-form-item-label > label {
            line-height: 14px;
            font-size: 12px;
        }
    }
}

.optimizer-outline-wrapper {
    padding: 0px 24px;

    .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
    }

    .tag-line {
        width: 1px;
    }

    .outline-tags-wrapper {
        margin-left: -16px;
        padding-bottom: 18px;
    }
}

.container-dark {
    .optimizer-brief-form-wrapper.ant-form {
        .ant-form-item {
            .ant-form-item-label > label {
                color: #fff;
            }
        }

        .ant-input-disabled,
        .ant-input[disabled] {
            color: #9d9d9f;

            &.link-area {
                color: #327eef;
            }
        }

        .ant-btn-link {
            color: #fff;

            &:hover {
                background: #327eef1a;
                color: #327eef;
            }
        }

        .ant-input-number-disabled {
            background-color: transparent;
            border-color: transparent;
            cursor: auto;

            .ant-input-number-input {
                padding: 4px 0;
                color: #9d9d9f;
            }
        }
    }
}

div.optimizer-brief-tab-content {
    height: calc(100vh - 220px);
    overflow-y: overlay;
}

.usage-content {
    padding: 24px;
    overflow: overlay;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;

    div.ant-row {
        .chart-wrapper {
            width: 100%;
            padding: 20px 24px;

            border: 1px solid #e5e7eb;
            box-shadow: 0px 2px 4px #0000000a;
            border-radius: 6px;

            margin-bottom: 32px;

            .chart-label {
                margin-bottom: 24px;

                article.second-label {
                    font-size: 14px;
                    color: #728192;
                }
            }

            .x-axis-label {
                display: flex;
                justify-content: space-between;

                article {
                    font-size: 12px;
                    color: #1f1f1f;
                }
            }

            .recharts-wrapper {
                margin-left: -26px;
            }
        }

        .chart-wrapper.dark-mode {
            border-color: #2a2d2f;

            article.second-label {
                color: #9d9d9f;
            }

            .x-axis-label {
                article {
                    color: #ffffff;
                }
            }
        }
    }

    .user-stats-label {
        margin-bottom: 16px;
    }

    article.main-label {
        font-weight: 600;
        font-size: 18px;
        color: #1f1f1f;
    }

    article.main-label.dark-mode {
        color: #ffffff;
    }
}

@import '../../../resources/styles/variables.scss';

.notification-card {
    border: 1px solid #ef3e324d;
    background: #fff8f8;
    margin-bottom: 24px;

    .ant-card-body {
        padding: 10px;

        .ant-typography {
            font-size: 14px;
            line-height: 20px;
        }
    }
}

.notification-card-dark {
    background: #16161c;
}

.warning-notification-wrapper {
    padding: 12px 24px;
    height: 54px;
    min-height: 54px;
    background-color: #feecea;

    h5.ant-typography {
        font-weight: 400;
        font-size: 14px;
        margin: 0;
    }

    .ant-btn {
        height: 34px;
        margin-left: 12px;
        margin-bottom: 0;
        width: fit-content;
    }

    &.dark-mode {
        background-color: $dark-warning-bg-color;
    }
}

.trial-notification-timer {
    width: 150px;
    display: inline-block;
}

.trial-notification-button {
    margin-left: 5px !important;
}

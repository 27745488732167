@import './variables.scss';

// Layout
.ant-layout .ant-layout-header {
    height: 54px;
    padding-inline: 24px;
    position: sticky;
    top: 0;
    z-index: 1001;
    width: 100%;
}

.ant-layout .ant-layout-sider {
    height: 100vh;
    position: sticky;
    z-index: 1002;
    top: 0;
    left: 0;
    bottom: 0;
    transition: background-color 0s, width 0.2s, max-width 0.2s, min-width 0.2s;
}

//Text
.ant-typography {
    font-family: 'Inter';
}

h2.ant-typography,
.ant-typography h2 {
    margin: 0;
}

.ant-breadcrumb a {
    transition: color 0s;

    &:hover {
        transition: color 0.2s;
    }
}

// Button
.ant-btn {
    transition: all 0s;

    &:hover {
        transition: all 0.2s;
    }
}

.ant-btn-link {
    margin-bottom: 0;
    width: fit-content;
    padding: 4px 8px 6px;
    border-radius: 6px;

    span {
        text-decoration: underline;
    }

    &:hover {
        background-color: $light-primary-bg-color;
    }
}

.ant-btn-primary {
    box-shadow: none;
}

.ant-btn.ant-btn-lg {
    font-size: 16px;
    height: 38px;
    border-radius: 6px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
    }
}

.ant-btn-primary.ant-btn-background-ghost:not(:disabled) {
    color: $primary-color;
    border: 1px solid $primary-color;
    box-shadow: none;
}

.ant-btn-primary.ant-btn-background-ghost:not(:disabled):hover {
    color: $primary-color;
    background-color: $light-primary-bg-color;
    border: 1px solid $primary-color;
    box-shadow: none;
}

.ant-btn-primary.ant-btn-background-ghost.dark-mode:not(:disabled):hover,
button.ant-btn.dark-mode:not(:disabled):hover {
    background-color: $dark-primary-bg-color;
}

button.ant-btn {
    font-weight: 500;
}

button.ant-btn.ant-btn-link {
    font-weight: 400;
}

.ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.ant-form-vertical .ant-form-item-label {
    padding: 0 0 4px;
}

.ant-modal .ant-modal-content {
    .ant-modal-close {
        &:hover {
            background: transparent;

            svg {
                filter: invert(80%) sepia(78%) saturate(810%) hue-rotate(186deg) brightness(93%) contrast(101%);
            }
        }
    }
}

.ant-wave {
    display: none;
}

// Table
.ant-table-empty {
    .ant-table-tbody {
        .ant-table-cell {
            height: 150px;
        }
    }
}

.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th:not(:last-child),
.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header.ant-table-sticky-holder > table > thead > tr > th:not(:last-child),
.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td:not(:last-child),
.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td:not(:last-child) {
    border-right: none;
}

.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after {
    border-inline-end: none;
}

.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header.ant-table-sticky-holder > table > thead > tr > th,
.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
    &:first-child {
        border-inline-start: 1px solid #e5e7eb;
    }
}

.ant-table-content,
.ant-table-header.ant-table-sticky-holder {
    margin-left: -1px;

    th {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }

    .ant-table-cell:last-child {
        width: 120px;
        border-inline-end: 1px solid #e5e7eb;
    }
}

.ant-table-wrapper .ant-table-column-sorters {
    justify-content: start;

    span {
        flex: 0;
    }
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: #f6f8fa;
}

.ant-table-wrapper td.ant-table-column-sort {
    background: transparent;
}

.ant-table-wrapper .ant-table-thead > tr > th {
    padding: 9px 24px;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 15px 24px;
}

.ant-table-wrapper .ant-table-tbody > tr:last-child > *:first-child {
    border-end-start-radius: 6px;
    border-inline-end: 1px solid #2a2d2f;
}

.ant-table-wrapper .ant-table-tbody > tr:last-child > *:last-child {
    border-end-end-radius: 6px;
}

.ant-table-wrapper .ant-table-container {
    border-end-start-radius: 6px;
}

.container-dark {

    .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
    .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header.ant-table-sticky-holder > table > thead > tr > th,
    .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
    .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
        &:first-child {
            border-inline-start: 1px solid #2a2d2f;
        }
    }

    .ant-table-wrapper .ant-table-thead th.ant-table-column-sort,
    .ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
        background: #2d2d33;
    }

    .ant-table-content,
    .ant-table-header.ant-table-sticky-holder {
        margin-left: -1px;

        th {
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
        }

        .ant-table-cell:last-child {
            border-inline-end: 1px solid #2a2d2f;
        }
    }
}

// Divider
.ant-divider {
    margin: 0 0 20px;
    background: #e5e7eb;
}

.ant-divider.dark-mode {
    border: 0.5px solid;
    border-color: #2a2d2f;

    margin: 0 0 20px;
    background: #2a2d2f;
}

// Select
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
        background-color 0s, border-color 0s, color 0s;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
    transition: color 0s;
}

.ant-select-item-option-selected {
    .ant-select-item-option-content {
        color: $primary-color;
    }
}

.ant-select-dropdown .ant-select-item {
    margin-bottom: 4px;

    &:last-child {
        margin-bottom: 0;
    }
}

// Input
.ant-input-affix-wrapper {
    transition: all 0s;

    &:hover {
        transition: border-color 0.2s;
    }

    .ant-input {
        background: transparent;
    }
}

.ant-input {
    font-family: 'Inter';
    transition: all 0s;

    &:hover {
        transition: border-color 0.2s;
    }
}

textarea.ant-input {
    transition: background-color 0s;
}

.ant-form-item-with-help {
    transition: all 0.2s;
}

// Autofill background color and color text (light theme)
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    // !important should be for rewrite password element background
    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
    -webkit-text-fill-color: #1f1f1f;
    -webkit-background-clip: text;
}

.dark-input-autofill {

    // Autofill background color and color text  (dark theme)
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        // !important should be for rewrite password element background
        -webkit-box-shadow: 0 0 0 30px #242429 inset !important;
        -webkit-text-fill-color: #fff;
        -webkit-background-clip: text;
    }

    span.anticon.ant-input-password-icon {
        svg {
            fill: #9d9d9f;
        }
    }
}

// Tabs
.ant-tabs-top > .ant-tabs-nav {
    margin: 0;
}

.ant-tabs {
    height: 100%;
}

.ant-tabs-content {
    height: 100%;
}

.ant-tabs-tabpane {
    height: 100%;
}

.ant-tabs-nav-wrap {
    margin: 0 24px;
}

.ant-tabs-nav .ant-tabs-tab {
    font-weight: 400;
    font-size: 14px;
    padding: 11px 0;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
    color: #1f1f1f;
    text-shadow: none;
}

.ant-tabs .ant-tabs-tab-btn {
    transition: all 0.3s, color 0s;

    &:hover {
        transition: color 0.3s;
    }
}

.container-dark {
    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #ffffff;
    }
}

.ant-select-dropdown .ant-select-item {
    font-weight: 400;
}

// Checkbox
.ant-checkbox .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    transition: background 0s;

    &::after {
        width: 6.714286px;
        height: 10.142857px;
        inset-inline-start: 27.5%;
    }
}

// Nedded to customize scrollbar on select dropdown
.rc-virtual-list-scrollbar {
    width: 4px !important;
}

.rc-virtual-list-scrollbar-thumb {
    background: #6e6e6e66 !important;
}

// Pagination
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev {
    svg {
        path {
            stroke: #728192;
        }
    }

    &:hover {
        svg {
            path {
                stroke: $primary-color;
            }
        }
    }
}

.ant-pagination .ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination .ant-pagination-prev:hover .ant-pagination-item-link {
    background-color: transparent;
    color: $primary-color;
}

.ant-pagination .ant-pagination-item-active:hover {
    border-color: $primary-color;
    background-color: $light-primary-bg-color;

    a {
        color: $primary-color;
    }
}

.ant-pagination .ant-pagination-disabled {
    cursor: auto;

    svg {
        path {
            stroke: #728192;
        }
    }

    &:hover {
        cursor: auto;

        svg {
            path {
                stroke: #728192;
            }
        }
    }
}

.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover {
    background-color: $light-primary-bg-color;
}

.list-wrapper.dark-mode,
.ant-table-wrapper.dark-mode {
    .ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover {
        background-color: $dark-primary-bg-color;
    }
}

.ant-pagination .ant-pagination-item a {
    color: #728192;
}

.ant-pagination .ant-pagination-item-active {
    background-color: $light-primary-bg-color;

    a {
        color: $primary-color;
    }

    border: none;
}

.ant-pagination-options-size-changer {
    .anticon-down {
        &::after {
            content: '';
            position: absolute;
            top: -0.5px;
            right: 2px;
            width: 6px;
            height: 6px;
            border: solid #fff;
            border-width: 0 1px 1px 0;
            display: inline-block;
            padding: 2px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
        }

        &::before {
            content: '';
            position: absolute;
            top: 1px;
            right: 2px;
            width: 6px;
            height: 6px;
            border: solid #728192;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 2px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
        }

        svg {
            display: none;
        }
    }
}

.ant-pagination-options-size-changer.ant-select-open .anticon-down {
    &::after {
        top: 7.5px;
        transform: rotate(225deg);
        -webkit-transform: rotate(225deg);
    }

    &::before {
        top: 6px;
        border: solid $primary-color;
        border-width: 0 2px 2px 0;
        transform: rotate(225deg);
        -webkit-transform: rotate(225deg);
    }
}

.container-dark {

    .ant-pagination .ant-pagination-next,
    .ant-pagination .ant-pagination-prev {
        svg {
            path {
                stroke: #9d9d9f;
            }
        }

        &:hover {
            svg {
                path {
                    stroke: $primary-color;
                }
            }
        }
    }

    .ant-pagination-options-size-changer {
        .anticon-down {
            &::after {
                border: solid #242429;
            }

            &::before {
                border: solid #9d9d9f;
            }
        }
    }

    .ant-pagination-options-size-changer {
        .anticon-down {
            &::after {
                border: solid #242429;
                border-width: 0 1px 1px 0;
            }

            &::before {
                border: solid #9d9d9f;
                border-width: 0 2px 2px 0;
            }

            svg {
                display: none;
            }
        }
    }

    .ant-pagination-options-size-changer.ant-select-open .anticon-down {
        &::before {
            border: solid $primary-color;
            border-width: 0 2px 2px 0;
        }
    }

    .ant-pagination .ant-pagination-item a {
        color: #9d9d9f;
    }

    .ant-pagination .ant-pagination-item-active {
        background-color: $light-primary-bg-color;

        a {
            color: $primary-color;
        }
    }

    .ant-pagination .ant-pagination-disabled {
        cursor: auto;

        svg {
            path {
                stroke: #9d9d9f;
            }
        }

        &:hover {
            cursor: auto;

            svg {
                path {
                    stroke: #9d9d9f;
                }
            }
        }
    }
}

// Segmented
.ant-segmented {
    transition: background 0s;
}

.ant-segmented.ant-segmented-block .ant-segmented-item {
    align-items: center;
    justify-content: center;
    display: flex;
    transition: all 0s;
}

.ant-segmented .ant-segmented-item-selected {
    font-weight: 500;
}

.ant-segmented .ant-segmented-item {
    transition: color 0s;
}

// Switch
.ant-switch {
    transition: background-color 0s;
}

// Modal
.ant-modal-root .ant-modal-mask {
    z-index: 1050;
}

.ant-modal-root .ant-modal-wrap {
    z-index: 1051;
}

.modal-wrapper {
    .ant-modal {
        width: 600px !important;
        top: calc(50% - 295px);
        padding-bottom: 0;

        .ant-modal-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
        }

        .ant-modal-content {
            padding: 18px 24px 24px;
        }
    }

    .modal-subtitle {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 16px;
        letter-spacing: 0.3px;
    }

    .form-fields-wrapper {
        border-top: 1px solid #e5e7eb;
        border-bottom: 1px solid #e5e7eb;
        margin: 16px 0 24px;
        padding: 20px 0;
    }

    .form-buttons-wrapper {
        height: 40px;
    }

    .ant-btn.ant-btn-lg {
        width: 80px;

        &:first-child {
            margin-right: 14px;
        }
    }
}

.modal-wrapper-dark {
    .form-fields-wrapper {
        border-top: 1px solid #2a2d2f;
        border-bottom: 1px solid #2a2d2f;
    }
}

// Tooltip
.tooltip-overlay.ant-tooltip {
    max-width: 235px;

    .ant-tooltip-inner {
        background-color: #ffffff;
        color: #728192;
        border-radius: 4px;
        box-shadow: 0px 4px 12px #0000001f;
        padding: 6px 8px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        border-radius: 6px;
        border: 1px solid #e5e7eb;
        letter-spacing: 0.25px;
        text-align: center;

        .ant-typography {
            font-size: 12px;
        }
    }

    .ant-tooltip-arrow {
        margin-top: 1px;

        &::before {
            background: #e5e7eb;
            height: 8.5px;
        }

        &::after {
            background: #ffffff;
            box-shadow: none;
            border: 1px solid #e5e7eb;
        }
    }
}

.tooltip-overlay.ant-tooltip-placement-top,
.tooltip-overlay.ant-tooltip-placement-topLeft,
.tooltip-overlay.ant-tooltip-placement-topRight {
    .ant-tooltip-arrow {
        bottom: 1px !important;
    }
}

.tooltip-overlay.ant-tooltip-placement-right {
    .ant-tooltip-arrow {
        left: 1px !important;
    }
}

.tooltip-overlay-dark.ant-tooltip {
    .ant-tooltip-inner {
        background-color: #0b0a0f;
        color: #9d9d9f;
        border: 1px solid #2a2d2f;
    }

    .ant-tooltip-arrow {
        &::before {
            background: #2a2d2f;
        }

        &::after {
            background: #0b0a0f;
            border: 1px solid #2a2d2f;
        }
    }
}

// Empty message

.container-dark {
    .ant-empty-image {
        svg {
            path {
                stroke: #6f6f6f;

                &:last-child {
                    fill: #2a2d2f;
                }
            }

            ellipse {
                stroke: #2a2d2f;
                fill: #2a2d2f;
            }
        }
    }
}

// Collapse
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    transition: all 0s;
}

// Skeleton
.container-dark {
    .ant-skeleton.ant-skeleton-active .ant-skeleton-input::after {
        background: linear-gradient(90deg,
                #ffffff0f 25%,
                #ffffff26 37%,
                #ffffff0f 63%);
    }
}

// Submenu 
.ant-dropdown-menu .ant-dropdown-menu-submenu {    
    .ant-dropdown-menu-submenu-title {
        display: flex;
        margin-bottom: 8px;
        height: 30px;
        border-radius: 6px;
        padding: 4px 4px 4px 8px;

        svg {
            margin-right: 8px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &:not(.ant-dropdown-menu-item-disabled):hover {
            svg path {
                stroke: $primary-color;
            }

            span {
                color: $primary-color;
            }
        }
    }
}

.ant-dropdown-menu-sub {
    .ant-dropdown-menu-item {
        &:hover {
            .ant-dropdown-menu-title-content {
                color: $primary-color;
            }
        }
    }
}

.ant-dropdown-menu-submenu-title {
    &:hover {
        .ant-dropdown-menu-submenu-expand-icon {
            .ant-dropdown-menu-submenu-arrow-icon {
                svg {
                    fill: $primary-color;
                }
            }
        }
    }
}

.ant-dropdown-menu-submenu.ant-dropdown-menu-light {
    .ant-dropdown-menu-sub {
        border: 1px solid $light-border-color;
    }

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        color: $light-typography-primary-color;
    }
}

.ant-dropdown-menu-submenu.ant-dropdown-menu-dark {
    .ant-dropdown-menu-sub {
        border: 1px solid $dark-border-color;
    }
    
    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        color: $dark-typography-primary-color;
    }
}
@import '../../../resources/styles/variables.scss';

$tiptap-editor-padding: 120px;
$tiptap-editor-full-width-padding: 44px;
$tiptap-editor-full-width-with-html-tags-padding: 78px;
$tiptap-editor-full-width-right-padding: 24px;

$table-of-contents-width: 30%;

.tiptap-editor-wrapper .rankability-loader {
    margin-top: 0;
}

// Placeholder https://tiptap.dev/docs/editor/api/extensions/placeholder
.tiptap.ProseMirror .is-empty:only-child::after {
    color: $tiptap-editor-placeholder-color;
    content: attr(data-placeholder); // Find data-placeholder in Placeholder extension
    float: left;
    height: 0;
    pointer-events: none;
    position: absolute;
    top: 24px;
}

.tiptap.ProseMirror {
    padding: 24px 0px 16px;
    position: absolute !important;
    top: 40px;
    bottom: 0;
    width: 100%;
    overflow: overlay;
    overflow-x: hidden;
}

.tiptap-editor-wrapper {
    .ProseMirror {
        & > * {
            padding: 0 $tiptap-editor-padding;
        }

        ul li > .node-aiSpellChecker,
        ol li > .node-aiSpellChecker {
            left: $tiptap-editor-padding;
            right: $tiptap-editor-padding;
        }
    }

    hr {
        margin: 0 $tiptap-editor-padding;
    }

    &.turn-on-editor-full-width {
        ul li > .node-aiSpellChecker,
        ol li > .node-aiSpellChecker {
            left: $tiptap-editor-full-width-padding;
            right: $tiptap-editor-full-width-right-padding;
        }

        hr {
            margin-left: $tiptap-editor-full-width-padding;
            margin-right: $tiptap-editor-full-width-right-padding;

        }

        &.turn-on-editor-html-tags {
            ul li > .node-aiSpellChecker,
            ol li > .node-aiSpellChecker {
                left: $tiptap-editor-full-width-with-html-tags-padding;
            }

            hr {
                margin-left: $tiptap-editor-full-width-with-html-tags-padding;
            }
        }
    }
}

.tiptap.ProseMirror,
.ai-response-wrapper {
    ol li,
    ul li {
        margin-left: 2rem;
    }

    font-size: 16px;
    line-height: 24px;
    font-weight: 400;

    h1 {
        font-size: 34px;
        line-height: 40px;
    }
    h2 {
        font-size: 30px;
        line-height: 36px;
    }
    h3 {
        font-size: 26px;
        line-height: 32px;
    }
    h4 {
        font-size: 22px;
        line-height: 28px;
    }
    h5 {
        font-size: 20px;
        line-height: 25px;
    }
    h6 {
        font-size: 18px;
        line-height: 23px;
    }

    blockquote {
        font-size: 18px;
        line-height: 32px;
    }
    code {
        font-size: 14px;
        line-height: 20px;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: 600;
    }

    code {
        background-color: #eee;
        padding: 3px;

        span {
            font-family: monospace;
        }
    }

    code.code-block {
        padding: 0.2rem 0.4rem;
        background-color: #e8edf3;
        border-radius: 0.2rem;

        span {
            font-family: 'Inter';
        }
    }

    blockquote {
        font-style: italic;
        color: $light-typography-primary-color;

        & > * {
            position: relative;
            padding-left: 12px;

            &::before {
                left: 0;
                content: '';
                display: block;
                position: absolute;
                height: 100%;
                border-left: 2px solid $light-typography-primary-color;
            }
        }

    }

    // Highlight keywords
    .highlight-keyword,
    code.highlight-keyword {
        background-color: $light-success-bg-color;
    }

    .highlight-selected-keyword,
    code.highlight-selected-keyword,
    code.highlight-selected-keyword.dark-mode {
        background-color: $primary-highlight-color;
    }

    > * {
        margin-bottom: 20px;
    }

    *:has(+ .node-aiSpellChecker) {
        margin-bottom: 0;
    }

    .node-aiSpellChecker + * {
        margin-top: 20px;
    }
}

.tiptap-editor-wrapper.dark-mode {
    .tiptap.ProseMirror,
    .ai-response-wrapper {
        color: $dark-typography-primary-color;

        code {
            background-color: $dark-typography-secondary-color;
        }

        code.code-block {
            background-color: $dark-typography-secondary-color;
        }

        blockquote {
            color: $dark-typography-primary-color;

            & > *::before {
                border-color: $dark-typography-primary-color;
            }
        }

        s {
            color: $dark-typography-secondary-color;
        }

        // Highlight keywords
        .highlight-keyword {
            background-color: $dark-success-bg-color;
        }

        code.highlight-keyword {
            background-color: $dark-success-bg-color;
        }

        .highlight-keyword.highlight-selected-keyword,
        code.highlight-keyword.highlight-selected-keyword {
            background-color: $primary-highlight-color;
        }
    }
}

.tiptap-editor-wrapper.turn-off-highlight-keywords,
.tiptap-editor-wrapper.dark-mode.turn-off-highlight-keywords {
    .tiptap.ProseMirror {
        // Highlight keywords
        .highlight-keyword,
        code.highlight-keyword {
            background-color: transparent;
        }

        .highlight-selected-keyword,
        code.highlight-selected-keyword,
        code.highlight-selected-keyword.dark-mode {
            background-color: transparent;
        }
    }
}

.tiptap-editor-wrapper.turn-on-editor-full-width,
.tiptap-editor-wrapper.dark-mode.turn-on-editor-full-width {
    .tiptap.ProseMirror > * {
        // Full width
        padding-left: $tiptap-editor-full-width-padding;
        padding-right: $tiptap-editor-full-width-right-padding;
    }
}

.tiptap-editor-wrapper.turn-on-editor-html-tags,
.tiptap-editor-wrapper.dark-mode.turn-on-editor-html-tags {
    .tiptap.ProseMirror {
        // Placeholder
        .is-empty:only-child::after {
            color: $tiptap-editor-placeholder-color;
            content: attr(data-placeholder); // Find data-placeholder in Placeholder extension
            float: left;
            height: 0px;
            pointer-events: none;

            position: absolute;
            top: 24px;
        }

        // HTML tags
        > p::before,
        > h1::before,
        > h2::before,
        > h3::before,
        > h4::before,
        > h5::before,
        > h6::before,
        > ul::before,
        > ol::before {
            user-select: none;
            position: absolute;

            color: $dark-typography-secondary-color;
            min-width: 18px;

            left: 78px;

            font-size: 14px;
            text-align: end;
            font-weight: 400;
        }

        > p:has(img.custom-image) {
            /**
             * Ability to type any text after the image if it is the last node in Editor,
             * and removing not needed padding below images
             */
            &:not(:last-of-type) {
                .ProseMirror-separator {
                    float: right;
                    position: absolute;
                }
                > br.ProseMirror-trailingBreak {
                    display: none;
                }
            }
        }

        > p:not(:has(img.custom-image))::before {
            content: 'p';
        }
        > p:has(img.custom-image)::before {
            content: 'img';
            margin-top: 2px;
        }
        > h1::before {
            content: 'h1';
        }
        > h2::before {
            content: 'h2';
        }
        > h3::before {
            content: 'h3';
        }
        > h4::before {
            content: 'h4';
        }
        > h5::before {
            content: 'h5';
        }
        > h6::before {
            content: 'h6';
        }

        > ul::before {
            content: 'ul';
        }
        > ol::before {
            content: 'ol';
        }
    }
}

.tiptap-editor-wrapper.turn-on-editor-html-tags.turn-on-editor-full-width,
.tiptap-editor-wrapper.dark-mode.turn-on-editor-html-tags.turn-on-editor-full-width {
    .tiptap.ProseMirror {
        // HTML tags
        > p::before,
        > h1::before,
        > h2::before,
        > h3::before,
        > h4::before,
        > h5::before,
        > h6::before,
        > ul::before,
        > ol::before {
            left: 38px;
        }

        & > * {
            // Full width
            padding-left: $tiptap-editor-full-width-with-html-tags-padding;
            padding-right: $tiptap-editor-full-width-right-padding;
        }
    }
}

.tiptap.ProseMirror {
    div.ProseMirror-selectednode:has(> :nth-child(5)) {
        // rewrite existing image resize styles
        border: none !important;

        img {
            // rewrite existing image resize styles
            border: 3px solid $primary-color !important;
        }

        div {
            // rewrite existing image resize styles
            border: 4px solid $primary-color !important;
            border-radius: 40% !important;
        }
    }

    div.ProseMirror-selectednode > div > div:has(img) {
        /** Image Toolbar */
        min-width: 100px;
        border-radius: 6px !important;
        border-color: $primary-color !important;
    }

    img.custom-image,
    .custom-image {
        max-width: 100%;
        max-height: 100%;
        height: auto !important;
        padding: 1px;
    }

    div.ProseMirror-selectednode img.custom-image {
        padding: 1px;
    }

    div:has(.custom-image):not(.node-aiSpellChecker):not(.node-aiSpellChecker > div):not(.ai-response-wrapper):not(.ai-response-content) {
        height: fit-content !important;
        max-height: fit-content;
        max-width: 100%;

        & > div {
            /** Wrapper div */
            max-width: 100%;
            height: fit-content !important;
            border: 1px solid transparent;
            /** Fixing bottom padding between image and div wrapper */
            line-height: 0;

            /** Resizing option circles div, not Image Toolbar */
            & > div:not(:nth-of-type(1)) {
                border: 0 none;
                height: 9px !important;
                max-height: auto;
                border-color: $primary-color !important;
            }
        }

        &.ProseMirror-selectednode > div {
            /** Wrapper div in selected state */
            border-color: $primary-color !important;
        }
    }
}

// Drag handle
.drag-handle {
    .drag-button {
        width: 22px;
        height: 22px;
        padding: 0;
        cursor: grab;
        position: absolute;
        display: none;
        top: 2px;
        align-items: center;
        justify-content: center;
        border: none;
        margin-bottom: 0;
        box-shadow: none;

        svg {
            width: 16px;
            height: 16px;
            stroke: $dark-typography-secondary-color;
        }

        &:hover {
            svg {
                stroke: $primary-color;
            }
        }

        &:active {
            cursor: grabbing;
        }
    }
}

.ProseMirror[data-hovered-element] {
    & ~ div .drag-handle {
        .drag-button {
            display: flex;
        }
    }
}

.ProseMirror[data-hovered-element="h1"] ~ div .drag-handle .drag-button {
    top: 9px;
}

.ProseMirror[data-hovered-element="h2"] ~ div .drag-handle .drag-button {
    top: 7px;
}

.ProseMirror[data-hovered-element="h3"] ~ div .drag-handle .drag-button {
    top: 4px;
}

.ProseMirror[data-hovered-element="h4"],
.ProseMirror[data-hovered-element="h5"],
.ProseMirror[data-hovered-element="h6"] {
    & ~ div .drag-handle {
        .drag-button {
            top: 1px;
        }
    }
}


.tiptap-editor-wrapper.turn-on-editor-html-tags {
    .drag-handle {
        .drag-button {
            left: 60px;
        }
    }

    &.turn-on-editor-full-width {
        .drag-handle {
            .drag-button {
                left: 20px;
            }
        }
    }
}

.tiptap-editor-wrapper:not(.turn-on-editor-html-tags) {
    .drag-handle {
        .drag-button {
            left: 100px;
        }
    }

    &.turn-on-editor-full-width {
        .drag-handle {
            .drag-button {
                left: 24px;
            }
        }
    }
}

.tiptap-editor-wrapper.turn-on-editor-table-of-contents {
    .tiptap.ProseMirror {
        width: calc(100% - #{$table-of-contents-width});
    }
    
    .table-of-contents {
        width: $table-of-contents-width;
        display: flex;
        flex: auto;
        flex-direction: column;
        position: absolute;
        right: 0;
        height: -webkit-fill-available;
        overflow-y: scroll;
        padding: 16px;
    
        border-left: 1px solid $light-border-color;
    
        &.dark-mode {
            border-left: 1px solid $dark-border-color;
        }
    }
}

.dropcursor-border {
    border: 1px solid $dark-typography-secondary-color;

    &.prosemirror-dropcursor-block {
        margin: 0 $tiptap-editor-padding;
        max-width: calc(100% - #{$tiptap-editor-padding * 2});

        .tiptap-editor-wrapper.turn-on-editor-full-width + & {
            margin-left: $tiptap-editor-full-width-padding;
            max-width: calc(100% - #{$tiptap-editor-full-width-padding + $tiptap-editor-full-width-right-padding});
        }

        .tiptap-editor-wrapper.turn-on-editor-full-width.turn-on-editor-html-tags + & {
            margin-left: $tiptap-editor-full-width-with-html-tags-padding;
            max-width: calc(100% - #{$tiptap-editor-full-width-with-html-tags-padding + $tiptap-editor-full-width-right-padding});
        }
    }
}

.tiptap.ProseMirror.ProseMirror-focused {
    outline: none;
}

.ProseMirror-hideselection * {
    max-width: 100%;
}

div.content-monitor-chart {
    border: 1px solid #e5e7eb;
    box-shadow: 0px 2px 4px #0000000a;
    border-radius: 6px;
    padding: 20px 24px;
    margin-bottom: 24px;

    &.dark-mode {
        border-color: #2a2d2f;
    }

    svg {
        path.recharts-tooltip-cursor {
            fill: #327eef1a;
        }
    }

    .value-label {
        width: 38px;
    }

    article.main-label {
        margin-bottom: 24px;
        margin-left: 28px;
        font-weight: 600;
        font-size: 18px;
        color: #1f1f1f;
    }

    article.main-label.dark-mode {
        color: #ffffff;
    }
}

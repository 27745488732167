.complete-badge {
    width: 137px;
    height: 22px;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-right: 32px;
}

.badge-disabled {
    color: #1f1f1f;
}

.complete-badge-small {
    width: 38px;
    height: 22px;
    line-height: 22px;
}

@import '../../../../../resources/styles/variables.scss';

.billing-plans-wrapper {
    .switched-plan-content-wrapper {
        padding-top: 14px;
    }

    .benefits-list {
        display: grid;
        grid-template-columns: 1fr 1fr;

        article {
            color: #1f1f1f;
            margin-bottom: 4px;
            display: flex;
            align-items: center;
        }

        article.dark-theme-typography {
            color: #ffffff;
        }

        svg {
            margin-right: 4px;
            margin-top: 2px;
        }
    }

    .header-billing-section {
        width: 640px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        margin-bottom: 14px;

        .header-title {
            font-size: 24px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 0;
        }
    }

    h2.ant-typography {
        font-weight: 700;
        font-size: 26px;
        line-height: 38px;
        margin-bottom: 16px;
    }

    .subscription-info-wrapper {
        height: 54px;
        background: #dff6e3;

        h5.ant-typography {
            text-align: center;
            margin-bottom: 0;
            font-weight: 400;
            font-size: 14px;
        }

        .ant-btn {
            height: 34px;
            margin-left: 12px;
            margin-bottom: 0;
            width: fit-content;
        }
    }

    .subscription-warning-wrapper {
        background: $warning-bg-color;
    }

    .billing-plans-content-wrapper {
        padding: 40px;

        .billing-plans-title-wrapper {
            width: 750px;
            margin-bottom: 32px;

            h4.ant-typography {
                font-weight: 600;
                font-size: 24px;
                line-height: 34px;
                margin-bottom: 16px;
            }

            .billing-switch-wrapper {
                height: 38px;
                width: fit-content;

                .ant-segmented-item {
                    height: 34px;
                    width: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover,
                    &-selected {
                        .ant-badge {
                            background: #327eef;
                        }
                    }

                    &-disabled,
                    &-disabled:hover {
                        .ant-badge {
                            background: #c3cfe4;
                        }
                    }
                }

                .ant-badge {
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: #728192;
                    margin: 0 6px;
                }
            }
        }

        .billing-plans-cards-wrapper {
            width: 750px;
            min-height: 60px;

            .billing-plan-card {
                margin-bottom: 32px;
                box-shadow: 0px 2px 4px #0000000a;

                .ant-card-body {
                    padding: 20px 24px;
                }

                .plan-price-wrapper {
                    .ant-badge.ant-badge-status {
                        margin: 0 5px;

                        .ant-badge-status-dot {
                            top: -1px;
                            width: 4px;
                            height: 4px;
                        }
                    }
                }

                .card-title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    padding-bottom: 16px;
                    margin-bottom: 16px;
                    border-bottom: 1px solid #e5e7eb;

                    h5.ant-typography {
                        font-weight: 600;
                        font-size: 18px;
                        margin-bottom: 0;
                    }
                }

                .text-active,
                .text-unactive {
                    font-size: 22px;
                    font-weight: 700;
                }

                .text-unactive {
                    text-decoration: line-through;
                    margin-right: 6px;
                    color: #9199ad;
                }

                .plan-features-list {
                    margin-top: 6px;

                    .ant-typography {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 22px;
                        margin-bottom: 0;
                        white-space: nowrap;
                    }

                    .ant-badge.ant-badge-status {
                        margin: 0 5px;

                        .ant-badge-status-dot {
                            top: -2px;
                            width: 4px;
                            height: 4px;
                        }

                        &:last-child {
                            display: none;
                        }
                    }
                }

                .select-plan-button {
                    height: 35px;
                    width: 104px;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 0;

                    &.ant-btn-primary:disabled {
                        cursor: auto;
                        color: #20c752;
                        border: none;
                        background-color: #dff6e3;
                    }
                }
            }
        }

        .billing-footer-text {
            .ant-typography {
                text-align: center;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                margin: 0;
            }

            a.ant-typography {
                font-weight: 600;
            }
        }
    }

    .switched-plan-card-wrapper {
        width: 640px;
        margin-bottom: 24px;

        .edit-payment-link {
            padding: 0;

            &:hover {
                background-color: transparent;
            }
        }

        h5.ant-typography {
            margin: 0;
        }

        .info-section {
            margin-bottom: 12px;
            padding-bottom: 12px;
            border-bottom: 1px solid #e5e7eb;
        }

        .plan-title {
            margin-bottom: 8px;

            h5.ant-typography {
                margin-right: 4px;
            }
        }

        .status-list-wrapper li {
            margin-bottom: 4px;
        }

        .ant-divider {
            margin: 16px 0;
        }

        .ant-typography.label {
            font-weight: 400;
            font-size: 13px;
            margin: 8px 0 4px;
        }

        .ant-typography span.fw-500 {
            color: #1f1f1f;
        }

        .ant-input {
            height: 38px;
        }

        .ant-input-disabled,
        .ant-input[disabled] {
            color: #1f1f1f;
            background-color: transparent;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0392156863);
            cursor: auto;
            opacity: 1;
        }

        .complete-checkout-button {
            width: 180px;
            margin: 4px 0 0;
            height: 38px;
        }
    }
}

.billing-plans-wrapper-dark {
    .subscription-info-wrapper {
        background: $dark-success-bg-color;
    }

    .subscription-warning-wrapper {
        background: $dark-warning-bg-color;
    }

    .billing-plans-content-wrapper {
        .billing-plans-title-wrapper {
            .billing-switch-wrapper {
                .ant-segmented-item {
                    &:hover,
                    &-selected {
                        .ant-badge {
                            background: #fff;
                        }
                    }

                    &-disabled,
                    &-disabled:hover {
                        .ant-badge {
                            background: #9d9d9f;
                        }
                    }
                }

                .ant-badge {
                    background: #9d9d9f;
                }
            }
        }

        .billing-plans-cards-wrapper .billing-plan-card {
            .card-title {
                border-bottom: 1px solid #2a2d2f;
            }

            .text-unactive {
                color: #9d9d9f;
            }

            .select-plan-button.ant-btn-primary:disabled {
                cursor: auto;
                color: #20c752;
                background-color: $dark-success-bg-color;
            }
        }
    }

    .switched-plan-card-wrapper {
        .info-section {
            border-bottom: 1px solid #2a2d2f;
        }

        .ant-typography span.fw-500 {
            color: #fff;
        }

        .ant-input-disabled,
        .ant-input[disabled] {
            color: #fff;
            background-color: #ffffff0f;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0392156863);
            cursor: auto;
            opacity: 1;
        }
    }
}

.switch-plan-content-monitor-credits-modal {
    .switch-plan-content-monitor-credits-modal-content {
        display: flex;
        align-items: flex-start;
        padding-top: 4px;

        .content-monitor-credits-warning {
            color: #1f1f1f;
            font-weight: 500;
            font-size: 15px;
            margin-left: 10px;
            margin-top: -4px;
        }
    }

    .form-buttons-wrapper {
        height: 24px;

        .ant-btn {
            min-width: 85px;
            width: fit-content;

            &:last-of-type {
                margin-left: 10px;
            }
        }
    }
}

.modal-wrapper-dark .switch-plan-content-monitor-credits-modal .content-monitor-credits-warning {
    color: #ffffff;
}

.tooltip-overlay.ant-tooltip.legacy-plan-tooltip {
    max-width: 485px;
}
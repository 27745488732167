@import '../../../resources/styles/variables.scss';

.azure-healthcheck-wrapper {
    width: 400px;

    article {
        margin-bottom: 4px;
    }
}

article.message-typography {
    font-weight: 400;
    font-size: 16px;
    color: $light-typography-primary-color;
    margin-bottom: 20px;
}

article.message-dark-theme-typography {
    color: $dark-typography-primary-color;
}

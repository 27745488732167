@import '../../../../resources/styles/variables.scss';

.sider-create-btn {
    margin-bottom: 30.5px;
    padding: 0 12px;

    span {
        font-size: 14px;
        line-height: 22px;
        margin-left: 6px;
    }
}

.sider {
    .ant-menu-light.ant-menu-root.ant-menu-inline,
    .ant-menu-light.ant-menu-root.ant-menu-vertical {
        border-inline-end: none;
    }

    .ant-menu-title-content {
        margin-left: 0px;
        display: flex;
        align-items: center;
    }

    .ant-menu {
        padding: 0 12px;
        background: none;

        &.dark-mode .ant-menu-item:hover,
        &.dark-mode .ant-menu-item-selected {
            background-color: $dark-primary-bg-color;
        }
    }

    .ant-menu-item {
        margin: 0 0 5px;
        padding: 8px 10px !important;
        border-radius: 6px;
        width: auto;
        height: 38px;

        &.dark-mode:hover {
            background-color: $dark-primary-bg-color;
        }

        &-disabled {
            cursor: not-allowed;

            svg path {
                stroke: $primary-bg-disabled-color;

                &#stroke-star {
                    fill: $primary-bg-disabled-color;
                    stroke: none;
                }
            }

            svg {
                &#cm-icon {
                    fill: $primary-bg-disabled-color;
                    stroke: none;
                }
            }

            a {
                cursor: not-allowed;
            }
        }
    }

    .ant-menu-item-disabled .content-monitor-sider-icon path {
        fill: $primary-bg-disabled-color;
    }

    .ant-menu-title-content {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;

        a {
            display: flex;
            transition: color 0s;
        }

        .menu-icon {
            margin-right: 8px;
            height: 38px;
            display: flex;
            align-items: center;
        }
    }
}

.sider-collapsed {
    .sider-create-btn {
        padding: 0 16px;
    }

    .ant-menu {
        padding: 0 16px;
    }

    .ant-menu-item {
        padding: 9px !important;
        padding: 0 0 0 8px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.sider-dark {
    .ant-menu-item-disabled svg path {
        stroke: $dark-typography-disabled-color;

        &#stroke-star {
            fill: $dark-typography-disabled-color;
            stroke: none;
        }
    }

    .ant-menu-item-disabled svg {
        &#cm-icon {
            fill: $dark-typography-disabled-color;
            stroke: none;
        }
    }
}
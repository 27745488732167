.cancellation-modal {
    .ant-modal {
        max-width: 500px;

        .ant-modal-content {
            padding: 0;

            .text-section {
                padding: 15px 40px;
                
                p {
                    color: #728192;
                    font-size: 16px;
                }
            }
            
            .image-section {
                display: flex;
                padding: 15px 40px 0;
                align-items: center;

                h1 {
                    color: #1F1F1F;
                    font-size: 20px;
                    font-weight: 500;
                }
                
                img {
                    width: 55px;
                    margin-right: 15px;
                }
            }

            .highlighted-section {
                padding: 15px 40px;
                background-color: #327eef;
                color: #FFFFFF;
                font-size: 16px;
            }

            .cancellation-reason-form {
                padding: 0 40px 15px;
                font-size: 16px;
                display: flex;
                flex-direction: column;

                .ant-radio-wrapper:hover .ant-radio-inner,
                .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
                    border-color: #9199AC;
                }

                .ant-radio-inner {
                    flex: 0 0 18px;
                    margin-right: 10px;
                    width: 18px;
                    height: 18px;
                    background-color: #9199AC;
                    border-radius: 50%;
                }

                .ant-radio-wrapper .ant-radio-checked::after {
                    border: none;
                }

                .ant-radio-wrapper {
                    display: flex;
                    margin-bottom: 5px;
                    font-size: 16px;
                    color: #728192;
                }

                textarea {
                    font-size: 16px;
                    padding: 10px;
                    height: 85px;

                    &::placeholder {
                        color: #728192;
                    }
                }

                .ant-form-item {
                    margin-top: 15px;
                    margin-bottom: 0px;
                }

                .form-item-button {
                    display: flex;
                    justify-content: center;
                    margin-top: 24px;
                    margin-bottom: 0;

                    button {
                        height: 44px;
                        font-size: 16px;
                        align-items: center;
                        width: 90px;
                    }
                }

                .link {
                    text-align: center;
                    display: block;
                    margin: auto;
                }
            }
        }
    }
}

.cancellation-modal.dark-mode {
    .ant-modal .ant-modal-content .image-section h1 {
        color: #FFFFFF;
    }

    .ant-modal .ant-modal-content .cancellation-reason-form .ant-radio-checked .ant-radio-inner:after {
        background-color: #FFFFFF;
    }
}
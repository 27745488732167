.recharts-cartesian-grid-vertical {
    opacity: 0;
}

.recharts-layer .recharts-cartesian-axis .recharts-yAxis .yAxis {
    margin-right: 10px;
}

.base-chart-tooltip-root {
    div.ant-card-body {
        padding: 8px 12px;
    }

    .ant-divider {
        margin: 4px 0px;
    }

    .title-label {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #1f1f1f;
    }

    .title-label.dark-mode {
        color: #ffffff;
    }

    .value-label {
        font-weight: 400;
        margin-left: 16px;

        color: #1f1f1f;
    }

    .value-label.dark-mode {
        color: #ffffff;
    }

    .value-budge {
        margin-right: 4px;
    }
}

line {
    stroke: #7280924d;
}

.keyword-chart {
    z-index: 1001;

    .y-axis-label {
        display: flex;
        flex-direction: column;
        height: 244px;
        justify-content: space-between;
        align-items: flex-end;
        min-width: 30px;

        article {
            font-size: 12px;
        }
    }

    .x-axis-label {
        display: flex;
        justify-content: space-between;

        article {
            font-size: 12px;
        }
    }

    .recharts-tooltip-wrapper {
        &:focus-visible, &:focus {
            outline: none;
        }
    }

    .keyword-chart-title {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #1f1f1f;
    }

    .keyword-chart-title.dark-mode {
        color: #ffffff;
    }

    .ant-dropdown-menu {
        width: 600px;

        tspan {
            font-size: 12px;
            fill: #728192;
        }
    }

    .dropdown-with-extra-content-wrapper {
        padding: 5px 12px;
    }

    .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
        &:hover {
            background-color: transparent;
        }
    }
}

.recharts-wrapper {
    div.recharts-tooltip-wrapper {
        &:focus-visible, &:focus {
            outline: none;
         }
    }
}
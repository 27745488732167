.page-loading-spin {
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    .anticon.anticon-loading.anticon-spin.ant-spin-dot {
        font-size: 100px;
    }
}

.skeleton-loading {
    margin: 4px;
}

.layout-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.site-layout {
    position: absolute;
    top: 0;
    bottom: 0;
    transition: width 0.2s, left 0.2s;
    overflow: overlay;
}

.page-layout-wrapper {
    position: absolute;
    top: 54px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    min-width: 1216px; // NOTE: DO NOT change it, because it's an inital width of content for HD monitor
}

.outline-container {
    width: 100%;
    position: absolute;
    top: 40px;
    bottom: 0;
    overflow-y: overlay;
    padding: 16px 22px;
}

.add-new-tag-input {
    display: flex;
    align-items: center;
    border-radius: 6px;
    padding: 2px;
    padding-left: 8px;

    cursor: pointer;

    article {
        margin-left: 6px;

        font-size: 12px;
        color: #1f1f1f;
    }

    background: transparent;

    &:hover {
        background: #f3f7fd;
    }
}

.add-new-tag-input.dark-mode {
    article {
        color: #ffffff;
    }

    &:hover {
        background: #327eef1a;
    }
}

div.outline-content-rankability-loader-wrapper {
    height: 50vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

@import '../../../../resources/styles/variables.scss';

.mastermind-item-container {
    &:not(.collapsed) {
        height: 42px;
    }

    .live-call-link {
        color: $primary-color;
        font-weight: 500;
        padding-left: 28px;
        font-size: 13px;
        margin-top: 5px;
        display: inline-block;
    }

    .mastermind-item-label {
        display: flex;
        align-items: center;

        .title {
            color: $light-typography-secondary-color;
        }
    }

    .mastermind-timer-wrapper {
        .main-time-font {
            color: $light-typography-secondary-color;
            font-weight: 600;
            padding-right: 1px;
            padding-left: 1px;
        }
    }

    .time-items-wrapper {
        margin-left: 27.5px;

        article {
            font-size: 12px;
        }

        .time-item {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            width: unset;   
        }
    }

    &.modal-open {
        .title, .time-item article, article.main-time-font {
            color: $primary-color;
        }
    } 
}

.timer-loader-wrapper.mastermind-sidebar-countdown {
    margin: 16px 0 0 0;
}

.dark-mode {
    .mastermind-sidebar-countdown.main-time-font, .mastermind-item-label .title {
        color: $dark-typography-secondary-color;
    }

    &.modal-open {
        .title, .time-item article, article.main-time-font {
            color: $primary-color;
        }
    }
}

.mastermind-call-disabled {
    cursor: not-allowed;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    
    .mastermind-item-label .title, 
    .mastermind-timer-wrapper .main-time-font, 
    article {
        color: $light-typography-disabled-color;
    }

    path {
        fill: $light-typography-disabled-color;
    }

    .mastermind-timer-wrapper {
        .live-call-link {
            color: $light-typography-disabled-color;
        }
    }

    &.dark-mode {
        .mastermind-item-label .title, 
        .mastermind-timer-wrapper .main-time-font, 
        article {
            color: $dark-typography-disabled-color;
        }

        .mastermind-timer-wrapper {
            .live-call-link {
                color: $dark-typography-disabled-color;
            }
        }

        path {
            fill: $dark-typography-disabled-color;
        }
    }
}

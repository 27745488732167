.rankability-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    margin-top: 2px;

    .progress-line-wrapper {
        width: 137px;

        .ant-progress.ant-progress-line {
            margin: 0;
        }

        .ant-progress .ant-progress-outer .ant-progress-inner {
            background-color: #f0f0f0;
        }
    }

    .percentage-indicator {
        color: #1f1f1f;
        font-weight: bold;
        line-height: 18px;
    }

    .percentage-indicator.dark-mode {
        color: #ffffff;
    }

    path {
        opacity: 0.2;
    }

    path.loaded {
        opacity: 1;
        transition: opacity 1.5s;
    }
}

.rankability-loader-absolute {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    justify-content: center;
}
.profile-content-wrapper {
    .form-fields-wrapper {
        .ant-input-affix-wrapper {
            padding: 0;
            overflow: hidden;
            box-shadow: 0px 2px 4px #0000000a;
        }

        .ant-input-affix-wrapper .ant-input-suffix {
            display: none;
        }

        .ant-input-affix-wrapper-disabled .ant-input[disabled] {
            padding: 0 12px;
        }
    }

    .profile-name-section {
        .ant-row:first-child {
            margin-bottom: 16px;
        }
    }

    .ant-form-item {
        margin-bottom: 0;
    }

    .ant-form-item
        .ant-form-item-label
        > label.ant-form-item-required:not(
            .ant-form-item-required-mark-optional
        )::before {
        display: none;
    }

    .ant-form-item .ant-form-item-label > label {
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
    }

    .ant-input {
        height: 38px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        padding-bottom: 6px;
        box-shadow: 0px 2px 4px #0000000a;
    }

    .ant-input:placeholder-shown {
        font-weight: 400;
    }

    .ant-input:focus,
    .ant-input-focused {
        box-shadow: none;
    }

    .ant-input-status-error:not(.ant-input-disabled):not(
            .ant-input-borderless
        ).ant-input:focus {
        box-shadow: none;
    }

    .ant-input-disabled,
    .ant-input[disabled] {
        color: #1f1f1f;
        background-color: #fff;
        border-color: #e5e7eb;

        &:hover {
            border-color: #327eef;
        }
    }

    span.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled.ant-input-password {
        &:hover {
            border-color: #327eef;
        }
    }

    a.ant-typography {
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin-top: 12px;
    }
}

.container-dark {
    .profile-content-wrapper {
        section {
            border-bottom: 1px solid #2a2d2f;

            &:last-child {
                border-bottom: none;
            }
        }

        .profile-form-buttons-wrapper {
            border-top: 1px solid #2a2d2f;
        }

        .ant-input-disabled,
        .ant-input[disabled] {
            color: #fff;
            background-color: #ffffff0f;
            border-color: #2a2d2f;

            &:hover {
                border-color: #327eef;
            }
        }

        span.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled.ant-input-password {
            &:hover {
                border-color: #327eef;
            }
        }
    }
}

.update-slug-modal {
    h5.ant-typography {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
    }

    .url-wrapper {
        padding-bottom: 20px;
    }

    .error-message-wrapper {
        margin: 16px 0;
    }

    .ant-form-item {
        margin-bottom: 0;
        box-shadow: 0px 2px 4px #0000000a;
    }

    .ant-form-item .ant-form-item-label > label {
        font-size: 13px;
    }

    .ant-input-group .ant-input-group-addon {
        padding: 0 16px;
        font-size: 14px;
        background: #f6f8fa;
    }

    .ant-input-group-wrapper-status-error .ant-input-group-addon {
        color: #1f1f1f;
        border-color: #e5e7eb;
    }

    .ant-input {
        height: 38px;
    }

    .ant-input:focus,
    .ant-input-focused {
        box-shadow: none;
    }

    .ant-input-status-error:not(.ant-input-disabled):not(
            .ant-input-borderless
        ).ant-input:focus {
        box-shadow: none;
    }

    .ant-input-status-error:not(.ant-input-disabled):not(
            .ant-input-borderless
        ).ant-input {
        border-color: #ef3e32;
    }

    .url-rules {
        position: absolute;
        margin-top: 6px;

        article {
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
        }
    }
}

.update-slug-modal-dark {
    .url-wrapper {
        input:-webkit-autofill,
        input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px #19181d inset !important;
        }

        .ant-input-group .ant-input-group-addon {
            background: #ffffff1a;
        }

        .ant-input-group-wrapper-status-error .ant-input-group-addon {
            color: #fff;
            border-color: #2a2d2f;
        }
    }
}

div.change-email-modal-wrapper {
    margin-top: -16px;
}

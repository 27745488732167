.rank-item {
    width: 100%;
    border-radius: 0;
    border-top-right-radius: 6px;

    .ant-empty-normal {
        margin-block: 12px;
    }

    .ant-card-head {
        background: #f6f8fa;
        padding: 8px 12px;
        border-radius: 0;

        .complete-badge-small {
            width: 30px;
            height: 20px;
            line-height: 20px;
            font-size: 12px;
            margin-right: 0;
        }

        .custom-badge {
            padding: 2px 4px 3px;
            border-radius: 4px;
        }
    }

    .ant-card-body {
        padding: 12px 14px;
    }

    .ant-empty-image {
        display: none;
    }

    .rank-badge {
        margin-right: 4px;
    }

    .rank-favicon {
        width: 16px;
        height: 16px;
        margin-right: 6px;
        vertical-align: middle;
        margin-bottom: 2px;
    }
}

.rank-title {
    .rank-title-data {
        article {
            width: 100%;
            color: #1f1f1f;
            font-size: 14px;
            font-weight: 400;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        article.dark-mode {
            color: #ffffff;
        }
    }

    article.main-typography {
        font-size: 14px;
        color: #327eef;
        font-weight: 500;
        overflow: hidden;
        margin-bottom: 0;
    }

    article.rank-word-count {
        font-size: 12px;
        font-weight: 500;
        margin-right: 5px;
        display: inline;
    }

    article.subtitle-url {
        font-size: 12px;
        overflow: hidden;
        margin-bottom: 0;
    }
}

.rank-title-tooltip-overlay.ant-tooltip-inner {
    padding: 6px 8px;
}

.expand-button.ant-btn {
    margin: 5px 0 0 0;
    color: #327eef;
    font-size: 12px;
    font-weight: 600;

    &:hover {
        color: #327eef;
        border-color: #327eef;
        background-color: #327eef1a;
    }
}
